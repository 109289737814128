<template>
  <div class="revenues-table">
    <b-table
      small
      sticky-header
      striped
      borderless
      outlined
      hover
      show-empty
      no-border-collapse
      head-variant="dark"
      :items="items"
      :fields="fields"
      :empty-text="$t('messages.emptyText')"
      :stacked="stacked"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
    >
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    fields: Array,
    items: Array,
    mainField: String,
  },

  data() {
    return {
      sortBy: '',
      sortDesc: false,
    };
  },

  computed: {
    stacked() {
      return this.$mq !== 'xl';
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.revenues-table {
  .b-table-sticky-header {
    max-height: 410px;
    white-space: nowrap;
  }

  table {
    th {
      font-size: 13px;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .table.b-table > thead > tr > .table-b-table-default {
    color: $white;
    background-color: $primary;
  }

  .table-authorized {
    font-weight: bold;
  }
}
</style>
