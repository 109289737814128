<template>
  <Modal>
    <b-modal id="about-modal" centered hide-header>
      <div class="logo" :class="$mq"></div>
      <AppInfo />
      <hr v-if="customer.company_name" />
      <ul class="customer-info" :class="$mq">
        <li v-if="customer.company_name">
          <strong>{{ $t('customer.customer') }}:</strong>
          {{ customer.company_name }}
        </li>
        <li v-if="customer.cnpj">
          <strong>{{ $t('customer.cnpj') }}:</strong>
          {{ customer.cnpj }}
        </li>
        <li v-if="customer.license_date">
          <strong>{{ $t('customer.license_date') }}:</strong>
          {{ formatDate(customer.license_date) }}
        </li>
      </ul>
      <template #modal-footer="{ close }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="close()">
            {{ $t('buttons.close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import AppInfo from '@/components/utils/AppInfo';

import helper from '@/mixins/helper';

export default {
  components: { Modal, AppInfo },

  mixins: [helper],

  computed: {
    customer() {
      return this.$store.state.customer;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#about-modal {
  .logo {
    height: 90px;
    margin-bottom: 20px;
    background: #226699 url('../../../public/img/logo.png') center no-repeat;
    background-size: 300px;

    &.xs {
      background-size: 80vw;
    }
  }

  .app-info.xs,
  .customer-info.xs {
    padding-left: 8vw;
  }

  .modal-body {
    padding: 0;
    font-size: 13px;
  }

  ul {
    line-height: 24px;
    list-style: none;
  }

  a {
    color: $info;
  }
}
</style>
