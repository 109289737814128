<template>
  <footer class="footer" :class="$mq">
    <AppInfo :app-url="false" />
  </footer>
</template>

<script>
import AppInfo from '@/components/utils/AppInfo';

export default {
  components: { AppInfo },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.footer {
  width: 100%;
  background: $bg-color;

  .app-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 4px 0;
    padding: 0;
  }

  li {
    padding-left: 5px;
    list-style: none;
    font-size: 12px;
    color: $light-purple;

    a {
      color: $light-purple;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  .app-url {
    display: none;
  }

  &.sm,
  &.xs {
    .app-info {
      flex-direction: column;
      margin: 10px 0;
      text-align: center;
    }

    li {
      font-size: 11px;

      a {
        font-size: 11px;
      }
    }
  }
}
</style>
