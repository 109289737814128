<template>
  <Modal>
    <b-modal
      id="patient-record-modal"
      size="xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('patients.records') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="savingHistory || uploadingImage"
          @click="close()"
        >
          ×
        </button>
      </template>
      <Skeleton
        v-if="loadingHistory"
        :title="false"
        :buttons="0"
        :rows="11"
        :columns="1"
        :small="false"
      />
      <div v-else>
        <div class="patient-info" :class="$mq">
          <div>
            <span>{{ $t('patients.patient') }}</span>
            {{ patient.name }}
          </div>
          <div>
            <span>{{ $t('fields.patient.id') }}</span>
            {{ patient.id }}
          </div>
          <div v-if="patient.birth_date">
            <span>{{ $t('fields.patient.birth_date') }}</span>
            {{ formatDate(patient.birth_date) }}
          </div>
          <div v-if="patient.birth_date">
            <span>{{ $t('fields.age.age') }}</span>
            {{ formatAge(patient.birth_date) }}
          </div>
          <div>
            <span>{{ $t('fields.patient.first_appointment_date') }}</span>
            {{ formatDate(patient.first_appointment_date) }}
          </div>
          <div>
            <span>{{ $t('fields.patient.last_appointment_date') }}</span>
            {{ formatDate(patient.last_appointment_date) }}
          </div>
          <div v-if="patient.occupation">
            <span>{{ $t('fields.patient.occupation') }}</span>
            {{ patient.occupation }}
          </div>
          <div v-if="patient.marital_status">
            <span>{{ $t('fields.patient.marital_status') }}</span>
            {{ $t(`fields.marital_status.${patient.marital_status}`) }}
          </div>
        </div>
        <hr />
        <div class="history" :class="$mq">
          <span class="history-content">{{ $t('fields.record.history') }}</span>
          <div v-if="!history.content && !editHistory" class="no-history">
            {{ $t('messages.record.emptyRecord') }}
          </div>
          <div v-else-if="!editHistory" class="history-container">
            <b-button-group class="history-form-buttons">
              <b-button
                v-if="
                  item.patient_id !== patient.id || item.status !== 'attending'
                "
                class="history-edit-button"
                variant="outline-warning"
                @click="toggleEditHistory"
              >
                <fa-icon :icon="['fas', 'pen']" />
                <span>{{ $t('buttons.edit') }}</span>
              </b-button>
              <b-button
                class="history-print-button"
                variant="outline-dark"
                @click="print('patient-history-card')"
              >
                <fa-icon :icon="['fas', 'print']" />
                <span>{{ $t('buttons.print') }}</span>
              </b-button>
            </b-button-group>
            <b-card id="patient-history-card" class="history-card">
              <div class="print">
                <div>
                  <b>{{ $t('patients.patient') }}:</b>
                  {{ patient.name }}
                </div>
                <div>
                  <b>{{ $t('fields.patient.id') }}:</b>
                  {{ patient.id }}
                </div>
                <div v-if="patient.birth_date">
                  <b>{{ $t('fields.patient.birth_date') }}:</b>
                  {{ formatDate(patient.birth_date) }}
                </div>
                <div v-if="patient.birth_date">
                  <b>{{ $t('fields.age.age') }}:</b>
                  {{ formatAge(patient.birth_date) }}
                </div>
                <div>
                  <b>{{ $t('fields.patient.first_appointment_date') }}:</b>
                  {{ formatDate(patient.first_appointment_date) }}
                </div>
                <div>
                  <b>{{ $t('fields.patient.last_appointment_date') }}:</b>
                  {{ formatDate(patient.last_appointment_date) }}
                </div>
                <div v-if="patient.occupation">
                  <b>{{ $t('fields.patient.occupation') }}:</b>
                  {{ patient.occupation }}
                </div>
                <div v-if="patient.marital_status">
                  <b>{{ $t('fields.patient.marital_status') }}:</b>
                  {{ $t(`fields.marital_status.${patient.marital_status}`) }}
                </div>
              </div>
              <b-card-text v-html="history.content" />
            </b-card>
          </div>
          <b-form v-else class="history-form-container">
            <b-button-group v-if="!uploadingImage" class="history-form-buttons">
              <b-button
                class="history-cancel-button"
                variant="outline-dark"
                :disabled="savingHistory"
                @click="toggleEditHistory"
              >
                <fa-icon :icon="['fas', 'undo']" />
                <span>{{ $t('buttons.cancel') }}</span>
              </b-button>
              <b-button
                class="history-save-button"
                variant="outline-primary"
                :disabled="savingHistory"
                @click="saveHistory"
              >
                <Spinner v-if="savingHistory" size="small" />
                <div v-else>
                  <fa-icon :icon="['fas', 'save']" />
                  <span>
                    {{ $t('buttons.confirm') }}
                  </span>
                </div>
              </b-button>
            </b-button-group>
            <VueEditor
              v-model="history.content"
              class="history-editor"
              :editor-toolbar="editorToolbar"
              :disabled="savingHistory"
              useCustomImageHandler
              @image-added="handleImageAdded"
            />
            <b-overlay :show="uploadingImage" no-wrap>
              <template #overlay>
                <div class="text-center p-4 bg-primary text-light rounded">
                  <fa-icon :icon="['fas', 'cloud-upload-alt']" />
                  <div class="mb-3">
                    {{ $t('messages.record.uploadingImage') }}...
                  </div>
                </div>
              </template>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';
import Skeleton from '@/components/utils/Skeleton';

import { VueEditor } from 'vue2-editor';

import api from '@/mixins/api';
import helper from '@/mixins/helper';
import print from '@/mixins/print';

import axios from 'axios';

export default {
  components: {
    Modal,
    Spinner,
    Skeleton,
    VueEditor,
  },

  mixins: [api, helper, print],

  data() {
    return {
      history: {},
      record: {},
      loadingHistory: false,
      editHistory: false,
      savingHistory: false,
      uploadingImage: false,
      historyContent: null,
      editorToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'video'],
        ['clean'],
      ],
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    patient() {
      return this.$store.state.patientSearch;
    },

    item() {
      return this.$store.state.item;
    },
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('patients.records')}`;
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });

      this.loadHistory();
    },

    reset() {
      document.title = `${this.appName} - ${this.$t('patients.info')}`;
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });

      this.editHistory = false;
      this.history.content = this.historyContent;
    },

    toggleEditHistory() {
      this.editHistory = !this.editHistory;

      if (this.editHistory) {
        setTimeout(() => {
          const historyEditors =
            document.getElementsByClassName('ql-container');
          historyEditors.forEach(historyEditor => {
            if (historyEditor) {
              historyEditor.scrollTop = historyEditor.scrollHeight;
            }
          });
        }, 100);
      } else {
        this.history.content = this.historyContent;

        setTimeout(() => {
          const historyCard = document.getElementById('patient-history-card');
          if (historyCard) {
            historyCard.scrollTop = historyCard.scrollHeight;
          }
        }, 100);
      }
    },

    async loadHistory() {
      this.loadingHistory = true;

      const patient_id = this.patient.id;

      if (patient_id) {
        await axios
          .get(`${this.baseApiUrl}/records/${patient_id}`)
          .then(res => {
            this.history = res.data || {};
            this.historyContent = this.history.content;
          })
          .catch(this.showError)
          .finally(() => {
            this.loadingHistory = false;
          });

        setTimeout(() => {
          const historyCard = document.getElementById('patient-history-card');
          if (historyCard) {
            historyCard.scrollTop = historyCard.scrollHeight;
          }
        }, 100);
      }
    },

    async saveHistory() {
      this.savingHistory = true;

      const isHistoryEmpty =
        !this.history.content ||
        !this.history.content.replace(/(<([^>]+)>)/gi, '').trim();

      if (isHistoryEmpty) {
        this.$toasted.global.defaultError({
          msg: this.$t('messages.record.historyRequired'),
        });
      } else {
        await axios
          .put(`${this.baseApiUrl}/records/${this.history.id}`, this.history)
          .then(() => {
            this.historyContent = this.history.content;
            this.toggleEditHistory();

            this.$toasted.global.defaultSuccess({
              msg: this.$t('messages.record.historyUpdated'),
            });
          })
          .catch(this.showError);
      }

      this.savingHistory = false;
    },

    async handleImageAdded(file, editor, cursorLocation, resetUploader) {
      this.uploadingImage = true;

      const formData = new FormData();
      formData.append('image', file);

      await axios
        .post(`${this.baseApiUrl}/records/image_upload`, formData)
        .then(res => {
          const url = res.data;
          editor.insertEmbed(cursorLocation, 'image', url);
          resetUploader();
        })
        .catch(this.showError);

      this.uploadingImage = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#patient-record-modal {
  .patient-info {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 20px;

    div {
      padding: 0 0 5px;
      font-size: 13px;
      color: $gray-700;
    }

    &.xs {
      grid-template-columns: auto;
    }

    &.sm {
      grid-template-columns: auto auto;
    }
  }

  .patient-info span,
  .history span.history-content,
  form span.record-content {
    padding: 0 0 5px;
    font-size: 13px;
    font-weight: bold;
    color: $gray-700;

    &::after {
      content: ':';
    }
  }

  hr {
    margin: 5px 0;
  }

  .history {
    .no-history {
      padding: 10px 0 20px 0;
      font-size: 13px;
      color: $gray-800;
    }

    .history-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .history-edit-button,
      .history-print-button {
        min-width: 90px;
        margin-top: -25px;
        padding: 2px 5px;
        font-size: 10px;
        z-index: 1081;

        span {
          padding-left: 4px;
          font-size: 12px;
          font-weight: normal;
          color: unset;

          &::after {
            content: '';
          }
        }
      }

      .history-print-button {
        margin-left: 10px;
      }

      .history-card {
        width: 100%;
        height: 530px;
        margin: 5px 0 10px;
        overflow-y: scroll;
        font-size: 15px;
        z-index: 1080;

        .card-body {
          padding-top: 0;

          p {
            margin: 0;
            padding: 0;
            font-size: 13px;

            pre {
              font-size: 13px;
              white-space: pre-wrap;
            }
          }
        }

        img {
          max-width: 30%;
        }

        .print {
          display: none;
        }
      }
    }

    .history-form-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: -30px;

      .history-cancel-button,
      .history-save-button {
        min-width: 90px;
        margin-top: 5px;
        padding: 2px 5px;
        font-size: 10px;
        z-index: 1081;

        span {
          padding-left: 4px;
          font-size: 12px;
          font-weight: normal;
          color: unset;

          &::after {
            content: '';
          }
        }
      }

      .history-save-button {
        margin-left: 10px;
      }
    }

    &.xs,
    &.sm {
      .history-card {
        height: 55vh !important;
      }

      #quill-container {
        min-height: 50vh !important;
        max-height: 50vh !important;
      }
    }
  }

  .quillWrapper {
    width: 100%;
    margin-top: 5px;

    .ql-toolbar {
      padding: 5px;
      background-color: $gray-300;
      border: 1px solid $gray-300;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .ql-formats {
        margin-bottom: 0;

        button svg {
          height: 18px;
        }
      }

      .ql-picker.ql-color-picker svg {
        width: unset !important;
        height: 18px !important;
        vertical-align: unset;
      }
    }

    #quill-container {
      min-height: 202px;
      max-height: 202px;
      overflow-y: scroll;
      background-color: $gray-100;
      border: 1px solid $gray-300;
      border-top: none;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      .ql-editor {
        font-size: 13px;

        img {
          max-width: 30%;
        }
      }
    }

    &.first-record #quill-container {
      min-height: 350px;
      max-height: 350px;
    }

    &.history-editor {
      margin-bottom: 0;

      #quill-container {
        min-height: 494px;
        max-height: 494px;

        .ql-editing[data-mode='video'] {
          top: 0;
          left: 10px !important;

          &::before {
            content: 'Link do vídeo:';
          }

          input {
            outline: none;
          }

          a::after {
            content: 'Salvar';
          }
        }
      }
    }
  }
}
</style>
