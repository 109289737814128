<template>
  <b-card id="bills-tabs" class="bills-tabs" :class="$mq" no-body>
    <header class="print">
      <h1>{{ $t('main.appName') }} - {{ customer.name }}</h1>
      <h2>{{ $t('menu.finances.bills') }}</h2>
    </header>
    <b-tabs card>
      <slot name="body" />
    </b-tabs>
    <b-card-footer>
      <slot name="footer" />
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: mapState(['customer']),
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.bills-tabs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  height: 545px;
  background-color: transparent;

  header.print {
    display: none;
  }

  .card-header {
    background-color: $white;

    .nav-tabs {
      display: flex;
      flex-direction: row;

      .nav-item {
        display: flex;
        flex-direction: column;
        background-color: $gray-100;

        .nav-link {
          font-size: 12px;
          border-color: $gray-200;
          border-bottom-color: transparent;

          &:hover {
            border-color: $gray-300;
            border-bottom-color: transparent;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .card-body {
    padding: 15px 10px 0;
  }

  .card-footer {
    height: 30px;
    padding: 0;
  }

  &.xs,
  &.sm {
    height: auto;
  }
}
</style>
