<template>
  <div id="list-table" class="list-table" :class="$mq">
    <span class="total">
      <caption>
        {{
          $t('main.total')
        }}:
      </caption>
      {{ totalRows }}
    </span>
    <b-table
      small
      sticky-header
      striped
      borderless
      outlined
      hover
      show-empty
      no-border-collapse
      head-variant="dark"
      tbody-tr-class="row-class"
      :items="records"
      :fields="fields"
      :empty-text="$t('messages.emptyText')"
      :empty-filtered-text="$t('messages.emptyFilteredText')"
      :filter="filter"
      @filtered="onFiltered"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      @sort-changed="clearSelected"
      @row-clicked="selectRow"
      @row-contextmenu="contextMenu"
      @row-dblclicked="doubleClick"
    >
    </b-table>
    <DropdownMenu
      v-if="$mq === 'xl'"
      :entity-name="entity.name.plural"
      :actions="entity.dropdownActions"
      :position="dropdownPosition"
    />
  </div>
</template>

<script>
import DropdownMenu from '@/components/utils/DropdownMenu/DropdownMenu';

import helper from '@/mixins/helper';

export default {
  components: { DropdownMenu },

  mixins: [helper],

  props: {
    entity: Object,
    fields: Array,
    filter: String,
    isItemSelected: Boolean,
  },

  data() {
    return {
      records: [],
      sortBy: '',
      sortDesc: false,
      totalRows: 0,
      selectedRow: null,
      dropdownPosition: {
        top: 0,
        left: 0,
      },
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    items() {
      return this.$store.state.items;
    },

    isRowSelected() {
      return this.selectedRow !== null;
    },
  },

  mounted() {
    this.resetTable();
  },

  methods: {
    setRecords() {
      const records = this.items;
      const fields = this.entity.fields.list;

      records.forEach(record => {
        fields.forEach(field => {
          if (field.translate) {
            record[field.key] = this.$t(
              `fields.${this.entity.name.singular}.${field.key}.${
                record[field.key]
              }`,
            );
          }
        });
      });

      this.records = records;
    },

    resetTable() {
      this.setRecords();
      this.sortBy =
        this.entity.sortBy ||
        this.entity.secondaryField ||
        this.entity.mainField;
      this.totalRows = this.items.length;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;

      this.clearSelected();
    },

    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }));
    },

    selectRow(item, index, event) {
      if (this.selectedRow !== index) {
        this.selectedRow = index;
      } else if (event.type !== 'contextmenu') {
        this.clearSelected();
      }

      this.$emit('itemselected', this.isRowSelected);

      this.$store.commit('mutate', { prop: 'item', with: { ...item } });
    },

    doubleClick(item, index, event) {
      this.selectRow(item, index, event);
      if (!this.isRowSelected) {
        this.selectRow(item, index, event);
      }
      this.$bvModal.show(`${this.entity.name.plural}-form-modal`);
    },

    clearSelected() {
      this.selectedRow = null;
      this.$emit('itemselected', this.isRowSelected);

      this.$store.commit('mutate', { prop: 'item', with: {} });
    },

    contextMenu(item, index, event) {
      event.preventDefault();

      this.selectRow(item, index, event);

      const top = event.clientY - 15;
      const left = event.clientX;
      this.dropdownPosition = { top, left };
    },
  },

  watch: {
    selectedRow(value) {
      const elements = document.getElementsByClassName('row-class');

      elements.forEach(element => {
        element.style.backgroundColor = '';
      });
      if (value !== null) {
        elements[value].style.backgroundColor = '#aacdfa';
      }
    },

    isItemSelected(value) {
      if (!value) {
        this.clearSelected();
      }
    },

    items() {
      this.resetTable();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.list-table {
  .total {
    display: block;
    margin: 8px 0;
    font-size: 12px;

    caption {
      display: inline;
      font-weight: bold;
      color: $gray-700;
    }
  }

  .b-table-sticky-header {
    height: 230px;
    margin-bottom: 0;
  }

  table {
    th {
      font-size: 13px;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .row-class {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: $light-blue;
    }
  }

  &.xs,
  &.sm {
    .b-table-sticky-header {
      margin-bottom: 20px;
    }
  }
}
</style>
