<template>
  <b-dropdown class="dropdownmenu" ref="dropdown" variant="none" no-caret>
    <DropdownItem
      v-for="action in actions"
      :key="action.name"
      :action="action"
      :entity-name="entityName"
      @emitFunction="emitFunction"
    />
  </b-dropdown>
</template>

<script>
import DropdownItem from '@/components/utils/DropdownMenu/DropdownItem';

export default {
  components: { DropdownItem },

  props: {
    entityName: String,
    actions: Array,
    position: Object,
  },

  methods: {
    emitFunction(functionName) {
      if (functionName) {
        this.$emit(functionName);
      }
    },
  },

  watch: {
    position(obj) {
      const elements = document.getElementsByClassName('dropdown-toggle');
      const dropdownMenu =
        elements.length === 7
          ? elements[elements.length - 2]
          : elements[elements.length - 1];

      dropdownMenu.style.top = `${obj.top}px`;
      dropdownMenu.style.left = `${obj.left}px`;

      this.$refs.dropdown.show();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.dropdownmenu {
  .dropdown-toggle {
    position: fixed;
  }
}
</style>
