<template>
  <Modal>
    <b-modal
      :id="`${entity.name.plural}-prices-modal`"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t(`${entity.name.plural}.prices`) }}</h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <Skeleton
        v-if="loadingData"
        :title="true"
        :buttons="0"
        :rows="3"
        :columns="2"
        :small="false"
      />
      <b-form v-else>
        <input
          :id="`${entity.name.singular}-id`"
          type="hidden"
          v-model="item.id"
        />
        <caption>
          <span>{{ $t('appointment_types.appointment_type') }}</span>
          {{
            item[entity.mainField]
          }}
        </caption>
        <b-table-simple sticky-header striped>
          <b-tbody>
            <b-tr v-for="price in prices" :key="price.id">
              <b-td>
                <label :for="`price-${price.id}`">
                  {{ price.doctor_username }}
                </label>
              </b-td>
              <b-td>
                <money
                  v-model="price.price"
                  v-bind="money"
                  class="form-control shadow-none"
                  :class="price.price === 0 ? 'zero' : ''"
                />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="disableComponents"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            @click="ok()"
            :disabled="disableComponents"
          >
            <Spinner v-if="savingPrices" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Skeleton from '@/components/utils/Skeleton';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, Skeleton, Spinner },

  mixins: [api, helper],

  props: {
    entity: Object,
  },

  data() {
    return {
      title: document.title,
      loadingData: false,
      savingPrices: false,
      disableComponents: false,
      doctors: [],
      prices: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },

  computed: mapState(['customer', 'user', 'item']),

  methods: {
    async init() {
      document.title = `${this.appName} - ${this.$t(
        `${this.entity.name.plural}.prices`,
      )}`;

      this.$store.commit('mutate', { prop: 'closeDialog', with: true });

      this.loadingData = true;
      this.disableComponents = true;

      await this.loadDoctors();
      await this.loadPrices();

      this.loadingData = false;
      this.disableComponents = false;
    },

    reset() {
      document.title = `${this.appName} - ${this.$t(
        `menu.items.${this.entity.name.plural}`,
      )}`;

      this.doctors = [];
      this.prices = [];

      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    async loadDoctors() {
      const customer_id = this.customer.id;

      await axios
        .get(`${this.baseApiUrl}/doctors/${customer_id}`)
        .then(res => {
          const doctors = res.data || [];

          if (this.user.role !== 'doctor') {
            this.doctors = doctors;
          } else {
            this.doctors = doctors.filter(doctor => doctor.id === this.user.id);
          }
        })
        .catch(this.showError);
    },

    async loadPrices() {
      const type_id = this.item.id;

      await axios
        .get(`${this.baseApiUrl}/prices/${type_id}`)
        .then(res => {
          const data = res.data || [];
          const prices = [];

          this.loadDoctors();
          this.doctors.forEach((doctor, index) => {
            prices[index] = {
              type_id,
              doctor_id: doctor.id,
              doctor_username: doctor.username,
            };
            data.forEach(data => {
              if (data.doctor_id === doctor.id) {
                prices[index] = { ...prices[index], ...data };
              }
            });
          });
          this.prices = prices;
        })
        .catch(this.showError);
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.savingPrices = true;
      this.disableComponents = true;

      this.savePrices();
    },

    savePrices() {
      const type_id = this.item.id;
      const prices = this.prices;

      axios
        .post(`${this.baseApiUrl}/prices/${type_id}`, prices)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t(`messages.${this.entity.name.singular}.prices`, {
              title: this.item[this.entity.mainField],
            }),
          });
          this.$emit('submit');

          this.$bvModal.hide(`${this.entity.name.plural}-prices-modal`);
        })
        .catch(this.showError)
        .finally(() => {
          this.savingPrices = false;
          this.disableComponents = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#appointment_types-prices-modal {
  caption {
    display: block;
    padding: 0 0 15px;
    font-size: 13px;
    color: $gray-700;

    span {
      font-weight: bold;

      &::after {
        content: ':';
      }
    }
  }

  .b-table-sticky-header {
    max-height: 465px;
    margin-bottom: 0;
  }

  .table-striped tbody tr {
    &:nth-of-type(odd) {
      background-color: $gray-200;
    }
    &:nth-of-type(even) {
      background-color: $gray-100;
    }

    td {
      padding: 0 10px;
      font-size: 13px;
      vertical-align: middle;

      label {
        margin: 0;
      }

      input {
        margin: 5px 0;
        font-size: 12px;
        border-color: $input-border-color;
        background-color: $white;

        &.zero {
          color: $gray-500;
        }
      }
    }
  }
}
</style>
