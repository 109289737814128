<template>
  <Modal>
    <b-modal
      id="patient-form-modal"
      size="xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('patients.edit') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="savingPatient"
          @click="close()"
        >
          ×
        </button>
      </template>
      <b-form>
        <input id="patient-id" type="hidden" v-model="patient.id" />
        <b-form-group
          v-for="group in patient_model.fields.form"
          :key="group.groupName"
        >
          <legend v-if="group.groupName">{{ group.groupName }}</legend>
          <div
            class="form-fields"
            :class="group.type"
            :style="`grid-template-columns: ${group.gridTemplateColumns}`"
          >
            <FormField
              v-for="field in group.fields"
              :key="field.key"
              entity-name="patient"
              entity-state="patientSearch"
              :field="field"
              :disabled="field.disabled || savingPatient"
              :mask="field.mask"
            />
          </div>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="savingPatient"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="info" @click="ok()" :disabled="savingPatient">
            <Spinner v-if="savingPatient" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import patient_model from '@/models/patient';

import api from '@/mixins/api';
import helper from '@/mixins/helper';
import validate from '@/mixins/validate';

import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [patient_model, api, helper, validate],

  data() {
    return {
      savingPatient: false,
      patientData: {},
    };
  },

  computed: {
    patient() {
      return this.$store.state.patientSearch;
    },
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('patients.edit')}`;
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });

      this.patient_model.fields.form.forEach(group =>
        group.fields.forEach(field => {
          field.state = true;
        }),
      );

      this.patientData = { ...this.patient };
    },

    reset() {
      document.title = `${this.appName} - ${this.$t('patients.info')}`;
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });

      this.$store.commit('mutate', {
        prop: 'patientSearch',
        with: { ...this.patientData },
      });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      let fieldsValidity = true;
      this.patient_model.fields.form.forEach(group => {
        if (!this.checkFieldsValidity(this.patient, group.fields)) {
          fieldsValidity = false;
        }
      });

      if (fieldsValidity) {
        this.savePatient();
      }
    },

    async savePatient() {
      this.savingPatient = true;

      const patient = {
        ...this.patient,
      };

      this.patient_model.fields.form.forEach(group => {
        group.fields.forEach(field => {
          if (field.formatter) {
            patient[field.key] = this[field.formatter](this.patient[field.key]);
          }
        });
      });

      await axios
        .put(`${this.baseApiUrl}/patients/${patient.id}`, patient)
        .then(async () => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.patient.confirmed', {
              title: patient[this.patient_model.mainField],
              operation: this.$t('messages.patient.updated'),
            }),
          });

          this.patientData = { ...patient };
          this.$bvModal.hide('patient-form-modal');
          this.$emit('updatePatient');
          this.$store.commit('mutate', { prop: 'refreshSchedule', with: true });
        })
        .catch(this.showError);

      this.savingPatient = false;
    },
  },
};
</script>

<style>
#patient-form-modal___BV_modal_content_ {
  margin: -30px 0 0 -10px;
}
</style>
