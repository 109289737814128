<template>
  <div class="spinner" :class="size">
    <b-spinner :variant="variant"></b-spinner>
  </div>
</template>

<script>
export default {
  props: {
    variant: String,
    size: String,
  },
};
</script>

<style lang="scss">
.spinner {
  &.medium,
  &.large {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
  }

  &.small .spinner-border {
    width: 15px;
    height: 15px;
  }

  &.large .spinner-border {
    width: 70px;
    height: 70px;
  }
}
</style>
