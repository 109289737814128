<template>
  <Modal>
    <b-modal
      id="change-password-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('user.changePassword') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="changingPassword"
          @click="close()"
        >
          ×
        </button>
      </template>
      <form ref="form">
        <input id="user-id" type="hidden" v-model="user.id" />
        <FormField
          v-for="field in fields"
          :key="field.key"
          entity-name="user"
          entity-state="user"
          :field="field"
          :disabled="changingPassword"
        />
      </form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="changingPassword"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            @click="ok()"
            :disabled="changingPassword"
          >
            <Spinner v-if="changingPassword" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import validate from '@/mixins/validate';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, validate],

  computed: mapState(['user']),

  data() {
    return {
      title: document.title,
      changingPassword: false,
      userData: {},
      fields: [
        {
          key: 'passwordOld',
          type: 'password',
          state: null,
          required: true,
          autofocus: true,
        },
        {
          key: 'passwordNew',
          type: 'password',
          state: null,
          required: true,
          validation: 'checkPassword',
          validationMsg: this.$t('messages.user.passwordNewInvalid'),
        },
        {
          key: 'passwordNewConfirmation',
          type: 'password',
          state: null,
          required: true,
          validation: 'checkPasswordConfirmation',
          validationMsg: this.$t('messages.user.passwordNewConfirmationMatch'),
        },
      ],
    };
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('user.changePassword')}`;
      this.userData = { ...this.user };
      this.fields.forEach(field => {
        field.state = true;
      });
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });
    },

    reset() {
      document.title = this.title;
      this.$store.commit('mutate', { prop: 'user', with: this.userData });
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (!this.checkFieldsValidity(this.user, this.fields)) {
        return;
      }
      this.changingPassword = true;

      this.changePassword();
    },

    changePassword() {
      axios
        .put(`${this.baseApiUrl}/changePassword/${this.user.id}`, this.user)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.user.passwordChanged'),
          });

          this.$bvModal.hide('change-password-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.changingPassword = false;
        });
    },

    checkPassword(value = '') {
      return value.length >= 8;
    },

    checkPasswordConfirmation(value) {
      return !!value && value === this.user.passwordNew;
    },
  },
};
</script>
