import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  data() {
    return {
      appointment_type_model: {
        name: {
          singular: 'appointment_type',
          plural: 'appointment_types',
        },
        listSize: 'lg',
        mainField: 'name',
        secondaryField: 'description',
        sortBy: 'name',
        actions: [
          { name: 'add' },
          { name: 'edit' },
          {
            name: 'prices',
            label: this.$t('actions.appointment_type.prices'),
            variant: 'primary',
            icon: ['fas', 'dollar-sign'],
            modal: 'prices',
            hasId: true,
          },
          { name: 'delete' },
        ],
        dropdownActions: [
          { name: 'edit' },
          {
            name: 'prices',
            label: this.$t('actions.appointment_type.prices'),
            modal: 'prices',
          },
          { name: 'delete' },
        ],

        fields: {
          list: [
            {
              key: 'name',
              label: this.$t('fields.appointment_type.name'),
              sortable: true,
            },
            {
              key: 'description',
              label: this.$t('fields.appointment_type.description'),
              sortable: true,
            },
          ],

          view: [],

          form: [
            {
              fields: [
                {
                  key: 'name',
                  state: null,
                  required: true,
                  autofocus: true,
                  maxlength: 55,
                  formatter: 'upperFirstLetter',
                },
                {
                  key: 'description',
                  state: null,
                  maxlength: 55,
                  formatter: 'upperFirstLetter',
                },
              ],
            },
          ],
        },

        default: {
          actions: [],
          mobileActions: [],
          listFields: [],
          viewFields: [],
          formFields: [],
        },
      },
    };
  },

  mounted() {
    this.appointment_type_model.default.actions =
      this.appointment_type_model.actions;
    this.appointment_type_model.default.listFields =
      this.appointment_type_model.fields.list;
    this.appointment_type_model.default.viewFields =
      this.appointment_type_model.fields.view;
    this.appointment_type_model.default.formFields =
      this.appointment_type_model.fields.form;

    this.setMobileAppointmentType();
  },

  methods: {
    setMobileAppointmentType() {
      if (this.$mq === 'xl') {
        this.appointment_type_model.actions =
          this.appointment_type_model.default.actions;

        delete this.appointment_type_model.fields.view;
      } else {
        this.appointment_type_model.actions =
          this.appointment_type_model.default.actions.filter(
            action => action.name === 'add',
          );
        this.appointment_type_model.mobileActions =
          this.appointment_type_model.default.actions.filter(
            action => action.name !== 'add',
          );

        this.appointment_type_model.fields.view =
          this.appointment_type_model.default.viewFields;
      }

      if (this.$mq === 'xs') {
        this.appointment_type_model.fields.list =
          this.appointment_type_model.default.listFields.filter(
            field => field.key === 'name',
          );

        this.appointment_type_model.fields.form.forEach(group => {
          if (group.type === 'grid') group.gridTemplateColumns = 'auto';

          group.fields.forEach(field => {
            if (field.required) delete field.required;
            if (field.validation) delete field.validation;
            if (field.validationMsg) delete field.validationMsg;
          });
        });
      } else {
        this.appointment_type_model.fields.list =
          this.appointment_type_model.default.listFields;
        this.appointment_type_model.fields.form =
          this.appointment_type_model.default.formFields;
      }
    },
  },

  watch: {
    $mq() {
      this.setMobileAppointmentType();
    },
  },
};
