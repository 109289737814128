<template>
  <div class="not-found">
    <section>
      <Logo />
      <h1>{{ $t('main.notFound') }}</h1>
      <router-link to="/">
        <span>{{ $t('main.back') }}</span>
      </router-link>
    </section>
    <Footer />
  </div>
</template>

<script>
import Logo from '@/components/utils/Logo';
import Footer from '@/components/utils/Footer';

export default {
  components: { Logo, Footer },
};
</script>

<style lang="scss" scoped>
@import '@/styles/custom.scss';

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 30px 0 80px;
    text-align: center;
  }

  h1 {
    padding: 60px 0;
    font-size: 24px;
  }

  a {
    color: $body-color;
  }
}
</style>
