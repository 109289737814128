<template>
  <b-card id="schedule-days" class="schedule-days" :class="$mq" no-body>
    <header class="print">
      <h1>{{ $t('main.appName') }} - {{ customer.name }}</h1>
      <h2>{{ $t('menu.appointments.schedules') }}</h2>
    </header>
    <div class="doctors-list">
      <div v-for="doctor in doctors" :key="doctor.id">
        <span v-if="doctor.id === doctorId">
          {{ $t('users.doctor') }}: {{ doctor.username }}
        </span>
      </div>
    </div>
    <b-tabs card>
      <!--eslint-disable vue/no-use-v-if-with-v-for -->
      <b-tab
        v-for="day in days"
        :key="day.index"
        :active="day.active && !disabled"
        v-if="day.visible"
        @click="!day.active && $emit('dateChange', day.date)"
      >
        <template #title>
          <div v-if="!disabled">
            <p class="nav-day">
              {{
                $mq === 'xl'
                  ? $t(`schedule.day.${day.name}`)
                  : $t(`schedule.dayAbbr.${day.name}`)
              }}
            </p>
            <p v-if="$mq === 'xl'" class="nav-date">{{ day.date }}</p>
          </div>
        </template>
      </b-tab>
    </b-tabs>
    <b-card-body>
      <slot name="body" />
    </b-card-body>
    <b-card-footer v-if="$mq === 'xl'">
      <slot name="footer" />
    </b-card-footer>
  </b-card>
</template>

<script>
import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  props: {
    date: String,
    doctors: Array,
    doctorId: Number,
    disabled: Boolean,
    visibleTabs: Array,
  },

  data() {
    return {
      weekDays: this.getWeekDays(),
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    days() {
      const days = [];
      const day = this.formatDay(this.date, 'DD/MM/YYYY', 'E');

      this.weekDays.forEach(weekDay => {
        days.push({
          index: weekDay.index,
          name: weekDay.name,
          date: this.setDate(weekDay.index),
          active: weekDay.index == day,
          visible: this.isTabVisible(weekDay, day),
        });
      });

      return days;
    },
  },

  methods: {
    setDate(weekDay) {
      const date = this.getDateTime(this.date, 'DD/MM/YYYY');
      const day = this.formatDay(date, null, 'E');
      const diff = day - weekDay;

      return this.formatDate(date.subtract(diff, 'days'));
    },

    isTabVisible(weekDay, day) {
      return weekDay.index == day || this.visibleTabs.includes(weekDay.name);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.schedule-days {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 585px;
  background-color: transparent;

  header.print,
  .doctors-list {
    display: none;
  }

  .card-header {
    background-color: $white;

    .nav-tabs {
      display: flex;
      flex-direction: row;

      .nav-item {
        display: flex;
        flex-direction: column;
        width: 129px;
        background-color: $gray-100;

        .nav-link {
          height: 45px;
          padding: 5px 15px 2px;
          font-size: 12px;
          border-color: $gray-200;
          border-bottom-color: transparent;

          &:hover {
            border-color: $gray-300;
            border-bottom-color: transparent;
          }

          p {
            margin: 0;
          }

          .nav-day {
            text-align: left;
          }

          .nav-date {
            text-align: right;
          }
        }
      }
    }
  }

  .tab-content {
    display: none;
  }

  .card-body {
    padding: 15px 10px;
  }

  &.xs,
  &.sm {
    height: unset;

    .card-header .nav-tabs .nav-item {
      width: unset;

      .nav-link {
        height: unset;
      }
    }
  }
}
</style>
