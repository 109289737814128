<template>
  <b-card
    class="messages-card"
    :title="message.user"
    :sub-title="dateTime"
    :class="message.type"
  >
    <b-card-text>
      {{ message.content }}
    </b-card-text>
  </b-card>
</template>

<script>
import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  computed: {
    message() {
      return this.$store.state.message;
    },

    dateTime() {
      return this.formatDateTime(
        this.message.created_at,
        'YYYY-MM-DD HH:mm:ss',
        'DD/MM/YYYY HH:mm',
      );
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.messages-card {
  max-height: 230px;
  overflow-y: scroll;

  &.message {
    background-color: $white;
    border-color: $gray-300;
  }

  &.wait {
    background-color: $white-orange;
    border-color: $light-orange;
  }

  &.call {
    background-color: $white-blue;
    border-color: lighten($light-blue, 5%);
  }

  &.save {
    background-color: lighten($white-green, 15%);
    border-color: $white-green;
  }

  &.pending {
    background-color: lighten($purple, 33%);
    border-color: lighten($purple, 28%);
  }

  &.revert,
  &.cancel {
    background-color: $gray-200;
    border-color: darken($gray-300, 3%);
  }

  .card-body {
    padding: 10px;
  }

  .card-title {
    display: inline-block;
    margin: 0 15px 15px 0;
    font-size: 16px;
  }

  .card-subtitle {
    display: inline-block;
    padding-bottom: 5px;
    font-size: 12px;
    color: $gray-800 !important;
  }

  .card-text {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-size: 13px;
  }
}
</style>
