import helper from '@/mixins/helper';
import validate from '@/mixins/validate';

export default {
  mixins: [helper, validate],

  data() {
    return {
      customer_model: {
        name: {
          singular: 'customer',
          plural: 'customers',
        },
        listSize: 'lg',
        formSize: 'xl',
        mainField: 'company_name',
        secondaryField: 'name',
        actions: [{ name: 'add' }, { name: 'edit' }, { name: 'delete' }],
        dropdownActions: [{ name: 'edit' }, { name: 'delete' }],

        fields: {
          list: [
            {
              key: 'id',
              label: this.$t('fields.customer.id'),
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'company_name',
              label: this.$t('fields.customer.company_name'),
              sortable: true,
            },
            {
              key: 'company_representative',
              label: this.$t('fields.customer.company_representative'),
              sortable: true,
            },
            {
              key: 'license_date',
              label: this.$t('fields.customer.license_date'),
              formatter: value => this.formatDate(value),
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'license_status',
              label: this.$t('fields.customer.license_status.license_status'),
              translate: true,
              sortable: true,
            },
          ],

          view: [
            {
              groupName: this.$t('fields.customer.company'),
              type: 'list',
              fields: [
                { key: 'cnpj', label: this.$t('fields.customer.cnpj') },
                {
                  key: 'company_representative',
                  label: this.$t('fields.customer.company_representative'),
                },
                {
                  key: 'cpf',
                  label: this.$t('fields.customer.cpf'),
                  breakLine: true,
                },
                {
                  key: 'license_date',
                  label: this.$t('fields.customer.license_date'),
                  formatter: 'formatDate',
                },
                {
                  key: 'license_status',
                  label: this.$t(
                    'fields.customer.license_status.license_status',
                  ),
                },
                {
                  key: 'email',
                  label: this.$t('fields.customer.email'),
                  breakLine: true,
                },
                {
                  key: 'created_at',
                  label: this.$t('fields.customer.created_at'),
                  formatter: 'formatDate',
                },
                {
                  key: 'updated_at',
                  label: this.$t('fields.customer.updated_at'),
                  formatter: 'formatDate',
                },
              ],
            },
            {
              groupName: this.$t('fields.phone.phones'),
              type: 'list',
              fields: [
                { key: 'phone_mobile', label: this.$t('fields.phone.mobile') },
                { key: 'phone_home', label: this.$t('fields.phone.home') },
                { key: 'phone_work', label: this.$t('fields.phone.work') },
                { key: 'phone_other', label: this.$t('fields.phone.other') },
              ],
            },
            {
              groupName: this.$t('fields.address.address'),
              type: 'concat',
              fields: [
                { key: 'address_street' },
                { key: 'address_number' },
                { key: 'address_complement' },
                { key: 'address_district' },
                { key: 'address_city' },
                { key: 'address_region' },
                {
                  key: 'address_zip_code',
                  label: this.$t('fields.address.zip_code'),
                },
              ],
            },
            {
              type: 'list',
              groupName: this.$t('fields.customer.manual'),
              fields: [{ key: 'manual', label: '', type: 'file' }],
            },
          ],

          form: [
            {
              type: 'grid',
              gridTemplateColumns: '310px 3fr 1fr',
              fields: [
                {
                  key: 'name',
                  state: null,
                  required: true,
                  autofocus: true,
                  maxlength: 100,
                  formatter: 'lowerAll',
                },
                {
                  key: 'company_name',
                  state: null,
                  required: true,
                  maxlength: 100,
                  formatter: 'upperAll',
                },
                {
                  key: 'cnpj',
                  state: null,
                  mask: 'cnpj',
                  placeholder: '___.____.____/_____-___',
                  validation: 'checkCNPJ',
                  validationMsg: this.$t('messages.customer.cnpjInvalid'),
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '1fr 1fr 3fr  1fr',
              fields: [
                {
                  key: 'license_date',
                  state: null,
                  type: 'date',
                  required: true,
                },
                {
                  key: 'license_active',
                  state: null,
                  required: true,
                  type: 'select',
                  options: this.getBooleans(),
                },
                {
                  key: 'company_representative',
                  state: null,
                  required: true,
                  maxlength: 65,
                  formatter: 'captalize',
                },
                {
                  key: 'cpf',
                  state: null,
                  mask: 'cpf',
                  placeholder: '____.____.____-___',
                  validation: 'checkCPF',
                  validationMsg: this.$t('messages.customer.cpfInvalid'),
                },
              ],
            },
            {
              groupName: this.$t('fields.phone.phones'),
              type: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 270px',
              fields: [
                {
                  key: 'phone_mobile',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'phone_home',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'phone_work',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'phone_other',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'email',
                  state: null,
                  required: true,
                  maxlength: 55,
                  formatter: 'formatEmail',
                  validation: 'checkEmail',
                  validationMsg: this.$t('messages.customer.emailInvalid'),
                },
              ],
            },
            {
              groupName: this.$t('fields.address.address'),
              type: 'grid',
              gridTemplateColumns: '1fr 308px 1fr 270px',
              fields: [
                {
                  key: 'address_zip_code',
                  state: null,
                  mask: 'cep',
                  placeholder: '________-____',
                  validation: 'checkZipCode',
                  validationMsg: this.$t('messages.address.zipCodeInvalid'),
                  onKeyUp: 'searchAddress',
                },
                {
                  key: 'address_street',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_number',
                  state: null,
                  maxlength: 20,
                  formatter: 'removeExtraSpaces',
                },
                {
                  key: 'address_complement',
                  state: null,
                  maxlength: 20,
                  formatter: 'lowerAll',
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '3fr 3fr 1fr',
              fields: [
                {
                  key: 'address_district',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_city',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_region',
                  state: null,
                  type: 'select',
                  options: this.getBrazilianRegions(),
                },
              ],
            },
            {
              groupName: this.$t('fields.customer.settings'),
              type: 'grid',
              gridTemplateColumns: '3fr 1fr 3fr',
              fields: [
                { key: 'logo', state: null, required: true },
                {
                  key: 'background_color',
                  state: null,
                  type: 'color',
                  required: true,
                },
                { key: 'manual', state: null, required: true },
              ],
            },
          ],
        },
      },
    };
  },

  methods: {
    getGenders() {
      return [
        { value: null, text: '' },
        { value: 'male', text: this.$t('fields.gender.male') },
        { value: 'female', text: this.$t('fields.gender.female') },
      ];
    },

    getMaritalStatus() {
      return [
        { value: null, text: '' },
        { value: 'single', text: this.$t('fields.marital_status.single') },
        { value: 'married', text: this.$t('fields.marital_status.married') },
        {
          value: 'separated',
          text: this.$t('fields.marital_status.separated'),
        },
        { value: 'divorced', text: this.$t('fields.marital_status.divorced') },
        { value: 'widowed', text: this.$t('fields.marital_status.widowed') },
        { value: 'other', text: this.$t('fields.marital_status.other') },
      ];
    },

    getSkins() {
      return [
        { value: null, text: '' },
        { value: 'white', text: this.$t('fields.skin.white') },
        { value: 'black', text: this.$t('fields.skin.black') },
        { value: 'brown', text: this.$t('fields.skin.brown') },
        { value: 'other', text: this.$t('fields.skin.other') },
      ];
    },

    getCitizenships() {
      return [
        { value: null, text: '' },
        { value: 'brazilian', text: this.$t('fields.citizenship.brazilian') },
        { value: 'other', text: this.$t('fields.citizenship.other') },
      ];
    },
  },
};
