<template>
  <Modal>
    <b-modal
      id="appointments-wait-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @ok="confirm"
    >
      <input id="appointment-id" type="hidden" v-model="item.id" />
      <p class="msg">
        {{
          $t('confirmations.appointment.wait', {
            title: item.patient_name,
          })
        }}
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()">
            {{ $t('buttons.no') }}
          </b-button>
          <b-button variant="info" @click="ok()">
            {{ $t('buttons.yes') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';

import { mapState } from 'vuex';

export default {
  components: { Modal },

  computed: mapState(['item']),

  methods: {
    confirm() {
      this.$emit('setPatient');
      this.$bvModal.show('appointments-patient-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
#appointments-wait-modal {
  .msg {
    padding-bottom: 30px;
  }
}
</style>
