<template>
  <Modal>
    <b-modal
      id="appointments-form-modal"
      size="xl"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t(`appointments.${action}`) }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <div class="appointments-container" :class="$mq">
        <div>
          <b-alert v-if="revision" show>
            <p>{{ $t('messages.appointment.revision') }}</p>
            <p>
              {{
                $t('messages.appointment.lastConsultation', {
                  lastConsultationDate,
                  revisionDays,
                })
              }}
            </p>
          </b-alert>
          <AppointmentsList
            v-if="$mq === 'xl'"
            :appointments="appointments"
            :loadingAppointments="loadingAppointments"
          />
        </div>
        <b-form>
          <input id="appointment-id" type="hidden" v-model="item.id" />
          <b-form-group v-for="group in fields" :key="group.groupName">
            <legend v-if="group.groupName">{{ group.groupName }}</legend>
            <div
              class="form-fields"
              :class="group.type"
              :style="`grid-template-columns: ${group.gridTemplateColumns}`"
            >
              <FormField
                v-for="field in group.fields"
                :key="field.key"
                entity-name="appointment"
                entity-state="item"
                :field="field"
                :disabled="field.disabled || disableComponents"
                :mask="field.mask"
                :fit="item.fit === true"
                @patientChange="onPatientChanged()"
              />
            </div>
          </b-form-group>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="disableComponents"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="info" @click="ok()" :disabled="disableComponents">
            <Spinner v-if="savingAppointment" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';
import AppointmentsList from '@/components/appointments/AppointmentsList';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner, AppointmentsList },

  mixins: [api, helper],

  props: {
    action: String,
    doctors: Array,
    patients: Array,
    appointment_types: Array,
  },

  data() {
    return {
      appointment: {},
      appointments: [],
      loadingAppointments: false,
      disableComponents: false,
      savingAppointment: false,
      revision: false,
      revisionDays: null,
      lastConsultationDate: null,
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    item() {
      return this.$store.state.item;
    },

    fields() {
      return [
        {
          fields: [
            {
              key: 'doctor_id',
              state: true,
              type: 'select',
              options: this.getOptions('doctors', 'username'),
              disableCondition: ['id', 'notExists'],
            },
          ],
        },
        {
          type: 'grid',
          gridTemplateColumns: 'auto auto',
          fields: [
            {
              key: 'date',
              state: true,
              type: 'datepicker',
              disableCondition: ['id', 'notExists'],
            },
            {
              key: 'time_scheduling',
              state: true,
              type: 'timepicker',
              disableCondition: ['status', 'notEquals', 'scheduled'],
            },
            {
              key: 'type_id',
              state: true,
              placeholder: '',
              type: 'select',
              options: this.getOptions('appointment_types', 'name'),
            },
            {
              key: 'category',
              state: true,
              label: this.$t('fields.appointment.category.category'),
              placeholder: '',
              type: 'select',
              options: this.getCategories(),
            },
          ],
        },
        {
          fields: [
            {
              key: 'patient_name',
              state: true,
              placeholder: '',
              type: 'datalist',
              options: this.getList('patients', 'name'),
              formatter: 'captalize',
              changeFunction: 'patientChange',
              disableCondition: ['id', 'exists'],
            },
          ],
        },
        {
          groupName: this.$t('fields.phone.phones'),
          type: 'grid',
          gridTemplateColumns: 'auto auto',
          fields: [
            {
              key: 'phone_mobile',
              state: true,
              type: 'tel',
              placeholder: '(__) ______-_____',
            },
            {
              key: 'phone_home',
              state: true,
              type: 'tel',
              placeholder: '(__) ______-_____',
            },
            {
              key: 'phone_work',
              state: true,
              type: 'tel',
              placeholder: '(__) ______-_____',
            },
            {
              key: 'phone_other',
              state: true,
              type: 'tel',
              placeholder: '(__) ______-_____',
            },
          ],
        },
      ];
    },
  },

  methods: {
    async init() {
      if (this.item.status === 'canceled') {
        const item = {
          customer_id: this.item.customer_id,
          user_id: this.item.user_id,
          doctor_id: this.item.doctor_id,
          date: this.item.date,
          time_scheduling: this.item.time_scheduling,
          fit: this.item.fit,
        };

        this.$store.commit('mutate', { prop: 'item', with: { ...item } });
      }

      this.appointment = { ...this.item };

      if (this.appointment.id) {
        this.setPatientData();
      }
    },

    reset() {
      this.appointments = [];
      this.loadingAppointments = false;
      this.revision = false;
      this.revisionDays = null;
      this.lastConsultationDate = null;
    },

    onPatientChanged() {
      this.setPatientData();
    },

    async setPatientData() {
      await this.setPatient();
      await this.setRevision();
      await this.loadAppointments();
    },

    async setPatient() {
      const customer_id = this.customer.id;
      const patient_name = this.item.patient_name;

      await axios
        .get(`${this.baseApiUrl}/patients/${customer_id}/${patient_name}`)
        .then(res => {
          const patient = res.data || {};
          const item = { ...this.item };

          item.patient_id = patient.id;
          item.phone_mobile = patient.phone_mobile;
          item.phone_home = patient.phone_home;
          item.phone_work = patient.phone_work;
          item.phone_other = patient.phone_other;

          this.$store.commit('mutate', { prop: 'item', with: { ...item } });
        })
        .catch(this.showError);
    },

    async setRevision() {
      if (!this.item.id) {
        const { patient_id, doctor_id } = this.item;

        await axios
          .get(`${this.baseApiUrl}/consultations/${patient_id}/${doctor_id}`)
          .then(res => {
            const consultation = res.data || {};

            const revision = !!consultation.id;
            this.revision = revision;

            if (revision) {
              this.lastConsultationDate = this.formatDate(consultation.date);
              this.revisionDays =
                1 + this.getDatesDiff(new Date(), consultation.date);

              if (this.item.type_id === 3 || !this.item.type_id) {
                this.item.type_id = 7;
              }
            } else {
              this.lastConsultationDate = null;
              this.revisionDays = null;
            }
          })
          .catch(this.showError);
      }
    },

    async loadAppointments() {
      const patient_id = this.item.patient_id;

      if (patient_id) {
        this.loadingAppointments = true;

        await axios
          .get(`${this.baseApiUrl}/appointments/${patient_id}`)
          .then(res => {
            this.appointments = res.data || [];

            this.loadingAppointments = false;
          })
          .catch(this.showError);
      } else {
        this.appointments = [];
      }
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.saveAppointment();
    },

    saveAppointment() {
      this.savingAppointment = true;
      this.disableComponents = true;

      const appointment = { ...this.item, status: 'scheduled' };

      this.fields.forEach(group => {
        group.fields.forEach(field => {
          if (field.formatter) {
            appointment[field.key] = this[field.formatter](
              this.item[field.key],
            );
          }
        });
      });

      const method = appointment.id ? 'put' : 'post';
      const operation = this.$t(
        appointment.id
          ? 'messages.appointment.updated'
          : 'messages.appointment.added',
      );
      const id = appointment.id || '';

      axios[method](`${this.baseApiUrl}/appointments/${id}`, appointment)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.appointment.confirmed', {
              operation,
            }),
          });

          this.$emit('submit');
          this.$bvModal.hide('appointments-form-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.savingAppointment = false;
          this.disableComponents = false;
        });
    },

    getCategories() {
      return [
        {
          value: 'private',
          text: this.$t('fields.appointment.category.private'),
        },
        {
          value: 'unpaid',
          text: this.$t('fields.appointment.category.unpaid'),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#appointments-form-modal {
  .appointments-container {
    display: flex;
    flex-direction: row;

    .alert {
      width: 500px;
      padding-bottom: 0;
      font-size: 13px;
      color: $dark-yellow;
      background-color: $white-yellow;
      border-color: $light-yellow;
    }

    form {
      width: 405px;
    }

    &.xs,
    &.sm {
      flex-direction: column-reverse;

      form,
      .alert {
        width: unset;
      }
    }
  }
}
</style>
