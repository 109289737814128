<template>
  <b-button
    class="action-button"
    :class="action.divider ? `divider ${$mq}` : $mq"
    :variant="variant"
    v-b-modal="modal"
    :disabled="disabled || disabledAction || action.disabled"
    @click="$emit('emitFunction', clickFunction, clickFunctionParam)"
  >
    <div v-if="action.divider" class="divider"></div>
    <fa-icon :icon="icon" />
    <span>{{ label }}</span>
  </b-button>
</template>

<script>
import '@/config/font-awesome';

export default {
  props: {
    action: Object,
    entityName: String,
    isItemSelected: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      defaultActions: {
        add: {
          variant: 'success',
          icon: ['fas', 'plus'],
          modal: 'form',
          function: 'clearSelected',
        },
        edit: {
          variant: 'warning',
          icon: ['fas', 'pen'],
          modal: 'form',
          hasId: true,
        },
        delete: {
          variant: 'danger',
          icon: ['fas', 'trash'],
          modal: 'delete',
          hasId: true,
        },
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    defaultAction() {
      return this.defaultActions[this.action.name];
    },

    variant() {
      let variant;

      if (!this.defaultAction) {
        variant = this.action.variant;
      } else {
        variant = this.action.variant || this.defaultAction.variant;
      }

      return this.$mq === 'xs' ? variant : `outline-${variant}`;
    },

    icon() {
      let icon;

      if (!this.defaultAction) {
        icon = this.action.icon;
      } else {
        icon = this.action.icon || this.defaultAction.icon;
      }

      return icon;
    },

    label() {
      if (this.$mq === 'xs') {
        return '';
      }

      let label;

      if (!this.defaultAction) {
        label = this.action.label;
      } else {
        label = this.action.label || this.$t(`buttons.${this.action.name}`);
      }

      return label;
    },

    modal() {
      let modal;

      if (!this.defaultAction) {
        modal = this.action.modal;
      } else {
        modal = this.action.modal || this.defaultAction.modal;
      }

      return `${this.entityName}-${modal}-modal`;
    },

    disabledAction() {
      let disableCondition = false;

      if (this.action.disableCondition) {
        const [field, condition, value] = this.action.disableCondition;

        if (condition === 'exists') {
          disableCondition = !!this.item[field];
        } else if (condition === 'notExists') {
          disableCondition = !this.item[field];
        } else if (condition === 'equals') {
          disableCondition = this.item[field] === value;
        } else if (condition === 'notEquals') {
          disableCondition = this.item[field] !== value;
        }
      }

      let hasId;

      if (!this.defaultAction) {
        hasId = this.action.hasId;
      } else {
        hasId = this.action.hasId || this.defaultAction.hasId;
      }

      let forbidden = false;
      if (this.action.role) {
        if (this.action.role !== this.user.role && this.user.role !== 'admin') {
          forbidden = true;
        }
      }

      return disableCondition || (hasId && !this.isItemSelected) || forbidden;
    },

    clickFunction() {
      let clickFunction;

      if (!this.defaultAction) {
        clickFunction = this.action.function;
      } else {
        clickFunction = this.action.function || this.defaultAction.function;
      }

      return clickFunction;
    },

    clickFunctionParam() {
      let clickFunctionParam;

      if (!this.defaultAction) {
        clickFunctionParam = this.action.functionParam;
      } else {
        clickFunctionParam =
          this.action.functionParam || this.defaultAction.functionParam;
      }

      return clickFunctionParam;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/custom.scss';

.action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 80px;
  margin: 0 10px;
  padding: 5px;

  &:last-child {
    margin-right: 5px;
  }

  &:disabled {
    color: $gray-500;
    border-color: $gray-500;
  }

  &.divider {
    margin-left: 25px;
  }

  .divider {
    position: absolute;
    top: -14px;
    left: -18px;
    height: 55px;
    border-left: 1px solid $gray-300;
  }

  svg {
    padding-bottom: 2px;
  }

  span {
    padding: 0 4px;
    font-size: 12px;
  }

  &.xs {
    min-width: 10vw;
    margin: 0 0 0 10px;
    padding: 5px;

    span {
      display: none;
    }
  }
}
</style>
