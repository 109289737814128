import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeScreen from '@/components/home/HomeScreen';
import AuthScreen from '@/components/auth/AuthScreen';
import ForgotPasswordScreen from '@/components/auth/ForgotPasswordScreen';
import ResetPasswordScreen from '@/components/auth/ResetPasswordScreen';
import NotFoundScreen from '@/components/error/NotFoundScreen';

Vue.use(VueRouter);

const routes = [
  {
    name: 'home',
    path: '/',
    component: HomeScreen,
  },
  {
    name: 'auth',
    path: '/auth',
    component: AuthScreen,
  },
  {
    name: 'auth_forgot',
    path: '/auth_forgot',
    component: ForgotPasswordScreen,
  },
  {
    name: 'auth_reset',
    path: '/auth_reset',
    component: ResetPasswordScreen,
  },
  {
    name: 'not_found',
    path: '*',
    component: NotFoundScreen,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
