import helper from '@/mixins/helper';
import validate from '@/mixins/validate';

export default {
  mixins: [helper, validate],

  data() {
    return {
      contact_model: {
        name: {
          singular: 'contact',
          plural: 'contacts',
        },
        listSize: 'lg',
        mainField: 'name',
        actions: [{ name: 'add' }, { name: 'edit' }, { name: 'delete' }],
        dropdownActions: [{ name: 'edit' }, { name: 'delete' }],

        fields: {
          list: [
            {
              key: 'name',
              label: this.$t('fields.contact.name'),
              sortable: true,
            },
          ],

          view: [
            {
              groupName: this.$t('fields.phone.phones'),
              type: 'list',
              fields: [
                { key: 'phone_mobile', label: this.$t('fields.phone.mobile') },
                { key: 'phone_home', label: this.$t('fields.phone.home') },
                { key: 'phone_work', label: this.$t('fields.phone.work') },
                { key: 'phone_other', label: this.$t('fields.phone.other') },
              ],
            },
            {
              groupName: this.$t('fields.address.address'),
              type: 'concat',
              fields: [
                { key: 'address_street' },
                { key: 'address_number' },
                { key: 'address_complement' },
                { key: 'address_district' },
                { key: 'address_city' },
                { key: 'address_region' },
                {
                  key: 'address_zip_code',
                  label: this.$t('fields.address.zip_code'),
                },
              ],
            },
          ],

          form: [
            {
              fields: [
                {
                  key: 'name',
                  state: null,
                  required: true,
                  autofocus: true,
                  maxlength: 65,
                  formatter: 'removeExtraSpaces',
                },
              ],
            },
            {
              groupName: this.$t('fields.phone.phones'),
              type: 'grid',
              gridTemplateColumns: 'auto auto',
              fields: [
                {
                  key: 'phone_mobile',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'phone_home',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'phone_work',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
                {
                  key: 'phone_other',
                  state: null,
                  type: 'tel',
                  mask: 'phone',
                  placeholder: '(__) ______-_____',
                  validation: 'checkPhone',
                  validationMsg: this.$t('messages.phone.phoneInvalid'),
                },
              ],
            },
            {
              groupName: this.$t('fields.address.address'),
              type: 'grid',
              gridTemplateColumns: '30% auto',
              fields: [
                {
                  key: 'address_zip_code',
                  state: null,
                  mask: 'cep',
                  placeholder: '________-____',
                  validation: 'checkZipCode',
                  validationMsg: this.$t('messages.address.zipCodeInvalid'),
                  onKeyUp: 'searchAddress',
                },
                {
                  key: 'address_street',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '30% auto 15%',
              fields: [
                {
                  key: 'address_number',
                  state: null,
                  maxlength: 20,
                  formatter: 'removeExtraSpaces',
                },
                {
                  key: 'address_complement',
                  state: null,
                  maxlength: 20,
                  formatter: 'lowerAll',
                },
                {
                  key: 'address_region',
                  state: null,
                  type: 'select',
                  options: this.getBrazilianRegions(),
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: 'auto auto',
              fields: [
                {
                  key: 'address_district',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_city',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
              ],
            },
          ],
        },

        default: {
          actions: [],
          mobileActions: [],
          formFields: [],
        },
      },
    };
  },

  mounted() {
    this.contact_model.default.actions = this.contact_model.actions;
    this.contact_model.default.formFields = this.contact_model.fields.form;

    this.setMobileContact();
  },

  methods: {
    setMobileContact() {
      if (this.$mq === 'xl') {
        this.contact_model.actions = this.contact_model.default.actions;
      } else {
        this.contact_model.actions = this.contact_model.default.actions.filter(
          action => action.name === 'add',
        );
        this.contact_model.mobileActions =
          this.contact_model.default.actions.filter(
            action => action.name !== 'add',
          );
      }

      if (this.$mq === 'xs') {
        this.contact_model.fields.form.forEach(group => {
          if (group.type === 'grid') group.gridTemplateColumns = 'auto';

          group.fields.forEach(field => {
            if (field.required) delete field.required;
            if (field.validation) delete field.validation;
            if (field.validationMsg) delete field.validationMsg;
          });
        });
      } else {
        this.contact_model.fields.form = this.contact_model.default.formFields;
      }
    },
  },

  watch: {
    $mq() {
      this.setMobileContact();
    },
  },
};
