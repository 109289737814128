import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  data() {
    return {
      user_model: {
        name: {
          singular: 'user',
          plural: 'users',
        },
        listSize: 'lg',
        mainField: 'username',
        secondaryField: 'fullname',
        sortBy: 'username',
        actions: [
          { name: 'add', role: 'receptionist' },
          { name: 'edit', role: 'receptionist' },
          {
            name: 'schedule',
            label: this.$t('actions.user.schedule'),
            variant: 'primary',
            icon: ['far', 'calendar-alt'],
            modal: 'schedule',
            hasId: true,
            disableCondition: ['role', 'equals', 'receptionist'],
          },
          { name: 'delete', role: 'receptionist' },
        ],
        dropdownActions: [
          { name: 'edit', role: 'receptionist' },
          {
            name: 'schedule',
            label: this.$t('actions.user.schedule'),
            modal: 'schedule',
            disableCondition: ['role', 'equals', 'receptionist'],
          },
          { name: 'delete', role: 'receptionist' },
        ],

        fields: {
          list: [
            {
              key: 'fullname',
              label: this.$t('fields.user.listFullname'),
              sortable: true,
            },
            {
              key: 'username',
              label: this.$t('fields.user.listUsername'),
              sortable: true,
            },
            {
              key: 'user_role',
              label: this.$t('fields.user.role.role'),
              translate: true,
              sortable: true,
            },
            {
              key: 'status',
              label: this.$t('fields.user.status'),
              translate: true,
              sortable: true,
            },
          ],

          view: [
            {
              type: 'list',
              fields: [
                {
                  key: 'role',
                  label: this.$t('fields.user.role.role'),
                  translate: true,
                  breakLine: true,
                },
                {
                  key: 'status',
                  label: this.$t('fields.user.status.status'),
                  breakLine: true,
                },
                {
                  key: 'email',
                  label: this.$t('fields.user.email'),
                },
              ],
            },
          ],

          form: [
            {
              fields: [
                {
                  key: 'fullname',
                  state: null,
                  required: true,
                  placeholder: '',
                  formatter: 'captalize',
                  disableCondition: ['password', 'exists'],
                },
                {
                  key: 'username',
                  state: null,
                  required: true,
                  placeholder: '',
                  formatter: 'captalize',
                  disableCondition: ['password', 'exists'],
                },
                {
                  key: 'email',
                  state: null,
                  required: true,
                  placeholder: '',
                  formatter: 'formatEmail',
                  validation: 'checkEmail',
                  validationMsg: this.$t('messages.user.emailInvalid'),
                },
                {
                  key: 'role',
                  state: null,
                  required: true,
                  label: this.$t('fields.user.role.role'),
                  placeholder: '',
                  type: 'select',
                  options: this.getRoles(),
                  disableCondition: ['password', 'exists'],
                },
                {
                  key: 'active',
                  state: null,
                  required: true,
                  placeholder: '',
                  type: 'select',
                  options: this.getBooleans(),
                },
              ],
            },
          ],
        },

        default: {
          actions: [],
          mobileActions: [],
          listFields: [],
          viewFields: [],
          formFields: [],
        },
      },
    };
  },

  mounted() {
    this.user_model.default.actions = this.user_model.actions;
    this.user_model.default.listFields = this.user_model.fields.list;
    this.user_model.default.viewFields = this.user_model.fields.view;
    this.user_model.default.formFields = this.user_model.fields.form;

    this.setMobileUser();
  },

  methods: {
    getRoles() {
      return [
        { value: null, text: '' },
        { value: 'doctor', text: this.$t('user.role.doctor') },
        { value: 'receptionist', text: this.$t('user.role.receptionist') },
      ];
    },

    setMobileUser() {
      if (this.$mq === 'xl') {
        this.user_model.actions = this.user_model.default.actions;

        delete this.user_model.fields.view;
      } else {
        this.user_model.actions = this.user_model.default.actions.filter(
          action => action.name === 'add',
        );
        this.user_model.mobileActions = this.user_model.default.actions.filter(
          action => action.name !== 'add' && action.name !== 'schedule',
        );

        this.user_model.fields.view = this.user_model.default.viewFields;
      }

      if (this.$mq === 'xs') {
        this.user_model.fields.list = this.user_model.default.listFields.filter(
          field => field.key !== 'fullname',
        );

        this.user_model.fields.form.forEach(group => {
          if (group.type === 'grid') group.gridTemplateColumns = 'auto';

          group.fields.forEach(field => {
            if (field.required) delete field.required;
            if (field.validation) delete field.validation;
            if (field.validationMsg) delete field.validationMsg;
          });
        });
      } else {
        this.user_model.fields.list = this.user_model.default.listFields;
        this.user_model.fields.form = this.user_model.default.formFields;
      }
    },
  },

  watch: {
    $mq() {
      this.setMobileUser();
    },
  },
};
