import Vue from 'vue';

import VueMask from 'v-mask';
Vue.use(VueMask);

import moment from 'moment';
Vue.prototype.moment = moment;

import money from 'v-money';
Vue.use(money);

import api from '@/mixins/api';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  mixins: [api],

  computed: mapState(['address']),

  methods: {
    formatDateTime(dateTime, formatIn, formatOut) {
      if (formatOut === 'todayTime') {
        const today = moment(new Date()).format('YYYY-MM-DD');
        const date = moment(dateTime, formatIn).format('YYYY-MM-DD');
        if (today === date) {
          formatOut = 'HH:mm';
        } else {
          formatOut = 'DD/MM/YYYY HH:mm';
        }
      }

      const formatted = moment(dateTime, formatIn).format(formatOut);

      return formatted === 'Invalid date' ? '' : formatted;
    },

    formatDate(date, formatIn = 'YYYY-MM-DD', formatOut = 'DD/MM/YYYY') {
      return this.formatDateTime(date, formatIn, formatOut);
    },

    formatTime(time, formatIn = 'HH:mm:ss', formatOut = 'HH:mm') {
      return this.formatDateTime(time, formatIn, formatOut);
    },

    formatDay(date, formatIn = 'YYYY-MM-DD', formatOut = 'dddd') {
      const formatted = moment(date, formatIn).format(formatOut);

      return formatted === 'Invalid date' ? '' : formatted.toLowerCase();
    },

    getDateTime(dateTime, format) {
      return moment(dateTime, format);
    },

    getDuration(dateTime, format, startof) {
      let interval = moment(dateTime, format);
      interval -= moment().startOf(startof);

      return interval;
    },

    getDatesDiff(date1, date2) {
      date1 = moment(date1);
      date2 = moment(date2);

      return date1.diff(date2, 'days') - 1;
    },

    getWeekDays(format = 'dddd') {
      const weekDays = [];

      for (let i = 1; i <= 7; i++) {
        const weekDay = moment().isoWeekday(i).format(format).toLowerCase();
        weekDays.push({
          index: i,
          name: weekDay,
        });
      }

      return weekDays;
    },

    formatAge(date, format = 'YYYY-MM-DD') {
      date = moment(date, format);
      date = moment().diff(date);

      const age = moment.duration(date).add(1, 'days');
      const years = age.years();
      const months = age.months();
      const days = age.days();

      let ageFormatted = '';
      if (years > 0) {
        ageFormatted += `${years} `;
        ageFormatted +=
          years === 1
            ? this.$t('fields.age.year')
            : this.$t('fields.age.years');
      }
      if (months > 0) {
        if (years > 0) {
          ageFormatted += ', ';
        }
        ageFormatted += `${months} `;
        ageFormatted +=
          months === 1
            ? this.$t('fields.age.month')
            : this.$t('fields.age.months');
      }
      if (days > 0) {
        if (years > 0 || months > 0) {
          ageFormatted += ', ';
        }
        ageFormatted += `${days} `;
        ageFormatted +=
          days === 1 ? this.$t('fields.age.day') : this.$t('fields.age.days');
      }

      return ageFormatted;
    },

    formatContent(value) {
      let content = value.substring(0, 80);

      if (content.length < value.length) {
        content += '...';
      }
      return content;
    },

    formatPrice(value, prefix = 'R$') {
      if (value === 'variable') {
        return this.$t('fields.bill_expense.type_price.variable');
      }

      let price = (value / 1).toFixed(2).replace('.', ',');

      return (
        prefix + ' ' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      );
    },

    formatMonths(months) {
      if (months.length === 12) {
        return this.$t('bill_expenses.month.all');
      }

      return months.map(month => month.name).join(', ');
    },

    formatEmail(email) {
      return email ? email.trim().toLowerCase() : null;
    },

    captalize(name) {
      const rejects = ['E', 'Da', 'Das', 'De', 'Do', 'Dos'];

      name = this.removeExtraSpaces(name);
      name = name
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

      rejects.forEach(word => {
        name = name.replace(` ${word} `, ` ${word.toLowerCase()} `);
      });

      return name;
    },

    upperFirstLetter(name) {
      name = this.removeExtraSpaces(name);
      name = name
        .toLowerCase()
        .split('  ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('  ');

      return name;
    },

    upperAll(name) {
      name = this.removeExtraSpaces(name);
      name = name.toUpperCase();

      return name;
    },

    lowerAll(name) {
      name = this.removeExtraSpaces(name);
      name = name.toLowerCase();

      return name;
    },

    removeSpaces(value, replace = '') {
      if (!value) {
        return '';
      }

      return value.replace(/\s+/g, replace).trim();
    },

    removeExtraSpaces(value) {
      return this.removeSpaces(value, ' ');
    },

    getOptions(entity, field) {
      const options = [{}];

      this[entity].forEach((item, index) => {
        options[index] = {
          value: item.id,
          text: item[field],
          disabled: item.active === 0,
        };
      });

      return options;
    },

    getList(entity, field) {
      const list = [];

      this[entity].forEach(item => {
        list.push(item[field]);
      });

      return list;
    },

    getBooleans() {
      return [
        { value: null, text: '' },
        { value: '1', text: this.$t('fields.boolean.yes') },
        { value: '0', text: this.$t('fields.boolean.no') },
      ];
    },

    getBrazilianRegions() {
      const regions = [
        '',
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MG',
        'MS',
        'MT',
        'PA',
        'PB',
        'PE',
        'PI',
        'PR',
        'RJ',
        'RN',
        'RO',
        'RR',
        'RS',
        'SC',
        'SE',
        'SP',
        'TO',
      ];

      return regions.map(region => ({
        value: region,
        text: region,
      }));
    },

    searchAddress(zipCode) {
      zipCode = zipCode.replace(/\D/g, '');

      if (zipCode.length === 8) {
        axios
          .get(`${this.baseApiUrl}/contacts/address/${zipCode}`)
          .then(res => {
            const address = res.data || {};

            this.$store.commit('mutate', {
              prop: 'address',
              with: { ...address },
            });
          });
      }
    },
  },
};
