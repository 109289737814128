<template>
  <div class="search-input">
    <fa-icon :icon="['fas', 'search']" />
    <b-form-input
      id="search-input"
      type="search"
      :placeholder="$t('main.search')"
      v-model="filter"
      @input="$emit('filter', filter)"
      autofocus
      autocomplete="off"
      autocorrect="off"
      autocapitalize="none"
    ></b-form-input>
  </div>
</template>

<script>
import '@/config/font-awesome';

export default {
  props: {
    isCleared: Boolean,
  },

  data() {
    return {
      filter: null,
    };
  },

  watch: {
    isCleared(value) {
      if (value) {
        this.filter = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.search-input {
  display: flex;
  flex-direction: row;
  width: 270px;
  height: 27px;
  background: $gray-100;
  border: 1px solid $input-border-color;
  border-radius: 5px;

  svg {
    margin: 4px 6px 0;
    color: $gray-500;
    opacity: 0.7;
  }

  input {
    height: 27px;
    padding: 0 5px 0 2px;
    font-size: 13px;
    background: none;
    border: none;
    outline: none;

    &:focus {
      background: none;
    }

    &::placeholder {
      color: $gray-600;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 15px;
      width: 15px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: top right;
    }
  }
}
</style>
