import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    customer: {},
    customers: [],
    item: {},
    items: [],
    loadingItems: false,
    patient: {},
    schedule: {},
    refreshSchedule: false,
    message: {},
    refreshMessages: false,
    closeDialog: false,
    patientsSideBarVisible: false,
    patientSearch: {},
    goNext: false,
    address: {},
  },
  mutations: {
    mutate: (state, payload) => (state[payload.prop] = payload.with),
  },
});
