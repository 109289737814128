<template>
  <Modal>
    <b-modal
      id="bills-form-modal"
      size="lg"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t(`bills.${action}`) }}
        </h1>
        <button type="button" class="close" :disabled="saving" @click="close()">
          ×
        </button>
      </template>
      <b-form>
        <input id="bill-id" type="hidden" v-model="item.id" />
        <b-form-group v-for="group in fields" :key="group.groupName">
          <legend v-if="group.groupName">{{ group.groupName }}</legend>
          <div
            class="form-fields"
            :class="group.type"
            :style="`grid-template-columns: ${group.gridTemplateColumns}`"
          >
            <FormField
              v-for="field in group.fields"
              :key="field.key"
              entity-name="bill"
              entity-state="item"
              :field="field"
              :disabled="field.disabled || saving"
              :mask="field.mask"
              :allowed-options="field.allowedOptions && accounts"
              :year="field.month && year"
              :month="field.month && month"
            />
          </div>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()" :disabled="saving">
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="primary" @click="ok()" :disabled="saving">
            <Spinner v-if="saving" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, helper],

  props: {
    action: String,
    accounts: String,
    year: null,
    month: null,
  },

  data() {
    return {
      title: document.title,
      saving: false,
      itemData: {},
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    item() {
      return this.$store.state.item;
    },

    fields() {
      const fields = [
        {
          type: 'grid',
          gridTemplateColumns: 'auto 250px',
          fields: [
            {
              key: 'name',
              state: null,
              autofocus: true,
              placeholder: '',
              maxlength: 55,
              formatter: 'removeExtraSpaces',
            },
            {
              key: 'account',
              state: null,
              type: 'select',
              options: this.getAccounts(),
              allowedOptions: true,
            },
          ],
        },
        {
          fields: [
            {
              key: 'description',
              state: null,
              placeholder: '',
              maxlength: 255,
              formatter: 'removeExtraSpaces',
            },
          ],
        },
        {
          type: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 250px',
          fields: [
            {
              key: 'price',
              state: null,
              type: 'money',
              maxlength: 55,
            },
            {
              key: 'due_date',
              state: null,
              type: 'datepicker',
              month: true,
            },
            {
              key: 'payment_date',
              state: null,
              type: 'datepicker',
              reset: true,
            },
            {
              key: 'method',
              state: null,
              type: 'select',
              options: this.getMethods(),
              hideCondition: ['payment_date', 'notExists'],
            },
          ],
        },
      ];

      if (this.$mq !== 'xl') {
        fields.forEach(group => {
          if (group.type === 'grid') group.gridTemplateColumns = 'auto';
        });
      }

      return fields;
    },
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t(`bills.${this.action}`)}`;
      this.fields.forEach(group =>
        group.fields.forEach(field => {
          field.state = true;
        }),
      );
      this.itemData = { ...this.item };
    },

    reset() {
      document.title = `${this.appName} - ${this.$t('finances.bills.bills')}`;
      this.$store.commit('mutate', {
        prop: 'item',
        with: { ...this.itemData },
      });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.save();
    },

    save() {
      this.saving = true;

      const item = {
        ...this.item,
        customer_id: this.customer.id,
      };

      this.fields.forEach(group => {
        group.fields.forEach(field => {
          if (field.formatter) {
            item[field.key] = this[field.formatter](this.item[field.key]);
          }
        });
      });

      const method = item.id ? 'put' : 'post';
      const operation = this.$t(
        item.id ? 'messages.bill.updated' : 'messages.bill.added',
      );
      const id = item.id || '';

      axios[method](`${this.baseApiUrl}/bills/${id}`, item)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.bill.confirmed', {
              title: item.name,
              operation,
            }),
          });

          this.$emit('submit');

          this.$bvModal.hide('bills-form-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.saving = false;
        });
    },

    getAccounts() {
      return [
        { value: null, text: '' },
        { value: 'personal', text: this.$t('bills.account.personal') },
        { value: 'business', text: this.$t('bills.account.business') },
      ];
    },

    getMethods() {
      return [
        { value: 'slip', text: this.$t('bills.method.slip') },
        { value: 'credit_card', text: this.$t('bills.method.credit_card') },
        { value: 'debit_card', text: this.$t('bills.method.debit_card') },
        { value: 'account_debit', text: this.$t('bills.method.account_debit') },
        { value: 'pix', text: this.$t('bills.method.pix') },
        { value: 'transfer', text: this.$t('bills.method.transfer') },
        { value: 'deposit', text: this.$t('bills.method.deposit') },
        { value: 'cash', text: this.$t('bills.method.cash') },
        { value: 'check', text: this.$t('bills.method.check') },
        { value: 'other', text: this.$t('bills.method.other') },
      ];
    },
  },
};
</script>
