<template>
  <b-form-group
    v-if="!hideCondition"
    class="form-field"
    :label="label"
    :label-for="fieldId"
    :invalid-feedback="invalidFeedback"
  >
    <b-form-textarea
      v-if="field.type === 'textarea'"
      :id="fieldId"
      :placeholder="placeholder"
      v-model="item[field.key]"
      :maxlength="field.maxlength"
      :rows="field.rows || 3"
      no-resize
      :autofocus="autofocus"
      :required="field.required"
      :state="field.state"
      :disabled="disabled || disableCondition"
    />
    <b-form-select
      v-else-if="field.type === 'select'"
      :id="fieldId"
      v-model="item[field.key]"
      :options="fieldOptions || field.options"
      :autofocus="autofocus"
      :required="field.required"
      :state="field.state"
      :disabled="disabled || disableCondition"
    />
    <b-input-group v-else-if="field.type === 'datalist'">
      <b-form-input
        :list="fieldId"
        :id="`${fieldId}-list`"
        v-model="item[field.key]"
        :autofocus="autofocus"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="none"
        :required="field.required"
        :state="field.state"
        :disabled="disabled || disableCondition"
        @change="$emit(field.changeFunction)"
      />
      <b-form-datalist :id="fieldId" :options="field.options" />
    </b-input-group>
    <multiselect
      v-else-if="field.type === 'multiselect'"
      :id="fieldId"
      :placeholder="placeholder"
      v-model="item[field.key]"
      :options="field.options"
      :autofocus="autofocus"
      :required="field.required"
      :state="field.state"
      :disabled="disabled || disableCondition"
      :max-height="280"
      :multiple="true"
      :searchable="false"
      :close-on-select="true"
      :show-labels="false"
      :group-select="true"
      group-values="values"
      group-label="all"
      track-by="name"
      label="name"
    />
    <b-input-group v-else-if="field.type === 'datepicker'">
      <b-form-input
        type="date"
        :id="fieldId"
        v-model="item[field.key]"
        :autofocus="autofocus"
        :required="field.required"
        :state="field.state"
        :disabled="disabled || disableCondition"
      />
      <b-form-datepicker
        :id="`${fieldId}-time`"
        v-model="item[field.key]"
        :today-button="!field.month"
        today-button-variant="outline-dark"
        :label-today-button="$t('schedule.day.today')"
        button-only
        button-variant="none"
        right
        size="sm"
        locale="pt-BR"
        label-help=""
        hide-header
        :reset-button="field.reset"
        reset-button-variant="outline-dark"
        :label-reset-button="$t('buttons.reset')"
        label-no-date-selected=""
        :label-prev-year="$t('fields.date.previousYear')"
        :label-pre-month="$t('fields.date.previousMonth')"
        :label-current-month="$t('fields.date.currentMonth')"
        :label-next-month="$t('fields.date.nextMonth')"
        :label-next-year="$t('fields.date.nextYear')"
        :required="field.required"
        :state="field.state"
        :disabled="disabled || disableCondition"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
        :min="minDate"
        :max="maxDate"
      />
    </b-input-group>
    <b-input-group v-else-if="field.type === 'timepicker'">
      <b-form-input
        type="time"
        :id="fieldId"
        v-model="item[field.key]"
        :autofocus="autofocus"
        :required="field.required"
        :state="field.state"
        :disabled="disabled || disableCondition"
      />
      <b-form-timepicker
        :id="`${fieldId}-time`"
        v-model="item[field.key]"
        :class="fit && 'fit'"
        hide-header
        :label-hours="$t('fields.schedule.hours')"
        :label-minutes="$t('fields.schedule.minutes')"
        :label-close-button="$t('buttons.ok')"
        close-button-variant="outline-dark"
        button-only
        button-variant="none"
        right
        size="sm"
        minutes-step="5"
        label-no-time-selected=""
        :required="field.required"
        :state="field.state"
        :disabled="disabled || disableCondition"
      />
    </b-input-group>
    <money
      v-else-if="field.type === 'money'"
      :id="fieldId"
      v-model="item[field.key]"
      v-bind="money"
      class="form-control shadow-none"
      :class="item[field.key] === 0 ? 'zero' : ''"
      :maxlength="field.maxlength"
      :required="field.required"
      :state="field.state"
      :disabled="disabled || disableCondition"
    />
    <b-form-input
      v-else
      :type="field.type || 'text'"
      :id="fieldId"
      :placeholder="placeholder"
      v-model="item[field.key]"
      v-mask="masked"
      :maxlength="field.maxlength"
      :autofocus="autofocus"
      autocomplete="chrome-off"
      autocorrect="off"
      autocapitalize="none"
      :required="field.required"
      :state="field.state"
      :disabled="disabled || disableCondition"
      @keyup="onKeyUp()"
    />
  </b-form-group>
</template>

<script>
import Multiselect from 'vue-multiselect';

import helper from '@/mixins/helper';

export default {
  components: { Multiselect },

  mixins: [helper],

  props: {
    entityName: String,
    entityState: String,
    field: Object,
    disabled: Boolean,
    mask: String,
    fit: Boolean,
    allowedOptions: String,
    year: null,
    month: null,
  },

  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },

  computed: {
    item() {
      const item = this.$store.state[this.entityState];
      if (this.field.translate) {
        item[this.field.key] = this.$t(
          `${this.entityName}.${this.field.key}.${item[this.field.key]}`,
        );
      }
      if (this.field.type === 'date') {
        const date = item[this.field.key];
        if (date) {
          item[this.field.key] = date.substring(0, 10);
        }
      }
      return item;
    },

    fieldId() {
      return `${this.entityName}-${this.field.key}`;
    },

    label() {
      let label =
        this.field.label !== undefined
          ? this.field.label
          : this.$t(`fields.${this.entityName}.${this.field.key}`);

      if (this.field.required) {
        label += ' *';
      }

      return label;
    },

    placeholder() {
      let placeholder =
        this.field.placeholder !== undefined
          ? this.field.placeholder
          : this.$t(`fields.${this.entityName}.placeholder`, {
              field: this.$t(`fields.${this.entityName}.${this.field.key}`),
            });

      return placeholder;
    },

    invalidFeedback() {
      return this.field.validationMsg || this.$t('messages.requiredField');
    },

    autofocus() {
      return this.field.autofocus && !this.item[this.field.key];
    },

    masked() {
      switch (this.mask) {
        case 'month_day':
          return '##';
        case 'cep':
          return '#####-###';
        case 'cpf':
          return '###.###.###-##';
        case 'cnpj':
          return '##.###.###/####-##';
        case 'cpf_cnpj':
          if (this.item[this.field.key]) {
            const value = this.item[this.field.key];
            if (value.length <= 14) {
              return '###.###.###-##';
            } else {
              return '##.###.###/####-##';
            }
          }
        case 'phone':
          if (this.item[this.field.key]) {
            const value = this.item[this.field.key];
            if (value.length >= 15) {
              return '(##) #####-####';
            } else if (value.length > 10) {
              return '(##) ####-####';
            } else if (value.length > 9) {
              return '#####-####';
            } else {
              return '####-####';
            }
          }
      }
    },

    minDate() {
      const date = new Date(this.year, this.month - 1);
      const minDate = new Date(date.getFullYear(), date.getMonth(), 1);

      return minDate;
    },

    maxDate() {
      const date = new Date(this.year, this.month - 1);
      const maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      return maxDate;
    },

    fieldOptions() {
      if (this.allowedOptions) {
        const allowedOptions = this.allowedOptions.split(', ');
        const fieldOptions = this.field.options.filter(option =>
          allowedOptions.includes(`"${option.value}"`),
        );

        return fieldOptions;
      }

      return this.field.options;
    },

    disableCondition() {
      let disableCondition = false;

      if (this.field.disableCondition) {
        const [field, condition, value] = this.field.disableCondition;

        if (condition === 'exists') {
          disableCondition = !!this.item[field];
        } else if (condition === 'notExists') {
          disableCondition = !this.item[field];
        } else if (condition === 'equals') {
          disableCondition = this.item[field] === value;
        } else if (condition === 'notEquals') {
          disableCondition = this.item[field] !== value;
        }
      }

      return disableCondition;
    },

    hideCondition() {
      let hideCondition = false;

      if (this.field.hideCondition) {
        const [field, condition, value] = this.field.hideCondition;

        if (condition === 'exists') {
          hideCondition = !!this.item[field];
        } else if (condition === 'notExists') {
          hideCondition = !this.item[field];
        } else if (condition === 'equals') {
          hideCondition = this.item[field] === value;
        } else if (condition === 'notEquals') {
          hideCondition = this.item[field] !== value;
        }
      }

      return hideCondition;
    },

    address() {
      return this.$store.state.address;
    },
  },

  methods: {
    onKeyUp() {
      if (this.field.onKeyUp) {
        this[this.field.onKeyUp](this.item[this.field.key]);
      }
    },
  },

  watch: {
    address(obj) {
      if (this.field.onKeyUp && !obj.erro) {
        const item = this.$store.state[this.entityState];

        item.address_street = obj.logradouro;
        item.address_number = '';
        item.address_complement = obj.complemento;
        item.address_district = obj.bairro;
        item.address_city = obj.localidade;
        item.address_region = obj.uf;

        this.$store.commit('mutate', {
          prop: this.entityState,
          with: { ...item },
        });
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import '@/styles/custom.scss';

.form-field {
  label {
    font-size: 12px;
    font-weight: bold;
  }

  input,
  select,
  textarea {
    font-size: 12px;
  }

  input.zero {
    color: $gray-400;
  }

  &.form-group.is-invalid {
    margin: 0;
  }

  input.is-invalid,
  select.is-invalid,
  textarea.is-invalid {
    background: $white-red;
  }

  .invalid-feedback {
    margin-bottom: -17px;
    font-size: 12px;
    text-align: right;
  }

  .form-control:disabled {
    color: $gray-600;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

  input[type='date'],
  input[type='time'] {
    width: 100%;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &[disabled] {
      color: $gray-400;
      background-color: #f1f0f2;
      border-color: #f1f0f2;
    }
  }

  .input-group:not(.has-validation) > .form-control:not(:last-child) {
    border-radius: 5px;
  }

  .b-form-datepicker,
  .b-form-timepicker {
    position: absolute;
    height: 100%;
    width: 100%;

    &.b-form-btn-label-control.form-control > .form-control {
      font-size: 12px;
      font-weight: normal;
    }

    .b-calendar .b-calendar-grid {
      min-height: 283px;
      border-color: $gray-200;
      background-color: $white;

      footer {
        border-top: none !important;
      }
    }

    .dropdown-menu {
      background-color: $white-blue;
    }

    .form-control {
      border-color: $gray-200;
      background-color: $white;
    }

    button {
      color: $gray-700;

      &.dropdown-toggle {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        font-size: 11px;
      }
    }

    .b-form-date-controls button {
      margin: 0 auto;
    }

    footer button.btn:hover {
      color: $white;
    }

    &.fit .dropdown-menu .b-time div div:first-child {
      button {
        display: none;
      }

      bdi {
        color: $gray-600;
      }
    }
  }

  .multiselect {
    .multiselect__select::before {
      border-color: #000 transparent transparent;
    }

    .multiselect__tags {
      border-color: $gray-200;
      background-color: $gray-100;

      .multiselect__tag {
        background-color: $gray-600;

        .multiselect__tag-icon {
          &:after {
            color: unset;
          }

          &:hover {
            background: darken($gray-600, 10%);
          }
        }
      }
    }

    .multiselect__content-wrapper ul {
      li {
        span {
          font-size: 13px;
          font-weight: normal;

          &.multiselect__option--highlight {
            color: inherit;
            background: $light-purple;

            &.multiselect__option--selected {
              background: $light-red;
            }
          }
        }

        &:first-child span {
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
