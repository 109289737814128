<template>
  <Modal>
    <b-modal
      :id="`${entity.name.plural}-schedule-modal`"
      size="lg"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t(`${entity.name.plural}.schedule`) }}</h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <Skeleton
        v-if="loadingSchedules"
        :title="true"
        :buttons="0"
        :rows="9"
        :columns="1"
        :small="false"
      />
      <b-form v-else>
        <input
          :id="`${entity.name.singular}-id`"
          type="hidden"
          v-model="item.id"
        />
        <caption>
          <span>{{ $t('users.doctor') }}</span>
          {{
            item[entity.mainField]
          }}
        </caption>
        <b-table-simple sticky-header striped>
          <b-tbody>
            <b-tr v-for="(day, index) in days" :key="day.name">
              <b-td>
                <b-form-checkbox
                  :id="`checkbox-${day.name}`"
                  :name="`checkbox-${day.name}`"
                  v-model="days[index].checked"
                  size="sm"
                  :disabled="disableComponents || forbidden"
                >
                  <label :for="`checkbox-${day.name}`">
                    {{ $t(`schedule.day.${day.name}`) }}
                  </label>
                </b-form-checkbox>
                <div>
                  <div
                    v-for="(schedule, period) in day.schedules"
                    :key="period"
                    v-show="day.checked"
                  >
                    <label :for="`period-${period + 1}`" class="period-label">
                      <span>
                        {{ $t(`schedule.period.${period + 1}`) }}
                        {{ $t('schedule.period.period') }}</span
                      >
                    </label>
                    <label :for="`period-${period}-start`" class="period-label">
                      {{ $t(`schedule.start`) }}</label
                    >
                    <b-form-timepicker
                      v-model="days[index].schedules[period].start"
                      hide-header
                      reset-button
                      reset-button-variant="outline-info"
                      :label-reset-button="$t('buttons.reset')"
                      :label-hours="$t('fields.schedule.hours')"
                      :label-minutes="$t('fields.schedule.minutes')"
                      :label-close-button="$t('buttons.ok')"
                      close-button-variant="outline-dark"
                      size="sm"
                      minutes-step="5"
                      label-no-time-selected=""
                      :disabled="disableComponents || forbidden"
                    />
                    <label :for="`period-${period}-end`" class="period-label">
                      {{ $t(`schedule.end`) }}</label
                    >
                    <b-form-timepicker
                      v-model="days[index].schedules[period].end"
                      hide-header
                      reset-button
                      reset-button-variant="outline-info"
                      :label-reset-button="$t('buttons.reset')"
                      :label-hours="$t('fields.schedule.hours')"
                      :label-minutes="$t('fields.schedule.minutes')"
                      :label-close-button="$t('buttons.ok')"
                      close-button-variant="outline-dark"
                      size="sm"
                      minutes-step="5"
                      label-no-time-selected=""
                      :disabled="disableComponents || forbidden"
                    />
                    <label
                      :for="`period-${period}-interval`"
                      class="period-label"
                    >
                      {{ $t(`schedule.interval`) }}</label
                    >
                    <b-form-timepicker
                      v-model="days[index].schedules[period].interval"
                      hide-header
                      reset-button
                      reset-button-variant="outline-info"
                      :label-reset-button="$t('buttons.reset')"
                      :label-hours="$t('fields.schedule.hours')"
                      :label-minutes="$t('fields.schedule.minutes')"
                      :label-close-button="$t('buttons.ok')"
                      close-button-variant="outline-dark"
                      size="sm"
                      minutes-step="5"
                      label-no-time-selected=""
                      :disabled="disableComponents || forbidden"
                    />
                  </div>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="disableComponents"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            @click="ok()"
            :disabled="disableComponents || forbidden"
          >
            <Spinner v-if="savingSchedules" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Skeleton from '@/components/utils/Skeleton';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, Skeleton, Spinner },

  mixins: [api],

  props: {
    entity: Object,
  },

  data() {
    return {
      title: document.title,
      loadingSchedules: false,
      savingSchedules: false,
      disableComponents: false,
      forbidden: false,
      days: [],
    };
  },

  computed: mapState(['user', 'item']),

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t(
        `${this.entity.name.plural}.schedule`,
      )}`;

      this.$store.commit('mutate', { prop: 'closeDialog', with: true });

      this.initDays();

      this.loadSchedules();

      this.forbidden =
        this.user.role === 'doctor' && this.item.id !== this.user.id;
    },

    reset() {
      document.title = `${this.appName} - ${this.$t(
        `menu.items.${this.entity.name.plural}`,
      )}`;

      this.initDays();

      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    initDays() {
      const days = [
        { name: 'monday' },
        { name: 'tuesday' },
        { name: 'wednesday' },
        { name: 'thursday' },
        { name: 'friday' },
        { name: 'saturday' },
        { name: 'sunday' },
      ];

      days.forEach(day => {
        day.checked = false;
        day.schedules = [
          {
            start: null,
            end: null,
            interval: null,
          },
          {
            start: null,
            end: null,
            interval: null,
          },
        ];
      });

      this.days = days;
    },

    loadSchedules() {
      this.loadingSchedules = true;
      this.disableComponents = true;

      const doctor_id = this.item.id;

      axios
        .get(`${this.baseApiUrl}/schedules/daily/${doctor_id}`)
        .then(res => {
          const schedules = res.data || [];

          schedules.forEach(schedule => {
            this.days.forEach(day => {
              if (schedule.day === day.name) {
                day.checked = true;
                day.schedules[schedule.period - 1] = schedule;
              }
            });
          });

          this.loadingSchedules = false;
        })
        .catch(this.showError)
        .finally(() => {
          this.disableComponents = false;
        });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.savingSchedules = true;
      this.disableComponents = true;

      this.saveSchedules();
    },

    saveSchedules() {
      const doctor_id = this.item.id;
      const schedules = [];

      this.days.forEach(day => {
        if (day.checked) {
          day.schedules.forEach((schedule, period) => {
            if (schedule.start || schedule.end || schedule.interval) {
              schedules.push({
                type: 'daily',
                day: day.name,
                period: period + 1,
                start: schedule.start,
                end: schedule.end,
                interval: schedule.interval,
              });
            }
          });
        }
      });

      axios
        .post(`${this.baseApiUrl}/schedules/${doctor_id}`, schedules)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t(`messages.${this.entity.name.singular}.schedule`, {
              title: this.item[this.entity.mainField],
            }),
          });
          this.$emit('submit');

          this.$bvModal.hide(`${this.entity.name.plural}-schedule-modal`);
        })
        .catch(this.showError)
        .finally(() => {
          this.savingSchedules = false;
          this.disableComponents = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#users-schedule-modal {
  caption {
    display: block;
    padding: 0 0 15px;
    font-size: 13px;
    color: $gray-700;

    span {
      font-weight: bold;

      &::after {
        content: ':';
      }
    }
  }

  .b-table-sticky-header {
    max-height: 465px;
    margin-bottom: 0;
  }

  .table-striped tbody tr {
    &:nth-of-type(odd) {
      background-color: $gray-200;
    }
    &:nth-of-type(even) {
      background-color: $gray-100;
    }

    td {
      padding: 10px;
    }

    td,
    td > div > div {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }

    .custom-checkbox {
      width: 130px;
      align-self: center;
    }

    .period-label {
      padding: 0 3px 0 15px;
      font-size: 12px;

      &::after {
        content: ':';
      }

      span {
        display: inline-block;
        width: 60px;
        text-align: right;
        font-weight: bold;
        color: $gray-600;
      }
    }

    .form-control {
      border-color: $input-border-color;
      background-color: $white;
    }

    .b-form-btn-label-control.form-control {
      width: 110px;
      margin-right: 10px;
      font-size: 11px;
    }

    .dropdown-menu {
      background-color: $white-blue;
    }

    footer button {
      min-width: 110px;
      min-height: unset;
      margin-bottom: 10px;
    }
  }
}
</style>
