export default {
  methods: {
    checkFieldsValidity(item, fields) {
      let valid = this.checkFieldsRequired(item, fields);
      if (!valid) {
        return valid;
      }
      fields.forEach(field => {
        if (field.validation) {
          field.state = this[field.validation](item[field.key]);
          if (!field.state) valid = false;
        }
      });
      return valid;
    },

    checkFieldsRequired(item, fields) {
      let valid = true;
      fields.forEach(field => {
        if (field.required) {
          field.state = this.checkNotEmpty(item[field.key]);
          if (!field.state) valid = false;
        }
      });
      return valid;
    },

    checkNotEmpty(value) {
      if (
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'string' && !value.trim()) ||
        (typeof value !== 'number' && !value)
      ) {
        return false;
      }
      return true;
    },

    checkCPF(value) {
      if (!value || !value.trim()) {
        return true;
      }

      if (!this.checkField(value, /^\d\d\d.\d\d\d.\d\d\d\-\d\d/)) {
        return false;
      }

      value = value.replace(/\D/g, '');
      if (value.length !== 11) {
        return false;
      }

      for (let i = 0; i < 10; i++) {
        if (value === i.toString().repeat(11)) {
          return false;
        }
      }

      for (let n = 9; n <= 10; n++) {
        let add = 0;
        for (let i = 0; i < n; i++)
          add += parseInt(value.charAt(i)) * (n + 1 - i);
        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) rev = 0;
        if (rev != parseInt(value.charAt(n))) return false;
      }

      return true;
    },

    checkCNPJ(value) {
      if (!value || !value.trim()) {
        return true;
      }

      if (!this.checkField(value, /^\d\d.\d\d\d.\d\d\d\/\d\d\d\d\-\d\d/)) {
        return false;
      }

      value = value.replace(/\D/g, '');
      if (value.length !== 14) {
        return false;
      }

      for (let i = 0; i < 13; i++) {
        if (value === i.toString().repeat(14)) {
          return false;
        }
      }

      let size = value.length - 2;
      let numbers = value.substring(0, size);
      const digits = value.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != digits.charAt(0)) return false;

      size = size + 1;
      numbers = value.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != digits.charAt(1)) return false;

      return true;
    },

    checkCPFOrCNPJ(value) {
      if (!value || !value.trim()) {
        return true;
      }

      if (value.length === 14) {
        return this.checkCPF(value);
      } else if (value.length === 18) {
        return this.checkCNPJ(value);
      } else {
        return false;
      }
    },

    checkMonthDay(value) {
      if (!value) {
        return true;
      }
      if (typeof value === 'number') {
        value = value.toString();
      }
      if (!value || !value.trim()) {
        return true;
      }
      if (value.length === 1) {
        value = `0${value}`;
      }

      if (!this.checkField(value, /^\d\d/)) {
        return false;
      }

      if (value < 1 || value > 28) {
        return false;
      }

      return true;
    },

    checkEmail(value) {
      return this.checkField(
        value,
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      );
    },

    checkPhone(value) {
      return this.checkField(value, /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/);
    },

    checkZipCode(value) {
      return this.checkField(value, /^\d{5}-\d{3}$/);
    },

    checkField(value, regex) {
      if (!value) {
        return true;
      }

      if (typeof value === 'number') {
        if (!value.toString() || !value.toString().trim()) {
          return true;
        }
      }

      return regex.test(value);
    },
  },
};
