<template>
  <div class="auth" :class="$mq">
    <section>
      <Logo />
      <slot />
    </section>
    <Footer />
  </div>
</template>

<script>
import Logo from '@/components/utils/Logo';
import Footer from '@/components/utils/Footer';

import auth from '@/mixins/auth';

export default {
  components: { Logo, Footer },

  mixins: [auth],

  title() {
    return this.appName;
  },

  created() {
    this.unsetUser();
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 30px 0 80px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    padding: 50px;
    background: $auth-form-bg-color;
    border-radius: 5%;
  }

  .form-group {
    width: 100%;
  }

  input {
    font-size: 0.75rem;
  }

  button {
    width: 250px;
    min-height: 35px;
    margin-top: 20px;
    font-size: 0.8rem;
  }

  a {
    padding-top: 15px;
    font-size: 0.75rem;
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.sm,
  &.xs {
    form {
      width: 90vw;
      max-width: 350px;
      padding: 50px 6vw;
    }

    button {
      width: 100%;
    }
  }
}
</style>
