<template>
  <div id="app" :class="$mq">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable nuxt/no-globals-in-created*/

import auth from '@/mixins/auth';

import { mapState } from 'vuex';

export default {
  mixins: [auth],

  computed: mapState(['closeDialog']),

  created() {
    window.addEventListener('resize', function () {
      let fixedWidth = 1360;
      let fixedHeight = 750;

      window.resizeTo(fixedWidth, fixedHeight);
    });

    window.onbeforeunload = e => {
      e.preventDefault();
      if (this.closeDialog) {
        return '';
      }
    };
  },

  mounted() {
    if (process.env.NODE_ENV !== 'development') {
      //document.addEventListener('contextmenu', event => event.preventDefault());
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

body {
  background: $bg-color url('../public/img/background.jpg') center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100vh;
  overflow: hidden;

  &.xl {
    ~ div #patient-view-modal___BV_modal_content_ {
      min-height: 500px;
      max-height: 703px;
    }

    ~ div #patient-appointments-modal___BV_modal_content_ {
      min-height: 500px;
      max-height: 703px;
    }

    ~ div #patient-record-modal___BV_modal_content_ {
      height: 703px;
    }

    ~ div #patient-record-modal___BV_modal_body_ {
      padding-bottom: 15px;
    }

    ~ div #appointments-form-modal___BV_modal_content_ {
      height: 703px;
    }

    ~ div #appointment-schedules-modal___BV_modal_content_ {
      height: 703px;
    }

    ~ div #appointments-list-modal___BV_modal_content_ {
      min-height: 500px;
      max-height: 703px;
    }

    ~ div #appointments-record-modal___BV_modal_content_ {
      height: 703px;
    }

    ~ div #appointments-record-modal___BV_modal_body_ {
      padding-bottom: 15px;
    }

    ~ div #finances-revenues-modal___BV_modal_content_ {
      height: 703px;
    }

    ~ div #finances-bills-modal___BV_modal_content_ {
      height: 703px;
    }

    ~ div #bill_expenses-form-modal___BV_modal_content_ {
      height: 480px;
    }
  }

  &.xs,
  &.sm {
    ~ div .modal {
      overflow: scroll !important;

      .modal-content {
        margin: 0;
      }
    }

    ~ div #users-modal___BV_modal_content_,
    ~ div #appointment_types-modal___BV_modal_content_ {
      height: 605px;
    }

    ~ div #patient-record-modal___BV_modal_content_,
    ~ div #appointments-record-modal___BV_modal_content_ {
      height: 98vh;
    }

    ~ div #appointment-schedules-modal___BV_modal_content_ {
      min-height: 98vh;
    }

    ~ div #appointments-form-modal___BV_modal_content_ {
      height: 91vh;
    }

    ~ div #finances-revenues-modal___BV_modal_content_ {
      min-height: 98vh;
    }

    ~ div #finances-bills-modal___BV_modal_content_ {
      min-height: 98vh;
    }
  }
}
</style>
