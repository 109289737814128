<template>
  <div class="dropdownitem" v-if="!action.hidden">
    <b-dropdown-divider v-if="divider" />
    <b-dropdown-item
      :variant="variant"
      :disabled="disabled || action.disabled"
      v-b-modal="!disabled && modal"
      @click="$emit('emitFunction', clickFunction)"
    >
      {{ label }}
    </b-dropdown-item>
  </div>
</template>

<script>
export default {
  props: {
    action: Object,
    entityName: String,
  },

  data() {
    return {
      defaultActions: {
        edit: {
          modal: 'form',
        },
        delete: {
          variant: 'danger',
          modal: 'delete',
          divider: true,
        },
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    defaultAction() {
      return this.defaultActions[this.action.name];
    },

    variant() {
      let variant;

      if (!this.defaultAction) {
        variant = this.action.variant;
      } else {
        variant = this.action.variant || this.defaultAction.variant;
      }

      return variant;
    },

    label() {
      return this.action.label || this.$t(`actions.${this.action.name}`);
    },

    modal() {
      let modal;

      if (!this.defaultAction) {
        modal = this.action.modal;
      } else {
        modal = this.action.modal || this.defaultAction.modal;
      }

      return `${this.entityName}-${modal}-modal`;
    },

    divider() {
      let divider;

      if (!this.defaultAction) {
        divider = this.action.divider;
      } else {
        divider = this.action.divider || this.defaultAction.divider;
      }

      return divider;
    },

    disabled() {
      let disableCondition = false;

      if (this.action.disableCondition) {
        const [field, condition, value] = this.action.disableCondition;

        if (condition === 'exists') {
          disableCondition = !!this.item[field];
        } else if (condition === 'notExists') {
          disableCondition = !this.item[field];
        } else if (condition === 'equals') {
          disableCondition = this.item[field] === value;
        } else if (condition === 'notEquals') {
          disableCondition = this.item[field] !== value;
        }
      }

      let forbidden = false;
      if (this.action.role) {
        if (this.action.role !== this.user.role && this.user.role !== 'admin') {
          forbidden = true;
        }
      }

      return disableCondition || forbidden;
    },

    clickFunction() {
      let clickFunction;

      if (!this.defaultAction) {
        clickFunction = this.action.function;
      } else {
        clickFunction = this.action.function || this.defaultAction.function;
      }

      return clickFunction;
    },
  },
};
</script>
