<template>
  <Modal>
    <b-modal
      id="bills-delete-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <input id="bill-id" type="hidden" v-model="item.id" />
      <p class="msg">
        {{
          $t('confirmations.bill.delete', {
            title: item.name,
          })
        }}
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()" :disabled="deleting">
            {{ $t('buttons.no') }}
          </b-button>
          <b-button variant="danger" @click="ok()" :disabled="deleting">
            <Spinner v-if="deleting" size="small" />
            <span v-else>
              {{ $t('buttons.yes') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, Spinner },

  mixins: [api],

  data() {
    return {
      title: document.title,
      deleting: false,
    };
  },

  computed: mapState(['customer', 'item']),

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('bills.delete')}`;
    },

    reset() {
      document.title = `${this.appName} - ${this.$t('finances.bills.bills')}`;
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.deleting = true;
      this.delete();
    },

    delete() {
      const id = this.item.id;
      const title = this.item.name;

      axios
        .delete(`${this.baseApiUrl}/bills/${id}`)
        .then(() => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.bill.confirmed', {
              title,
              operation: this.$t('messages.bill.removed'),
            }),
          });

          this.$emit('submit');
        })
        .catch(this.showError)
        .finally(() => {
          this.deleting = false;

          this.$bvModal.hide('bills-delete-modal');
        });
    },
  },
};
</script>
