<template>
  <b-form-datepicker
    class="revenues-date datepicker"
    v-model="date"
    today-button
    today-button-variant="outline-dark"
    :label-today-button="$t('schedule.day.today')"
    :disabled="disabled"
    :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }"
    size="sm"
    locale="pt-BR"
    label-help=""
    hide-header
    :label-reset-button="$t('buttons.reset')"
    :label-no-date-selected="labelNoDateSelected"
    :label-prev-year="$t('fields.date.previousYear')"
    :label-pre-month="$t('fields.date.previousMonth')"
    :label-current-month="$t('fields.date.currentMonth')"
    :label-next-month="$t('fields.date.nextMonth')"
    :label-next-year="$t('fields.date.nextYear')"
  />
</template>

<script>
import '@/config/font-awesome';

import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  props: {
    dateType: String,
    selectedDate: String,
    labelNoDateSelected: String,
    disabled: Boolean,
  },

  data() {
    return {
      date: null,
    };
  },

  watch: {
    date(value) {
      const date = this.formatDate(value);
      this.$emit('dateChange', this.dateType, date);
    },

    selectedDate(value) {
      this.date = this.formatDate(value, 'DD/MM/YYYY', 'YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.revenues-date {
  display: flex;
  flex-direction: row;
  width: 130px;
  height: 26px;
  border-color: $gray-200;

  &.focus {
    background-color: $gray-100;
    border-color: $gray-300;
  }

  &.b-form-btn-label-control.form-control > .form-control {
    font-size: 12px;
    font-weight: normal;
  }

  button.btn {
    color: $gray-700;
  }

  .b-form-date-controls button {
    margin: 0 auto;
  }

  footer button.btn:hover {
    color: $white;
  }

  .b-calendar .b-calendar-grid {
    border-color: $gray-200;
    background-color: $white;
  }

  .b-form-datepicker {
    height: 32px;

    label {
      padding-top: 6px;
    }
  }

  .dropdown-menu {
    background-color: $white-blue;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 3px;

    svg {
      font-size: 12px;
      color: $gray-700;
      cursor: pointer;
    }
  }
}
</style>
