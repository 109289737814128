<template>
  <b-alert v-if="showAlert" class="waiting-alert" :class="$mq" show>
    {{ totalPatients }} {{ patients }}<br />
    {{ $t('messages.message.waitingTreatment') }}
  </b-alert>
</template>

<script>
import api from '@/mixins/api';

import axios from 'axios';

export default {
  mixins: [api],

  data() {
    return {
      totalPatients: 0,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    showAlert() {
      return this.totalPatients > 0;
    },

    patients() {
      return this.totalPatients === 1
        ? this.$t('messages.message.patient')
        : this.$t('messages.message.patients');
    },
  },

  created() {
    setInterval(() => {
      if (
        this.$route.name === 'home' &&
        this.user.id &&
        this.user.role === 'doctor'
      ) {
        this.setTotalPatients();
      }
    }, 5000);
  },

  methods: {
    async setTotalPatients() {
      await axios
        .get(`${this.baseApiUrl}/waiting/${this.user.id}`)
        .then(res => {
          this.totalPatients = res.data.length;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/custom.scss';

.waiting-alert {
  position: fixed;
  top: 36px;
  left: 6px;
  z-index: 1080;

  width: 182px;
  padding: 12px 7px 15px 8px;

  font-size: 14px;
  font-weight: bold;
  color: $dark-yellow;

  background-color: $white-yellow;
  border-color: $dark-yellow;

  &.xs {
    top: 12px;
    z-index: 1000;
  }
}
</style>
