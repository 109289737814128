<template>
  <Modal>
    <div :class="`${entity.name.plural}-modal`">
      <b-modal
        :id="`${entity.name.plural}-modal`"
        :size="entity.listSize"
        centered
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        @show="init"
        @hidden="reset"
      >
        <template #modal-header="{ close }">
          <h1>
            {{ $t(`menu.items.${entity.name.plural}`) }}
          </h1>
          <button
            type="button"
            class="close"
            :disabled="disableComponents"
            @click="close()"
          >
            ×
          </button>
        </template>
        <Skeleton
          v-if="loadingItems"
          :title="true"
          :buttons="entity.actions.length"
          :rows="7"
          :columns="entity.fields.list.length"
          :small="true"
        />
        <section v-else>
          <header>
            <SearchInput :is-cleared="filter === null" @filter="updateFilter" />
            <ActionButtons
              :entity-name="entity.name.plural"
              :actions="entity.actions"
              :is-item-selected="isItemSelected"
              @clearSelected="clearSelected"
            />
          </header>
          <ListTable
            :entity="entity"
            :fields="entity.fields.list"
            :filter="filter"
            :is-item-selected="isItemSelected"
            @itemselected="updateItemSelected"
          />
          <ActionsMenu
            v-if="isItemSelected && entity.fields.view && $mq !== 'xl'"
            :entity-name="entity.name.plural"
            :actions="entity.mobileActions"
            :is-item-selected="isItemSelected"
            @clearSelected="clearSelected"
          />
          <ViewCard
            v-if="isItemSelected && entity.fields.view"
            :entity="entity"
          />
        </section>
      </b-modal>
      <FormModal
        :entity="entity"
        :action="formAction"
        @submit="clearSelected"
      />
      <DeleteModal :entity="entity" @submit="clearSelected" />
      <ScheduleModal :entity="entity" @submit="clearSelected" />
      <PricesModal :entity="entity" @submit="clearSelected" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Skeleton from '@/components/utils/Skeleton';

import SearchInput from '@/components/items/SearchInput';
import ActionButtons from '@/components/utils/ActionButtons/ActionButtons';
import ActionsMenu from '@/components/utils/ActionsMenu/ActionsMenu';
import ListTable from '@/components/items/ListTable';
import ViewCard from '@/components/items/ViewCard';

import FormModal from '@/components/items/FormModal';
import DeleteModal from '@/components/items/DeleteModal';

import ScheduleModal from '@/components/users/ScheduleModal';
import PricesModal from '@/components/appointment_types/PricesModal';

import api from '@/mixins/api';

import axios from 'axios';

export default {
  components: {
    Modal,
    Skeleton,

    SearchInput,
    ActionButtons,
    ActionsMenu,

    ListTable,
    ViewCard,

    FormModal,
    DeleteModal,

    ScheduleModal,
    PricesModal,
  },

  mixins: [api],

  props: {
    entity: Object,
  },

  data() {
    return {
      title: document.title,
      disableComponents: false,
      filter: null,
      isItemSelected: false,
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    items() {
      return this.$store.state.items;
    },

    item() {
      return this.$store.state.item;
    },

    loadingItems() {
      return this.$store.state.loadingItems;
    },

    actions() {
      return this.entity.actions;
    },

    formAction() {
      return this.isItemSelected ? 'edit' : 'add';
    },
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t(
        `menu.items.${this.entity.name.plural}`,
      )}`;

      this.loadItems();
    },

    reset() {
      document.title = this.title;

      this.clearSelected();

      this.$store.commit('mutate', { prop: 'items', with: [] });
    },

    loadItems() {
      this.disableComponents = true;
      this.$store.commit('mutate', { prop: 'loadingItems', with: true });

      const customer_id = this.customer.id;
      const id =
        this.entity.name.singular !== 'customer' ? `/${customer_id}` : '';

      axios
        .get(`${this.baseApiUrl}/${this.entity.name.plural}${id}`)
        .then(res => {
          const items = res.data || [];

          this.$store.commit('mutate', { prop: 'items', with: items });
          this.$store.commit('mutate', { prop: 'loadingItems', with: false });
        })
        .catch(this.showError)
        .finally(() => {
          this.disableComponents = false;
        });
    },

    updateFilter(filter) {
      this.filter = filter;
    },

    updateItemSelected(value) {
      this.isItemSelected = value;
    },

    clearSelected() {
      this.isItemSelected = false;

      this.filter = null;

      this.$store.commit('mutate', { prop: 'item', with: {} });
    },
  },
};
</script>

<style lang="scss">
#customers-modal___BV_modal_content_ {
  height: 703px;

  .b-table-sticky-header {
    height: 130px;
  }
}
#patients-modal___BV_modal_content_ {
  height: 703px;
}
#contacts-modal___BV_modal_content_ {
  height: 605px;
}
#bill_expenses-modal___BV_modal_content_ {
  height: 605px;
}
</style>
