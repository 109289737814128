import api from '@/mixins/api';

import axios from 'axios';

export default {
  mixins: [api],

  data() {
    return {
      userKey: '__consultorioweb_user',
    };
  },

  methods: {
    setUser(user) {
      this.$store.commit('mutate', { prop: 'user', with: user });
      axios.defaults.headers.common.Authorization = `bearer ${user.token}`;
      localStorage.setItem(this.userKey, JSON.stringify(user));
    },

    unsetUser() {
      this.$store.commit('mutate', { prop: 'user', with: {} });
      this.$store.commit('mutate', { prop: 'customer', with: {} });
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem(this.userKey);
    },
  },
};
