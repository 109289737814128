<template>
  <div class="home" :class="$mq">
    <Spinner
      v-if="validatingToken || loadingCustomers"
      variant="secondary"
      size="large"
    />
    <div
      v-else
      class="home-container"
      :style="`background: ${background_color} url(${logo}) center no-repeat;`"
    >
      <header>
        <Menu v-if="$mq !== 'xs'" />
        <MobileMenu v-else />
        <div class="notifications">
          <WaitingAlert v-if="user.role === 'doctor'" />
          <BillsMessenger v-else />
          <Messenger />
        </div>
      </header>
      <b-button
        v-if="!patientsSideBarVisible"
        class="sidebar-button"
        v-b-modal.patients-sidebar
      >
        <fa-icon :icon="['fas', 'chevron-right']" />
      </b-button>
      <PatientsSidebar />
      <Logo v-if="$mq === 'xs'" />
      <footer v-if="$mq !== 'xs'">
        <div class="user-info">
          <strong>{{ $t('user.user') }}:</strong>
          {{ username }}
        </div>
        <div v-if="user.role === 'admin'" class="customer-select">
          <strong>{{ $t('customer.customer') }}:</strong>
          <b-form-select
            v-model="customer_id"
            :options="customersList"
            size="sm"
          />
        </div>
      </footer>
      <div v-else class="mobile-user">
        <div v-if="user.role === 'admin'" class="customer-select">
          <strong>{{ $t('customer.customer') }}:</strong>
          <b-form-select
            v-model="customer_id"
            :options="customersList"
            size="sm"
          />
        </div>
        <b-nav-item-dropdown class="menu-item" no-caret dropup>
          <template #button-content>
            <fa-icon :icon="['fas', 'user']" />
            {{ username }}
          </template>
          <b-dropdown-item v-b-modal.profile-modal>
            {{ $t('menu.user.profile') }}
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.change-password-modal>
            {{ $t('menu.user.changePassword') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-b-modal.signout-modal>
            {{ $t('menu.user.signOut') }}
          </b-dropdown-item>
          <ProfileModal />
          <ChangePasswordModal />
          <SignOutModal />
        </b-nav-item-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/utils/Spinner';
import Logo from '@/components/utils/Logo';
import Menu from '@/components/home/Menu';
import MobileMenu from '@/components/home/MobileMenu';
import WaitingAlert from '@/components/home/WaitingAlert';
import Messenger from '@/components/messages/Messenger';
import BillsMessenger from '@/components/finances/Bills/BillsMessenger';
import PatientsSidebar from '@/components/patients/PatientsSidebar';

import ProfileModal from '@/components/users/ProfileModal';
import ChangePasswordModal from '@/components/users/ChangePasswordModal';
import SignOutModal from '@/components/users/SignOutModal';

import api from '@/mixins/api';
import auth from '@/mixins/auth';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: {
    Spinner,
    Logo,
    Menu,
    MobileMenu,
    WaitingAlert,
    Messenger,
    BillsMessenger,
    PatientsSidebar,

    ProfileModal,
    ChangePasswordModal,
    SignOutModal,
  },

  mixins: [api, auth],

  data() {
    return {
      username: '',
      validatingToken: true,
      loadingCustomers: false,
      customersList: [],
      customer_id: null,
      background_color: '',
      logo: '',
    };
  },

  computed: mapState([
    'user',
    'customer',
    'customers',
    'patientsSideBarVisible',
    'goNext',
  ]),

  mounted() {
    document.title = this.appName;
    this.$store.commit('mutate', { prop: 'goNext', with: false });
    this.validateToken();
  },

  beforeRouteLeave(to, from, next) {
    const goNext = this.goNext;

    const modals = [
      'profile-modal',
      'change-password-modal',
      'signout-modal',

      'customers-modal',
      'customers-form-modal',
      'customers-delete-modal',

      'users-modal',
      'users-form-modal',
      'users-delete-modal',
      'users-schedule-modal',

      'appointment_types-modal',
      'appointment_types-form-modal',
      'appointment_types-delete-modal',
      'appointment_types-prices-modal',

      'contacts-modal',
      'contacts-form-modal',
      'contacts-delete-modal',

      'appointment-schedules-modal',
      'appointments-extra-schedule-modal',
      'appointments-form-modal',
      'appointments-cancel-modal',
      'appointments-revert-modal',
      'appointments-wait-modal',
      'appointments-call-modal',
      'appointments-authorize-modal',
      'appointments-patient-modal',
      'appointments-list-modal',
      'appointments-record-modal',
      'record-dialog-modal',

      'messages-modal',
      'messages-send-modal',

      'finances-revenues-modal',
      'finances-bills-modal',
      'bills-form-modal',
      'bills-delete-modal',
      'bill_expenses-modal',
      'bill_expenses-form-modal',
      'bill_expenses-delete-modal',

      'support-modal',
      'about-modal',

      'patients-sidebar',
      'patient-view-modal',
      'patient-form-modal',
      'patient-appointments-modal',
      'patient-record-modal',
    ];

    modals.forEach(modalName => {
      let modalElement = document.getElementById(modalName);
      if (modalElement) {
        this.$bvModal.hide(modalName);
      }
    });

    next(goNext);
  },

  methods: {
    async validateToken() {
      this.validatingToken = true;

      const json = localStorage.getItem(this.userKey);
      const user = JSON.parse(json);

      if (!user) {
        this.validatingToken = false;
        this.$store.commit('mutate', { prop: 'goNext', with: true });
        this.$router.push({ name: 'auth' });

        return;
      }

      const res = await axios.post(`${this.baseApiUrl}/validateToken`, user);

      if (res.data) {
        this.setUser(user);
        this.username = this.user.username;

        if (this.user.role === 'admin') {
          await this.loadCustomers();
        } else {
          await this.loadCustomerData();
        }
      } else {
        this.$toasted.global.defaultInfo({
          msg: this.$t('messages.user.invalidToken'),
        });

        this.$store.commit('mutate', { prop: 'goNext', with: true });
        this.$router.push({ name: 'auth' });
      }

      this.validatingToken = false;
    },

    setCustomersList(customers) {
      this.customersList = [{ value: null, text: '' }];

      customers.forEach((customer, index) => {
        this.customersList[index + 1] = {
          value: customer.id,
          text: customer.name,
        };
      });
    },

    async loadCustomers() {
      this.loadingCustomers = true;

      await axios
        .get(`${this.baseApiUrl}/customers`)
        .then(res => {
          const customers = res.data || [];

          this.setCustomersList(customers);
        })
        .catch(this.showError)
        .finally(() => {
          this.loadingCustomers = false;
        });
    },

    async loadCustomerData() {
      this.loadingCustomers = true;

      const customer = await this.getCustomer();
      const customerTitle = customer.name.toUpperCase();
      document.title = `${this.appName} - ${customerTitle}`;

      this.$store.commit('mutate', { prop: 'customer', with: { ...customer } });

      this.background_color = customer.background_color;
      this.logo = customer.logo;

      this.loadingCustomers = false;
    },

    async getCustomer() {
      let customer;
      const customer_id =
        this.user.role === 'admin' ? this.customer_id : this.user.customer_id;

      await axios
        .get(`${this.baseApiUrl}/customers/${customer_id}`)
        .then(res => {
          customer = res.data || {};
        })
        .catch(this.showError);

      return customer;
    },
  },

  watch: {
    user() {
      this.username = this.user.username;
    },

    customers(array) {
      this.setCustomersList(array);

      array.forEach(customer => {
        if (customer.id === this.customer_id) {
          this.$store.commit('mutate', {
            prop: 'customer',
            with: { ...customer },
          });

          this.background_color = customer.background_color;
          this.logo = customer.logo;
        }
      });
    },

    async customer_id() {
      if (this.customer_id) {
        await this.loadCustomerData();
      } else {
        document.title = this.appName;

        this.$store.commit('mutate', { prop: 'customer', with: {} });

        this.background_color = '';
        this.logo = '';
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.home {
  width: 100%;
  height: 100%;

  .spinner {
    margin-top: 200px;
  }

  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    color: $light-blue;
  }

  header {
    width: 100%;
  }

  .sidebar-button {
    align-self: flex-start;
    padding: 20px 5px;
    border-radius: unset;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: 1071;
  }

  .user-info {
    margin: 4px 8px;
    font-size: 13px;
    text-align: right;
  }

  .customer-select {
    margin: 4px 8px;
    font-size: 13px;
    text-align: right;

    .custom-select {
      width: 130px;
      height: 20px;
      margin: 0 0 2px 5px;
      padding: 0 5px;
      font-size: 12px;
    }
  }

  &.xs {
    .home-container {
      flex-direction: column-reverse;
      background-size: 70vw !important;
    }

    .logo {
      align-self: center;
      max-width: 80vw;
      margin: -30vh 0 calc(-30vh + 80px) 5vw;
    }

    .sidebar-button {
      padding: 20px 2px;
    }

    .menu-item {
      list-style: none;

      > a {
        color: inherit;
        outline: none;
      }
    }

    .mobile-user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .customer-select {
        margin-top: 10px;
      }
    }
  }
}
</style>
