<template>
  <Modal>
    <b-modal
      :id="`${entity.name.plural}-form-modal`"
      :size="entity.formSize"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t(`${entity.name.plural}.${action}`) }}
        </h1>
        <button type="button" class="close" :disabled="saving" @click="close()">
          ×
        </button>
      </template>
      <b-form>
        <input
          :id="`${entity.name.singular}-id`"
          type="hidden"
          v-model="item.id"
        />
        <b-form-group
          v-for="group in entity.fields.form"
          :key="group.groupName"
        >
          <legend v-if="group.groupName">{{ group.groupName }}</legend>
          <div
            class="form-fields"
            :class="group.type"
            :style="`grid-template-columns: ${group.gridTemplateColumns}`"
          >
            <FormField
              v-for="field in group.fields"
              :key="field.key"
              :entity-name="entity.name.singular"
              entity-state="item"
              :field="field"
              :disabled="field.disabled || saving"
              :mask="field.mask"
            />
          </div>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()" :disabled="saving">
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="primary" @click="ok()" :disabled="saving">
            <Spinner v-if="saving" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';
import validate from '@/mixins/validate';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, helper, validate],

  props: {
    entity: Object,
    action: String,
  },

  data() {
    return {
      title: document.title,
      saving: false,
      itemData: {},
    };
  },

  computed: mapState(['customer', 'item']),

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t(
        `${this.entity.name.plural}.${this.action}`,
      )}`;
      this.entity.fields.form.forEach(group =>
        group.fields.forEach(field => {
          field.state = true;
        }),
      );
      this.itemData = { ...this.item };
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });
    },

    reset() {
      document.title = `${this.appName} - ${this.$t(
        `menu.items.${this.entity.name.plural}`,
      )}`;
      this.$store.commit('mutate', {
        prop: 'item',
        with: { ...this.itemData },
      });
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      let fieldsValidity = true;
      this.entity.fields.form.forEach(group => {
        if (!this.checkFieldsValidity(this.item, group.fields)) {
          fieldsValidity = false;
        }
      });

      if (fieldsValidity) {
        this.saving = true;
        this.save();
      }
    },

    save() {
      const item =
        this.entity.name.singular === 'customer'
          ? { ...this.item }
          : {
              ...this.item,
              customer_id: this.customer.id,
            };

      this.entity.fields.form.forEach(group => {
        group.fields.forEach(field => {
          if (field.formatter) {
            item[field.key] = this[field.formatter](this.item[field.key]);
          }
        });
      });

      const method = item.id ? 'put' : 'post';
      const operation = this.$t(
        item.id
          ? `messages.${this.entity.name.singular}.updated`
          : `messages.${this.entity.name.singular}.added`,
      );
      const id = item.id || '';

      axios[method](`${this.baseApiUrl}/${this.entity.name.plural}/${id}`, item)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t(`messages.${this.entity.name.singular}.confirmed`, {
              title: item[this.entity.mainField],
              operation,
            }),
          });
          this.updateList();

          this.$emit('submit');

          this.$bvModal.hide(`${this.entity.name.plural}-form-modal`);
        })
        .catch(this.showError)
        .finally(() => {
          this.saving = false;
        });
    },

    updateList() {
      this.$store.commit('mutate', { prop: 'loadingItems', with: true });

      const customer_id = this.customer.id;
      const id =
        this.entity.name.singular !== 'customer' ? `/${customer_id}` : '';

      axios
        .get(`${this.baseApiUrl}/${this.entity.name.plural}${id}`)
        .then(res => {
          const items = res.data || [];

          this.$store.commit('mutate', { prop: 'items', with: items });

          if (this.entity.name.singular === 'customer') {
            this.$store.commit('mutate', { prop: 'customers', with: items });
          }

          this.$store.commit('mutate', { prop: 'loadingItems', with: false });
        })
        .catch(this.showError);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.modal-content fieldset {
  margin: 0;

  legend {
    font-size: 13px;
    font-weight: bold;
    color: $gray-700;

    &::after {
      content: ':';
    }
  }

  .form-fields.grid {
    display: grid;
    grid-column-gap: 20px;
  }
}

#contacts-form-modal,
#bill_expenses-form-modal {
  padding-left: 0 !important;
}
#patients-form-modal___BV_modal_content_ {
  margin: -30px 0 0 -10px;
}
</style>
