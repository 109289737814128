<template>
  <div class="messenger" :class="`${$mq} ${showIcon && 'bg'}`">
    <div v-if="showIcon" class="icon">
      <fa-icon :icon="['fas', 'envelope']" @click="readMessages()" />
      <b-badge pill variant="danger">
        {{ totalMessages }}
      </b-badge>
    </div>
    <div v-if="showMessages" class="messages-backdrop"></div>
    <div v-if="showMessages" class="messages">
      <div
        v-for="message in messages"
        :key="message.id"
        class="message"
        :class="message.type"
      >
        <button class="close" @click="closeMessage(message.id)">×</button>
        <p class="sender">{{ message.sender }}</p>
        <p v-if="message.type === 'confirmation'" class="confirmation">
          {{ $t('messages.message.confirmation') }}:
        </p>
        <p class="content">{{ message.content }}</p>
        <p class="date-time">{{ message.dateTime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import '@/config/font-awesome';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  mixins: [api, helper],

  data() {
    return {
      messages: [],
      hiddenMessages: [],
      totalMessages: 0,
      showIcon: false,
      showMessages: false,
    };
  },

  computed: mapState(['user']),

  created() {
    setInterval(async () => {
      if (this.$route.name === 'home' && this.user.id) {
        await this.setTotalMessages();
        await this.loadMessages();
      }
    }, 5000);
  },

  methods: {
    async setTotalMessages() {
      await axios
        .get(`${this.baseApiUrl}/messages/${this.user.id}`)
        .then(res => {
          const messages = res.data || [];
          this.totalMessages = messages.length;
          this.showIcon = this.totalMessages > 0;
        });
    },

    async loadMessages() {
      await axios
        .get(`${this.baseApiUrl}/messages/${this.user.id}`)
        .then(res => {
          const messages = res.data || [];

          messages.forEach(message => {
            message.dateTime = this.formatDateTime(
              message.created_at,
              'YYYY-MM-DD HH:mm:ss',
              'DD/MM/YYYY HH:mm',
            );
          });

          if (!this.showMessages) {
            this.messages = messages;
          } else {
            this.hiddenMessages = messages;
          }
        });
    },

    async readMessages() {
      this.showIcon = false;

      if (this.showMessages) {
        const readMessages = this.messages;
        this.hiddenMessages.forEach(message => {
          readMessages.push(message);
        });
        this.messages = readMessages;
      } else {
        this.showMessages = true;
      }

      this.messages.forEach(async message => {
        message.read = true;
        await axios.put(`${this.baseApiUrl}/messages/${message.id}`, message);

        if (message.type === 'message') {
          const confirmation = {
            sender_id: message.recipient_id,
            recipient_id: message.sender_id,
            type: 'confirmation',
            content: message.content,
          };
          await axios
            .post(`${this.baseApiUrl}/messages`, confirmation)
            .catch(this.showError);
        }
      });

      this.$store.commit('mutate', { prop: 'refreshSchedule', with: true });
      this.$store.commit('mutate', { prop: 'refreshMessages', with: true });
    },

    closeMessage(message_id) {
      this.messages = this.messages.filter(
        message => message.id !== message_id,
      );
      if (this.messages.length === 0) {
        this.showMessages = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/custom.scss';

.messenger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: fixed;
  top: 4px;
  right: 5px;
  z-index: 1080;

  .icon {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    z-index: 1091;

    svg {
      margin: 26px 3px 0 0;
      font-size: 60px;
      color: $yellow;
      cursor: pointer;

      &:hover {
        color: darken($yellow, 25%);
      }
    }

    .badge {
      margin-right: -5px;
      font-size: 14px;
    }
  }

  /*.messages-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #5b698760;
    z-index: 1090;
  }*/

  .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 320px;
    max-height: 540px;
    overflow-y: auto;
    z-index: 1091;

    .message {
      width: 300px;
      margin: 2px 0 3px;
      padding: 5px 10px 15px 15px;
      font-size: 14px;
      color: $body-color;
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: -1px 0 3px 1px rgba(0, 0, 0, 0.39);

      &.message {
        background-color: $white-yellow;
      }

      &.confirmation {
        background-color: $white;
      }

      &.wait {
        background-color: $light-orange;
      }

      &.revert,
      &.cancel {
        background-color: $gray-300;
      }

      &.call {
        background-color: $light-blue;
      }

      &.save {
        background-color: $white-green;
      }

      &.pending {
        background-color: lighten($purple, 28%);
      }

      .sender {
        margin: 0;
        padding: 3px 0 10px;
        font-weight: bold;
      }

      .confirmation {
        font-style: italic;
      }

      .date-time {
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: $gray-800;
        text-align: right;
      }
    }
  }

  &.xs {
    &.bg {
      padding: 10px;
      border-radius: 10px;
      background-color: #000;
    }

    .messages {
      .message {
        width: 80vw;
        max-width: 300px;
      }
    }
  }
}
</style>
