<template>
  <Modal>
    <b-modal
      id="patient-view-modal"
      size="xl"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t('patients.info') }}</h1>
        <button type="button" class="close" @click="close()">×</button>
      </template>
      <div class="patient-info" :class="$mq">
        <div>
          <span>{{ $t('patients.patient') }}</span>
          {{ patient.name }}
        </div>
        <div>
          <span>{{ $t('fields.patient.id') }}</span>
          {{ patient.id }}
        </div>
        <div v-if="patient.birth_date">
          <span>{{ $t('fields.patient.birth_date') }}</span>
          {{ formatDate(patient.birth_date) }}
        </div>
        <div v-if="patient.birth_date">
          <span>{{ $t('fields.age.age') }}</span>
          {{ formatAge(patient.birth_date) }}
        </div>
        <div v-if="patient.first_appointment_date">
          <span>{{ $t('fields.patient.first_appointment_date') }}</span>
          {{ formatDate(patient.first_appointment_date) }}
        </div>
        <div v-if="patient.last_appointment_date">
          <span>{{ $t('fields.patient.last_appointment_date') }}</span>
          {{ formatDate(patient.last_appointment_date) }}
        </div>
        <div v-if="patient.appointments">
          <span>{{ $t('fields.patient.appointments') }}</span>
          {{ patient.appointments }}
        </div>
      </div>
      <b-button-group class="action-buttons">
        <ActionButton
          v-for="action in actions"
          :key="action.name"
          :action="action"
          entity-name="patient"
        />
      </b-button-group>
      <div
        v-if="Object.keys(record).length !== 0"
        class="patient-view-card"
        :class="$mq"
      >
        <b-card>
          <!--eslint-disable vue/no-use-v-if-with-v-for -->
          <b-card-text
            v-for="group in patient_model.fields.view"
            :key="group.groupName"
            v-if="groupFields[group.groupName]"
          >
            <caption
              v-if="
                Object.keys(groupFields).length > 1 &&
                groupFields[group.groupName]
              "
            >
              {{
                group.groupName
              }}
            </caption>
            <ul :class="group.type">
              <li
                v-for="field in group.fields"
                :key="field.key"
                v-if="record[field.key]"
              >
                <span>
                  <label
                    v-if="
                      groupFields[group.groupName].length > 1 && field.label
                    "
                    :for="field.key"
                  >
                    {{ field.label }}</label
                  >
                  <span>{{ record[field.key] }}</span></span
                >
                <br v-if="field.breakLine" />
              </li>
            </ul>
          </b-card-text>
        </b-card>
      </div>
    </b-modal>
    <PatientFormModal @updatePatient="$emit('updatePatient')" />
    <PatientAppointmentsModal />
    <PatientRecordModal />
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import ActionButton from '@/components/utils/ActionButtons/ActionButton';
import PatientFormModal from '@/components/patients/PatientFormModal';
import PatientAppointmentsModal from '@/components/patients/PatientAppointmentsModal';
import PatientRecordModal from '@/components/patients/PatientRecordModal';

import patient_model from '@/models/patient';

import helper from '@/mixins/helper';

export default {
  components: {
    Modal,
    ActionButton,
    PatientFormModal,
    PatientAppointmentsModal,
    PatientRecordModal,
  },

  mixins: [patient_model, helper],

  data() {
    return {
      title: document.title,
      record: {},
      actions: [
        {
          name: 'editPatient',
          label: this.$t('buttons.edit'),
          variant: 'warning',
          icon: ['fas', 'pen'],
          modal: 'form',
        },
        {
          name: 'appointments',
          label: this.$t('actions.patient.appointments'),
          variant: 'info',
          icon: ['far', 'calendar-alt'],
          modal: 'appointments',
        },
        {
          name: 'records',
          label: this.$t('actions.patient.records'),
          variant: 'primary',
          icon: ['far', 'clipboard'],
          modal: 'record',
        },
      ],
    };
  },

  computed: {
    patient() {
      return this.$store.state.patientSearch;
    },

    groupFields() {
      const groupFields = [];

      this.patient_model.fields.view.forEach(group => {
        const fields = group.fields.filter(field => this.patient[field.key]);
        if (fields.length > 0) {
          groupFields[group.groupName] = fields;
        }
      });

      return groupFields;
    },
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('patients.info')}`;

      this.setRecord();
    },

    reset() {
      document.title = this.title;

      this.record = {};

      this.$store.commit('mutate', {
        prop: 'patientSearch',
        with: {},
      });
    },

    setRecord() {
      const record = {};

      this.patient_model.fields.view.forEach(group => {
        group.fields.forEach(field => {
          if (this.patient[field.key]) {
            if (field.translate) {
              record[field.key] = this.$t(
                `fields.${field.key}.${this.patient[field.key]}`,
              );
            } else {
              record[field.key] = this.patient[field.key];
            }

            if (field.formatter) {
              record[field.key] = this[field.formatter](
                this.patient[field.key],
              );
            }
          }
        });
      });

      this.record = { ...record };
    },
  },

  watch: {
    patient() {
      this.setRecord();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#patient-view-modal {
  #patient-view-modal___BV_modal_body_ {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .patient-info {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-column-gap: 20px;
      width: 100%;

      div {
        padding: 0 0 5px;
        font-size: 13px;
        color: $gray-700;
      }

      span {
        padding: 0 0 5px;
        font-size: 13px;
        font-weight: bold;
        color: $gray-700;

        &::after {
          content: ':';
        }
      }

      &.xs {
        grid-template-columns: auto;
      }

      &.sm {
        grid-template-columns: auto auto;
      }
    }

    .action-buttons {
      margin: 15px 0 20px 0;
    }

    .patient-view-card {
      width: 100%;

      .card-body {
        padding: 10px;
      }

      .card-title {
        display: inline-block;
        margin: 0 15px 15px 0;
        font-size: 16px;
      }

      .card-subtitle {
        display: inline-block;
        padding-bottom: 5px;
        font-size: 12px;
        color: $gray-700 !important;
      }

      .card-text {
        display: flex;
        flex-direction: row;
        margin: 0;
        font-size: 13px;
      }

      caption {
        padding: 0 5px 0 0;
        font-weight: bold;
        color: $body-color;
      }

      ul {
        margin: 0;
        padding: 0 0 10px;

        li {
          display: inline;
          list-style: none;
        }

        &.list {
          li {
            padding-right: 5px;
          }

          label {
            font-weight: bold;
            color: $gray-700;

            &:after {
              content: ':';
              padding-right: 3px;
            }
          }
        }

        &.concat {
          li::after {
            content: ', ';
          }

          li:last-child::after {
            content: '';
          }
        }
      }

      &.xs,
      &.sm {
        .card-text {
          flex-direction: column;
        }

        caption {
          padding-bottom: 10px;
        }

        ul {
          li {
            display: inherit;
          }
        }
      }
    }
  }
}
</style>
