<template>
  <footer class="bills-footer" :class="className">
    <div>
      <span>{{ $t('finances.bills.totalPaid') }}</span>
      {{ total.bills }}
      {{ $t('finances.bills.totalOf') }}
      {{ bills.length }}
    </div>
    <div>
      <span>{{ $t('finances.bills.totalPricePaid') }}</span>
      {{ formatPrice(total.value) }}
    </div>
  </footer>
</template>

<script>
import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  props: {
    total: Object,
    bills: Array,
    className: String,
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.bills-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 5px 15px;
  font-size: 13px;

  span:after {
    content: ':';
  }

  &.print {
    display: none;
  }
}
</style>
