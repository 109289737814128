<template>
  <div class="messages-table">
    <b-table
      small
      sticky-header
      striped
      borderless
      outlined
      hover
      show-empty
      no-border-collapse
      :tbody-tr-class="`row-class ${tab}`"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-clicked="selectRow"
      :empty-text="emptyText"
    >
    </b-table>
  </div>
</template>

<script>
import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  props: {
    tab: String,
    fields: Array,
    items: Array,
    isItemSelected: Boolean,
  },

  data() {
    return {
      selectedRow: null,
      sortBy: 'created_at',
      sortDesc: true,
      emptyText: this.$t('messages.message.empty'),
    };
  },

  computed: {
    message() {
      return this.$store.state.message;
    },

    isRowSelected() {
      return this.selectedRow !== null;
    },
  },

  methods: {
    selectRow(item, index, event) {
      if (this.tab === 'received') {
        item._rowVariant = item.type;
        this.$emit('readMessage', item);
      }

      if (this.selectedRow !== index) {
        this.selectedRow = index;
      } else {
        this.clearSelected();
      }

      this.$store.commit('mutate', { prop: 'message', with: { ...item } });

      this.$emit('itemselected', this.isRowSelected);
    },

    clearSelected() {
      this.selectedRow = null;
      this.$emit('itemselected', this.isRowSelected);

      this.$store.commit('mutate', { prop: 'message', with: {} });
    },
  },
  watch: {
    selectedRow(value) {
      const elements = document.getElementsByClassName(this.tab);

      elements.forEach(element => {
        element.style.backgroundColor = '';
      });
      if (value !== null) {
        elements[value].style.backgroundColor = '#aacdfa';
      }
    },

    isItemSelected(value) {
      if (!value) {
        this.clearSelected();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.messages-table {
  .b-table-sticky-header {
    max-height: 300px;
    margin-bottom: 20px;
    white-space: nowrap;
  }

  table {
    thead {
      display: none;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .row-class {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: $light-blue;
    }
  }

  .table.b-table > thead > tr > .table-b-table-default {
    color: $white;
    background-color: $primary;
  }

  .table-message {
    color: $body-color;
  }

  .table-message-unread {
    font-weight: bold;
    color: $body-color;
  }

  .table-wait {
    color: $orange;
  }

  .table-wait-unread {
    font-weight: bold;
    color: $orange;
  }

  .table-call {
    color: $blue;
  }

  .table-call-unread {
    font-weight: bold;
    color: $blue;
  }

  .table-save {
    color: $dark-green;
  }

  .table-save-unread {
    font-weight: bold;
    color: $dark-green;
  }

  .table-pending {
    color: $purple;
  }

  .table-pending-unread {
    font-weight: bold;
    color: $purple;
  }

  .table-revert,
  .table-cancel {
    color: $gray-700;
  }

  .table-revert-unread,
  .table-cancel-unread {
    font-weight: bold;
    color: $gray-700;
  }
}
</style>
