<template>
  <Modal>
    <b-modal
      id="appointment-schedules-modal"
      size="xl"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t('appointments.schedules') }}</h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <header v-show="!loading.data" class="schedule-header" :class="$mq">
        <section>
          <ScheduleDate
            :selected-date="date"
            :disabled="loading.data || loading.schedule"
            @dateChange="onDateChanged"
          />
          <ScheduleDoctors
            :doctors="doctors"
            :disabled="loading.data || loading.schedule"
            @doctorChange="onDoctorChanged"
          />
        </section>
        <section>
          <ActionButtons
            entity-name="appointments"
            :actions="headerActions"
            :disabled="!doctor.id || loading.data || loading.schedule"
            @print="print"
          />
        </section>
      </header>
      <Spinner v-if="loading.data" variant="info" size="large" />
      <section v-else-if="doctor.id">
        <ScheduleDays
          :date="date"
          :doctors="doctors"
          :doctor-id="doctor.id"
          :disabled="loading.schedule"
          :visible-tabs="visibleTabs"
          @dateChange="onDateChanged"
        >
          <template #body>
            <Skeleton
              v-if="loading.schedule"
              :title="false"
              :buttons="0"
              :rows="14"
              :columns="6"
              :small="true"
            />
            <ScheduleTable
              v-else
              :date="date"
              :doctor="doctor"
              :fields="fields"
              :is-item-selected="isItemSelected"
              @itemselected="updateItemSelected"
              @setFit="setFit"
              @setWait="setWait"
              @setBlock="setBlock"
              @setUnlock="setUnlock"
              @setAbsence="setAbsence"
              @setPatient="setPatient"
            />
          </template>
          <template #footer>
            <ActionButtons
              v-if="!loading.schedule"
              entity-name="appointments"
              :actions="actionButtons"
              :is-item-selected="isItemSelected"
              :disabled="disableActions"
              @setFit="setFit"
              @setWait="setWait"
              @setBlock="setBlock"
              @setUnlock="setUnlock"
              @setAbsence="setAbsence"
              @setPatient="setPatient"
            />
          </template>
        </ScheduleDays>
      </section>
    </b-modal>
    <AppointmentsFormModal
      :action="formAction"
      :doctors="doctors"
      :patients="patients"
      :appointment_types="appointment_types"
      @submit="onSubmitForm"
    />
    <AppointmentsWaitModal @submit="onSubmitForm" @setPatient="setPatient" />
    <AppointmentsAuthorizeModal @submit="onSubmitForm" />
    <AppointmentsCancelModal @submit="onSubmitForm" />
    <AppointmentsRevertModal :doctor-id="doctor.id" @submit="onSubmitForm" />
    <AppointmentsCallModal @setPatient="setPatient" @submit="onSubmitForm" />
    <AppointmentsPatientModal
      :wait="wait"
      @wait="setWaiting"
      @submit="onSubmitForm"
    />
    <AppointmentsRecordModal @submit="onSubmitForm" />
    <AppointmentsListModal @submit="onSubmitForm" />
    <ExtraScheduleModal
      :doctors="doctors"
      :doctor-id="doctor.id"
      @submit="onSubmitForm"
    />
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';
import Skeleton from '@/components/utils/Skeleton';
import ScheduleDate from '@/components/appointments/ScheduleDate';
import ScheduleDoctors from '@/components/appointments/ScheduleDoctors';
import ScheduleDays from '@/components/appointments/ScheduleDays';
import ScheduleTable from '@/components/appointments/ScheduleTable';
import ActionButtons from '@/components/utils/ActionButtons/ActionButtons';
import ExtraScheduleModal from '@/components/appointments/ExtraScheduleModal';

import AppointmentsFormModal from '@/components/appointments/AppointmentsFormModal';
import AppointmentsWaitModal from '@/components/appointments/AppointmentsWaitModal';
import AppointmentsAuthorizeModal from '@/components/appointments/AppointmentsAuthorizeModal';
import AppointmentsCancelModal from '@/components/appointments/AppointmentsCancelModal';
import AppointmentsRevertModal from '@/components/appointments/AppointmentsRevertModal';
import AppointmentsCallModal from '@/components/appointments/AppointmentsCallModal';
import AppointmentsPatientModal from '@/components/appointments/AppointmentsPatientModal';
import AppointmentsRecordModal from '@/components/appointments/AppointmentsRecordModal';
import AppointmentsListModal from '@/components/appointments/AppointmentsListModal';

import api from '@/mixins/api';
import helper from '@/mixins/helper';
import print from '@/mixins/print';

import axios from 'axios';

export default {
  components: {
    Modal,
    Spinner,
    Skeleton,
    ScheduleDate,
    ScheduleDoctors,
    ScheduleDays,
    ScheduleTable,
    ActionButtons,
    ExtraScheduleModal,

    AppointmentsFormModal,
    AppointmentsWaitModal,
    AppointmentsAuthorizeModal,
    AppointmentsCancelModal,
    AppointmentsRevertModal,
    AppointmentsCallModal,
    AppointmentsPatientModal,
    AppointmentsRecordModal,
    AppointmentsListModal,
  },

  mixins: [api, helper, print],

  data() {
    return {
      title: document.title,
      disableComponents: false,
      loading: {
        data: false,
        patients: false,
        appointment_types: false,
        doctors: false,
        schedule: false,
        visibleTabs: false,
        doctor: {
          appointments: false,
          schedule: {
            daily: false,
            extra: false,
            block: false,
          },
        },
      },
      wait: false,
      patients: [],
      appointment_types: [],
      doctors: [],
      doctor: {
        id: null,
        appointments: [],
        schedule: {
          daily: [],
          extra: [],
          block: [],
        },
      },
      date: null,
      isItemSelected: false,
      disableActions: false,
      visibleTabs: [],
      actions: [
        {
          name: 'patient',
          label: this.$t('actions.appointment.patient'),
          variant: 'warning',
          icon: ['fas', 'user-edit'],
          modal: 'patient',
          function: 'setPatient',
        },
        {
          name: 'new',
          label: this.$t('actions.appointment.new'),
          variant: 'success',
          icon: ['fas', 'plus'],
          modal: 'form',
          function: 'setPatient',
        },
        {
          name: 'change',
          label: this.$t('actions.appointment.change'),
          variant: 'info',
          icon: ['fas', 'pen'],
          modal: 'form',
        },
        {
          name: 'fit',
          label: this.$t('actions.appointment.fit'),
          variant: 'success',
          icon: ['far', 'plus-square'],
          modal: 'form',
          function: 'setFit',
        },
        {
          name: 'wait',
          label: this.$t('actions.appointment.wait'),
          variant: 'primary',
          icon: ['fas', 'user-clock'],
          function: 'setWait',
        },
        {
          name: 'authorize',
          label: this.$t('actions.appointment.authorize'),
          variant: 'success',
          icon: ['fas', 'check'],
          modal: 'authorize',
          function: 'setPatient',
        },
        {
          name: 'cancel',
          label: this.$t('actions.appointment.cancel'),
          variant: 'dark',
          icon: ['fas', 'times'],
          modal: 'cancel',
        },
        {
          name: 'revert',
          label: this.$t('actions.appointment.revert'),
          variant: 'dark',
          icon: ['fas', 'undo'],
          modal: 'revert',
        },
        {
          name: 'block',
          label: this.$t('actions.appointment.block'),
          variant: 'danger',
          icon: ['fas', 'ban'],
          function: 'setBlock',
        },
        {
          name: 'unlock',
          label: this.$t('actions.appointment.unlock'),
          variant: 'info',
          icon: ['fas', 'unlock-alt'],
          function: 'setUnlock',
        },
        {
          name: 'absence',
          label: this.$t('actions.appointment.absence'),
          variant: 'info',
          icon: ['fas', 'user-slash'],
          function: 'setAbsence',
        },
        {
          name: 'call',
          label: this.$t('actions.appointment.call'),
          variant: 'success',
          icon: ['fas', 'user-check'],
          modal: 'call',
        },
        {
          name: 'editPatient',
          label: this.$t('actions.appointment.editPatient'),
          variant: 'warning',
          icon: ['fas', 'user-edit'],
          modal: 'patient',
          function: 'setPatient',
        },
        {
          name: 'appointments',
          label: this.$t('actions.appointment.appointments'),
          variant: 'info',
          icon: ['far', 'calendar-alt'],
          modal: 'list',
          function: 'setPatient',
        },
        {
          name: 'viewRecord',
          label: this.$t('actions.appointment.viewRecord'),
          variant: 'primary',
          icon: ['far', 'clipboard'],
          modal: 'record',
          function: 'setPatient',
        },
        {
          name: 'openRecord',
          label: this.$t('actions.appointment.openRecord'),
          variant: 'success',
          icon: ['far', 'clipboard'],
          modal: 'record',
          function: 'setPatient',
        },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    refreshSchedule() {
      return this.$store.state.refreshSchedule;
    },

    formAction() {
      if (this.item.fit && !this.item.id) {
        return 'fit';
      }
      if (this.item.status && this.item.status !== 'canceled') {
        return 'change';
      }
      return 'new';
    },

    headerActions() {
      const headerActions = [
        {
          name: 'extraSchedule',
          label: this.$t('actions.appointment.extraSchedule'),
          variant: 'info',
          icon: ['far', 'calendar-alt'],
          modal: 'extra-schedule',
        },
      ];

      if (this.$mq === 'xl') {
        headerActions.push({
          name: 'print',
          label: this.$t('buttons.print'),
          variant: 'dark',
          icon: ['fas', 'print'],
          function: 'print',
          functionParam: 'schedule-days',
        });
      }

      return headerActions;
    },

    fields() {
      let fields = [
        {
          key: 'appointment_fit',
          label: '',
          class: this.$mq === 'xl' && 'text-right',
        },
        {
          key: 'time_scheduling',
          label: this.$t('fields.appointment.time_scheduling'),
          formatter: value => this.formatTime(value),
          class: this.$mq === 'xl' && 'text-center',
        },
        {
          key: 'patient_name',
          label: this.$t('fields.appointment.patient_name'),
          class: 'col-patient',
        },
        {
          key: 'patient_code',
          label: this.$t('fields.appointment.patient_id'),
        },
        {
          key: 'patient_phones',
          label: this.$t('fields.appointment.patient_phones'),
        },
        {
          key: 'appointment_category',
          label: this.$t('fields.appointment.category.category'),
          translate: true,
        },
        { key: 'type', label: this.$t('fields.appointment.type') },
        { key: 'cancel_reason', label: '', thClass: 'cancel-reason' },
      ];

      if (this.$mq !== 'xl') {
        fields = fields.filter(
          field =>
            field.key !== 'patient_code' &&
            field.key !== 'patient_phones' &&
            field.key !== 'appointment_category' &&
            field.key !== 'cancel_reason',
        );
      }

      return fields;
    },

    actionButtons() {
      let actionButtons = [];

      if (this.user.role === 'receptionist') {
        if (!this.item.date) {
          actionButtons = [
            { name: 'new', disabled: true },
            { name: 'change', disabled: true },
            { name: 'fit', disabled: true },
            { name: 'wait', divider: true, disabled: true },
            { name: 'authorize', disabled: true },
            { name: 'cancel', disabled: true },
            { name: 'block', disabled: true },
            { name: 'absence', divider: true, disabled: true },
          ];
        } else {
          switch (this.item._rowVariant) {
            case 'scheduled':
              actionButtons = [
                { name: 'patient' },
                { name: 'change' },
                { name: 'fit' },
                { name: 'wait', divider: true },
                { name: 'authorize' },
                { name: 'cancel' },
                { name: 'block', disabled: true },
                { name: 'absence', divider: true },
              ];
              break;
            case 'canceled':
              actionButtons = [
                { name: 'new' },
                { name: 'patient' },
                { name: 'fit', disabled: true },
                { name: 'wait', divider: true, disabled: true },
                { name: 'authorize', disabled: true },
                { name: 'cancel', disabled: true },
                { name: 'block', disabled: true },
                { name: 'absence', divider: true, disabled: true },
              ];
              break;
            case 'absence':
            case 'waiting':
            case 'attending':
            case 'concluded':
            case 'authorized':
              actionButtons = [
                { name: 'patient' },
                { name: 'change', disabled: true },
                { name: 'fit' },
                { name: 'wait', divider: true, disabled: true },
                { name: 'authorize', disabled: true },
                { name: 'revert' },
                { name: 'block', disabled: true },
                { name: 'absence', divider: true, disabled: true },
              ];
              break;
            case 'pending':
              actionButtons = [
                { name: 'patient' },
                { name: 'change', disabled: true },
                { name: 'fit' },
                { name: 'wait', divider: true, disabled: true },
                { name: 'authorize' },
                { name: 'revert' },
                { name: 'block', disabled: true },
                { name: 'absence', divider: true, disabled: true },
              ];
              break;
            case 'block':
              actionButtons = [
                { name: 'new', disabled: true },
                { name: 'change', disabled: true },
                { name: 'fit', disabled: true },
                { name: 'wait', divider: true, disabled: true },
                { name: 'authorize', disabled: true },
                { name: 'cancel', disabled: true },
                { name: 'unlock' },
                { name: 'absence', divider: true, disabled: true },
              ];
              break;
            default:
              actionButtons = [
                { name: 'new' },
                { name: 'change', disabled: true },
                { name: 'fit', disabled: true },
                { name: 'wait', divider: true, disabled: true },
                { name: 'authorize', disabled: true },
                { name: 'cancel', disabled: true },
                { name: 'block' },
                { name: 'absence', divider: true, disabled: true },
              ];
              break;
          }
        }
      } else if (this.user.role === 'doctor') {
        if (!this.item.date) {
          actionButtons = [
            { name: 'editPatient', disabled: true },
            { name: 'appointments', disabled: true },
            { name: 'viewRecord', disabled: true },
            { name: 'revert', divider: true, disabled: true },
            { name: 'block', disabled: true },
            { name: 'call', divider: true, disabled: true },
          ];
        } else {
          switch (this.item._rowVariant) {
            case 'scheduled':
            case 'canceled':
            case 'absence':
            case 'authorized':
              actionButtons = [
                { name: 'editPatient' },
                { name: 'appointments' },
                { name: 'viewRecord' },
                { name: 'revert', divider: true, disabled: true },
                { name: 'block', disabled: true },
                { name: 'call', divider: true, disabled: true },
              ];
              break;
            case 'concluded':
            case 'pending':
              actionButtons = [
                { name: 'editPatient' },
                { name: 'appointments' },
                { name: 'viewRecord' },
                { name: 'revert', divider: true },
                { name: 'block', disabled: true },
                { name: 'call', divider: true, disabled: true },
              ];
              break;
            case 'waiting':
              actionButtons = [
                { name: 'editPatient' },
                { name: 'appointments' },
                { name: 'viewRecord' },
                { name: 'revert', divider: true, disabled: true },
                { name: 'block', disabled: true },
                { name: 'call', divider: true },
              ];
              break;
            case 'attending':
              actionButtons = [
                { name: 'editPatient' },
                { name: 'appointments' },
                { name: 'openRecord' },
                { name: 'revert', divider: true },
                { name: 'block', disabled: true },
                { name: 'call', divider: true, disabled: true },
              ];
              break;
            case 'block':
              actionButtons = [
                { name: 'editPatient', disabled: true },
                { name: 'appointments', disabled: true },
                { name: 'viewRecord', disabled: true },
                { name: 'revert', divider: true, disabled: true },
                { name: 'unlock' },
                { name: 'call', divider: true, disabled: true },
              ];
              break;
            default:
              actionButtons = [
                { name: 'editPatient', disabled: true },
                { name: 'appointments', disabled: true },
                { name: 'viewRecord', disabled: true },
                { name: 'revert', divider: true, disabled: true },
                { name: 'block' },
                { name: 'call', divider: true, disabled: true },
              ];
              break;
          }
        }
      }

      actionButtons.forEach((button, index) => {
        this.actions.forEach(action => {
          if (button.name === action.name) {
            actionButtons[index] = { ...button, ...action };
          }
        });
      });

      return actionButtons;
    },
  },

  methods: {
    async init() {
      document.title = `${this.appName} - ${this.$t('appointments.schedules')}`;

      this.$store.commit('mutate', { prop: 'closeDialog', with: true });

      await this.loadData();

      if (this.user.role === 'doctor') {
        this.doctor.id = this.user.id;
        await this.updateSchedule();
      }
    },

    reset() {
      document.title = this.title;

      this.clearSelected();

      this.doctor = {
        id: null,
        appointments: [],
        schedule: {
          daily: [],
          extra: [],
          block: [],
        },
      };
      this.visibleTabs = [];

      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    async loadData() {
      this.loading.data = true;
      this.disableComponents = true;

      await Promise.all([
        this.loadItem('doctors'),
        this.loadItem('patients'),
        this.loadItem('appointment_types'),
      ]);

      this.loading.data =
        this.loading.doctors ||
        this.loading.patients ||
        this.loading.appointment_types;
      this.disableComponents = false;
    },

    async loadItem(entity) {
      this.loading[entity] = true;

      await axios
        .get(`${this.baseApiUrl}/${entity}/${this.customer.id}`)
        .then(res => {
          this[entity] = res.data || [];
        })
        .catch(this.showError);

      this.loading[entity] = false;
    },

    async onDateChanged(date) {
      this.date = date;

      if (this.doctor.id) {
        await this.updateSchedule();
        this.clearSelected();
      }
    },

    async onDoctorChanged(doctor_id) {
      this.doctor.id = doctor_id;

      await this.updateSchedule();
      this.clearSelected();
    },

    async updateSchedule() {
      this.loading.schedule = true;
      this.disableComponents = true;

      await Promise.all([
        this.loadAppointments(),
        this.loadSchedule('daily'),
        this.loadSchedule('extra'),
        this.loadSchedule('block'),
        this.loadVisibleTabs(),
      ]);

      this.loading.schedule =
        this.loading.doctor.schedule.daily ||
        this.loading.doctor.schedule.extra ||
        this.loading.doctor.schedule.block ||
        this.loading.doctor.appointments ||
        this.loading.visibleTabs;
      this.disableComponents = false;
    },

    async loadAppointments() {
      this.loading.doctor.appointments = true;

      const doctor_id = this.doctor.id;
      const date = this.formatDate(this.date, 'DD/MM/YYYY', 'YYYY-MM-DD');

      await axios
        .get(`${this.baseApiUrl}/appointments/${doctor_id}/${date}`)
        .then(res => {
          const appointments = res.data || [];

          appointments.forEach(appointment => {
            this.fields.forEach(field => {
              if (field.translate) {
                appointment[field.key] = this.$t(
                  `fields.appointment.${field.key}.${appointment[field.key]}`,
                );
              }
            });

            appointment.appointment_fit = appointment.fit
              ? this.$t('fields.appointment.fit')
              : '';

            appointment._rowVariant = appointment.status;
          });

          this.doctor.appointments = appointments;

          this.loading.doctor.appointments = false;
        })
        .catch(this.showError);
    },

    async loadSchedule(type) {
      this.loading.doctor.schedule[type] = true;

      const doctor_id = this.doctor.id;
      const date = this.formatDate(this.date, 'DD/MM/YYYY', 'YYYY-MM-DD');

      let url = `${this.baseApiUrl}/schedules/${type}/${doctor_id}`;
      if (type !== 'daily') url += `/${date}`;

      await axios
        .get(url)
        .then(res => {
          this.doctor.schedule[type] = res.data || [];
          this.loading.doctor.schedule[type] = false;
        })
        .catch(this.showError);
    },

    async loadVisibleTabs() {
      if (this.date) {
        const doctor_id =
          this.user.role === 'doctor' ? this.user.id : this.doctor.id;
        const date = this.formatDate(this.date, 'DD/MM/YYYY', 'YYYY-MM-DD');

        await axios
          .get(`${this.baseApiUrl}/schedule_visibleDays/${doctor_id}/${date}`)
          .then(res => {
            const days = res.data[0];

            this.visibleTabs = days.map(day => day.day);
          })
          .catch(this.showError);
      }
    },

    updateItemSelected(value) {
      this.isItemSelected = value;
      this.wait = false;
      this.disableActions = false;
    },

    async onSubmitForm() {
      await this.updateSchedule();
      this.clearSelected();
    },

    clearSelected() {
      this.isItemSelected = false;
      this.wait = false;
      this.$store.commit('mutate', { prop: 'item', with: {} });
      this.$store.commit('mutate', { prop: 'patient', with: {} });
    },

    setFit() {
      const appointment = {
        customer_id: this.item.customer_id,
        user_id: this.item.user_id,
        doctor_id: this.item.doctor_id,
        date: this.item.date,
        time_scheduling: this.item.time_scheduling,
        status: 'scheduled',
        _rowVariant: this.item._rowVariant,
        fit: true,
      };

      this.$store.commit('mutate', { prop: 'item', with: { ...appointment } });
    },

    async setWait() {
      let patient = {};
      const customer_id = this.customer.id;
      const patient_name = this.item.patient_name;

      await axios
        .get(`${this.baseApiUrl}/patients/${customer_id}/${patient_name}`)
        .then(res => {
          patient = res.data || {};
        })
        .catch(this.showError);

      if (
        !patient.name ||
        !patient.cpf_cnpj ||
        !patient.birth_date ||
        !patient.gender
      ) {
        this.wait = true;
        this.$bvModal.show('appointments-wait-modal');
      } else {
        await this.setWaiting();
      }
    },

    async setWaiting() {
      this.loading.schedule = true;
      this.disableComponents = true;

      let time_arrival = new Date();
      time_arrival = this.formatTime(time_arrival);

      const appointment = {
        ...this.item,
        status: 'waiting',
        time_arrival,
        time_treatment: null,
        time_end: null,
        call_user_id: this.user.id,
        phone_mobile: this.item.patient_phone_mobile,
        phone_home: this.item.patient_phone_home,
        phone_work: this.item.patient_phone_work,
        phone_other: this.item.patient_phone_other,
      };

      await this.sendMessage();

      await axios
        .put(`${this.baseApiUrl}/appointments/${appointment.id}`, appointment)
        .then(async () => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.appointment.wait', {
              title: appointment.patient_name,
            }),
          });
          await this.updateSchedule();
          this.clearSelected();
        })
        .catch(this.showError);

      this.loading.schedule = false;
      this.disableComponents = false;
    },

    async sendMessage() {
      const message = {
        sender_id: this.user.id,
        recipient_id: this.doctor.id,
        type: 'wait',
        content: this.$t('messages.appointment.wait', {
          title: this.item.patient_name,
        }),
      };

      await axios
        .post(`${this.baseApiUrl}/messages`, message)
        .catch(this.showError);
    },

    async setBlock() {
      this.loading.schedule = true;
      this.disableComponents = true;

      const schedule = {
        ...this.item,
        type: 'block',
      };

      await axios
        .post(`${this.baseApiUrl}/schedules`, schedule)
        .then(async () => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.appointment.blocked'),
          });
          await this.updateSchedule();
          this.clearSelected();
        })
        .catch(this.showError);

      this.loading.schedule = false;
      this.disableComponents = false;
    },

    async setUnlock() {
      this.loading.schedule = true;
      this.disableComponents = true;

      await axios
        .delete(`${this.baseApiUrl}/schedules/${this.item.id}`)
        .then(async () => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.appointment.unlocked'),
          });
          await this.updateSchedule();
          this.clearSelected();
        })
        .catch(this.showError);

      this.loading.schedule = false;
      this.disableComponents = false;
    },

    async setAbsence() {
      this.loading.schedule = true;
      this.disableComponents = true;

      const appointment = {
        ...this.item,
        status: 'absence',
        time_arrival: null,
        time_treatment: null,
        time_end: null,
        phone_mobile: this.item.patient_phone_mobile,
        phone_home: this.item.patient_phone_home,
        phone_work: this.item.patient_phone_work,
        phone_other: this.item.patient_phone_other,
      };

      await axios
        .put(`${this.baseApiUrl}/appointments/${appointment.id}`, appointment)
        .then(async () => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.appointment.absence', {
              title: appointment.patient_name,
            }),
          });
          await this.updateSchedule();
          this.clearSelected();
        })
        .catch(this.showError);

      this.loading.schedule = false;
      this.disableComponents = false;
    },

    setPatient() {
      const customer_id = this.customer.id;
      const patient_name = this.item.patient_name;

      axios
        .get(`${this.baseApiUrl}/patients/${customer_id}/${patient_name}`)
        .then(res => {
          this.patient = res.data || {};

          this.$store.commit('mutate', {
            prop: 'patient',
            with: { ...this.patient },
          });
        })
        .catch(this.showError);
    },
  },

  watch: {
    async refreshSchedule(value) {
      if (value) {
        await this.updateSchedule();
        this.disableActions = true;
        this.$store.commit('mutate', { prop: 'refreshSchedule', with: false });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#appointment-schedules-modal {
  .schedule-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;

    section {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      .action-button:first-child {
        margin-right: 20px;
      }
    }

    &.xs,
    &.sm {
      section {
        display: grid;
        row-gap: 15px;

        .action-button:first-child {
          margin-top: -2px;
          margin-right: 0;
        }
      }
    }
  }
}
</style>
