<template>
  <b-form-group
    class="finances-items"
    :class="groupClass"
    :label="$t(`finances.revenues.${entity}`)"
  >
    <b-form-checkbox
      v-for="option in options"
      :key="option.value"
      :id="`checkbox-${entity}-${option.value}`"
      :name="`checkbox-${entity}-${option.value}`"
      v-model="option.checked"
      plain
      :disabled="disabled"
      @change="setSelected"
    >
      <label :for="`checkbox-${entity}-${option.value}`">
        {{ option.text }}
      </label>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
export default {
  props: {
    entity: String,
    items: Array,
    displayField: String,
    groupClass: String,
    disabled: Boolean,
  },

  data() {
    return {
      options: [],
      selected: [],
    };
  },

  methods: {
    setSelected() {
      this.selected = this.options
        .filter(option => option.checked)
        .map(option => option.value)
        .join(', ');

      this.$emit('setSelected', this.entity, this.selected);
    },
  },

  watch: {
    items(array) {
      this.options = [{}];

      array.forEach((item, index) => {
        this.options[index] = {
          value: item.id,
          text: item[this.displayField],
          checked: false,
        };
      });

      this.setSelected();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.finances-items {
  min-height: 92px;
  max-height: 92px;
  padding: 0 15px 8px;
  background-color: $gray-100;
  border: 1px solid $gray-200;
  border-radius: $border-radius;
  overflow-y: scroll;

  &.grid > div {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 15px;
  }

  legend {
    width: auto;
    margin-bottom: 5px;
    padding: 0 5px;
    font-size: 12px !important;
  }

  .form-check {
    font-size: 12px;

    label label {
      margin: 1px 0;
    }
  }
}
</style>
