<template>
  <div id="view-card" class="view-card" :class="$mq">
    <b-card :title="title" :sub-title="subTitle">
      <!--eslint-disable vue/no-use-v-if-with-v-for -->
      <b-card-text
        v-for="group in entity.fields.view"
        :key="group.groupName"
        v-if="groupFields[group.groupName]"
      >
        <caption
          v-if="
            Object.keys(groupFields).length > 1 && groupFields[group.groupName]
          "
        >
          {{
            group.groupName
          }}
        </caption>
        <ul :class="group.type">
          <li
            v-for="field in group.fields"
            :key="field.key"
            v-if="record[field.key]"
          >
            <span>
              <label
                v-if="groupFields[group.groupName].length > 1 && field.label"
                :for="field.key"
              >
                {{ field.label }}</label
              >
              <div v-if="field.type === 'file'">
                <b-link :href="record[field.key]" target="_blank">
                  <fa-icon :icon="['far', 'file-pdf']" />
                </b-link>
              </div>
              <span v-else>{{ record[field.key] }}</span></span
            >
            <br v-if="field.breakLine" />
          </li>
        </ul>
      </b-card-text>
      <b-card-text v-if="entity.name.singular === 'customer'">
        <div
          class="customer-logo"
          :style="`background-color: ${record.background_color}`"
        >
          <img :src="record.logo" alt="Logo" />
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import '@/config/font-awesome';

import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  props: {
    entity: Object,
  },

  data() {
    return {
      record: {},
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    item() {
      return this.$store.state.item;
    },

    title() {
      return this.getField(this.entity.mainField);
    },

    subTitle() {
      return this.entity.secondaryField
        ? this.getField(this.entity.secondaryField)
        : null;
    },

    groupFields() {
      const groupFields = [];

      this.entity.fields.view.forEach(group => {
        const fields = group.fields.filter(field => this.item[field.key]);
        if (fields.length > 0) {
          groupFields[group.groupName] = fields;
        }
      });

      return groupFields;
    },
  },

  mounted() {
    this.setRecord();
  },

  methods: {
    getField(field) {
      if (field.key) {
        if (field.formatter) {
          return this[field.formatter](this.record[field.key]);
        }

        return this.record[field.key];
      }

      return this.record[field];
    },

    setRecord() {
      const record = { ...this.item };

      this.entity.fields.view.forEach(group => {
        group.fields.forEach(field => {
          if (record[field.key]) {
            if (field.translate) {
              record[field.key] = this.$t(
                `fields.${field.key}.${record[field.key]}`,
              );
            }

            if (field.formatter) {
              record[field.key] = this[field.formatter](record[field.key]);
            }
          }
        });
      });

      this.record = record;
    },
  },

  watch: {
    item() {
      this.setRecord();
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.view-card {
  .card-body {
    padding: 10px;
  }

  .card-title {
    display: inline-block;
    margin: 0 15px 15px 0;
    font-size: 16px;
  }

  .card-subtitle {
    display: inline-block;
    padding-bottom: 5px;
    font-size: 12px;
    color: $gray-700 !important;
  }

  .card-text {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-size: 13px;
  }

  caption {
    padding: 0 5px 0 0;
    font-weight: bold;
    color: $body-color;
  }

  ul {
    margin: 0;
    padding: 0 0 10px;

    li {
      display: inline;
      list-style: none;
    }

    &.list {
      li {
        padding-right: 5px;
      }

      label {
        font-weight: bold;
        color: $gray-700;

        &:after {
          content: ':';
          padding-right: 3px;
        }
      }
    }

    &.concat {
      li::after {
        content: ', ';
      }

      li:last-child::after {
        content: '';
      }
    }
  }

  .customer-logo {
    padding: 10px;

    img {
      height: 120px;
    }
  }

  &.xs {
    .card-title {
      width: 100%;
    }
  }
}
</style>
