<template>
  <Modal>
    <b-modal
      id="appointments-extra-schedule-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('appointments.extraSchedule') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <b-form>
        <b-form-group v-for="group in fields" :key="group.groupName">
          <div
            class="form-fields"
            :class="group.type"
            :style="`grid-template-columns: ${group.gridTemplateColumns}`"
          >
            <FormField
              v-for="field in group.fields"
              :key="field.key"
              entity-name="schedule"
              entity-state="schedule"
              :field="field"
              :disabled="field.disabled || disableComponents"
            />
          </div>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="disableComponents"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="info" @click="ok()" :disabled="disableComponents">
            <Spinner v-if="savingSchedule" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, helper],

  props: {
    doctors: Array,
    doctorId: Number,
  },

  data() {
    return {
      doctorsList: [],
      disableComponents: false,
      savingSchedule: false,
    };
  },

  computed: {
    schedule() {
      return this.$store.state.schedule;
    },

    fields() {
      return [
        {
          fields: [
            {
              key: 'doctor_id',
              type: 'select',
              options: this.getOptions('doctors', 'username'),
              disabled: true,
            },
          ],
        },
        {
          type: 'grid',
          gridTemplateColumns: 'auto auto',
          fields: [
            {
              key: 'date',
              type: 'datepicker',
            },
            {
              key: 'time_scheduling',
              type: 'timepicker',
            },
          ],
        },
      ];
    },
  },

  methods: {
    init() {
      const schedule = {
        ...this.schedule,
        doctor_id: this.doctorId,
        type: 'extra',
      };
      this.$store.commit('mutate', { prop: 'schedule', with: { ...schedule } });
    },

    reset() {
      this.$store.commit('mutate', { prop: 'schedule', with: {} });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.saveSchedule();
    },

    saveSchedule() {
      this.savingSchedule = true;
      this.disableComponents = true;

      axios
        .post(`${this.baseApiUrl}/schedules`, this.schedule)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.appointment.extraScheduleSaved'),
          });

          this.$emit('submit');
          this.$bvModal.hide('appointments-extra-schedule-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.savingSchedule = false;
          this.disableComponents = false;
        });
    },
  },
};
</script>
