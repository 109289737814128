<template>
  <Modal>
    <b-modal
      id="messages-send-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('message.newMessage') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="sendingMessage"
          @click="close()"
        >
          ×
        </button>
      </template>
      <b-form>
        <input id="message-id" type="hidden" v-model="message.id" />
        <FormField
          v-for="field in fields"
          :key="field.key"
          entity-name="message"
          entity-state="message"
          :field="field"
          :disabled="field.disabled || sendingMessage"
        />
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="sendingMessage"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="primary" @click="ok()" :disabled="sendingMessage">
            <Spinner v-if="sendingMessage" size="small" />
            <span v-else>
              {{ $t('buttons.send') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, helper],

  props: {
    users: Array,
  },

  data() {
    return {
      sendingMessage: false,
      messageData: {},
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    message() {
      return this.$store.state.message;
    },

    fields() {
      return [
        {
          key: 'recipient_id',
          type: 'select',
          options: this.getOptions('users', 'username'),
        },
        {
          key: 'content',
          type: 'textarea',
          rows: 10,
        },
      ];
    },
  },

  methods: {
    init() {
      this.messageData = { ...this.message };
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });
    },

    reset() {
      this.$store.commit('mutate', {
        prop: 'message',
        with: { ...this.messageData },
      });
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.sendMessage();
    },

    sendMessage() {
      this.sendingMessage = true;

      const message = {
        ...this.message,
        type: 'message',
        sender_id: this.user.id,
      };

      axios
        .post(`${this.baseApiUrl}/messages`, message)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.message.sent'),
          });

          this.$emit('submit');

          this.$bvModal.hide('messages-send-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.sendingMessage = false;
        });
    },
  },
};
</script>
