<template>
  <div class="appointments-list">
    <Skeleton
      v-if="loadingAppointments"
      :title="true"
      :buttons="0"
      :rows="9"
      :columns="5"
      :small="true"
    />
    <div v-else>
      <span class="total">
        <caption>
          {{
            $t('appointments.total')
          }}:
        </caption>
        {{ appointments.length }}
      </span>
      <b-table
        small
        sticky-header
        striped
        borderless
        outlined
        hover
        no-border-collapse
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
      </b-table>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/utils/Skeleton';

import helper from '@/mixins/helper';

export default {
  components: { Skeleton },

  mixins: [helper],

  props: {
    appointments: Array,
    loadingAppointments: Boolean,
  },

  data() {
    return {
      fields: [
        { key: 'fit', label: '', class: 'text-right' },
        {
          key: 'date',
          label: this.$t('fields.appointment.date'),
          formatter: value => this.formatDate(value),
          class: 'text-center',
        },
        {
          key: 'time_scheduling',
          label: this.$t('fields.appointment.time_scheduling'),
          formatter: value => this.formatTime(value),
        },
        { key: 'doctor', label: this.$t('fields.appointment.doctor') },
        {
          key: 'category',
          label: this.$t('fields.appointment.category.category'),
          translate: true,
        },
        { key: 'type', label: this.$t('fields.appointment.type') },
        { key: 'cancel_reason', label: '' },
      ],
      sortBy: 'date',
      sortDesc: true,
    };
  },

  computed: {
    items() {
      const items = this.appointments;

      items.forEach(appointment => {
        this.fields.forEach(field => {
          if (field.translate) {
            appointment[field.key] = this.$t(
              `fields.appointment.${field.key}.${appointment[field.key]}`,
            );
          }
        });

        appointment.fit = appointment.fit
          ? this.$t('fields.appointment.fit')
          : '';

        appointment._rowVariant = appointment.status;
      });

      return items;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.appointments-list {
  width: 500px;
  margin-right: 20px;

  .total {
    display: block;
    margin: 0 0 8px;
    font-size: 12px;

    caption {
      display: inline;
      font-weight: bold;
      color: $gray-700;
    }
  }

  .b-table-sticky-header {
    max-height: 430px;
    margin-bottom: 0;
  }

  table {
    th {
      font-size: 13px;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .table.b-table > thead > tr > .table-b-table-default {
    color: $white;
    background-color: $primary;
  }

  .table-waiting {
    font-weight: bold;
    color: $orange;
  }

  .table-attending {
    font-weight: bold;
    color: $blue;
  }

  .table-pending {
    font-weight: bold;
    color: $purple;
  }

  .table-concluded {
    color: $dark-green;
  }

  .table-authorized {
    font-weight: bold;
    color: $dark-green;
  }

  .table-canceled {
    font-weight: bold;
    color: $gray-500;
  }

  .table-absence {
    font-weight: bold;
    text-decoration: line-through;
  }
}
</style>
