import '@/config/html-to-paper';

export default {
  data() {
    return {
      output: null,
    };
  },

  methods: {
    async print(id) {
      await this.$htmlToPaper(id, {
        windowTitle: document.title,
      });
    },
  },
};
