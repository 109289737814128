<template>
  <Modal>
    <b-modal
      id="appointments-authorize-modal"
      size="lg"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('appointments.authorize') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <Skeleton
        v-if="loadingData"
        :title="false"
        :buttons="0"
        :rows="6"
        :columns="3"
        :small="true"
      />
      <b-form v-else>
        <input id="appointment-id" type="hidden" v-model="item.id" />
        <div class="fields" :class="$mq">
          <div>
            <span>{{ $t('users.doctor') }}</span>
            {{ item.doctor_name }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.date') }}</span>
            {{ date }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.time_scheduling') }}</span>
            {{ time_scheduling }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.patient_name') }}</span>
            {{ item.patient_name }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.patient_id') }}</span>
            {{ item.patient_id }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.category.category') }}</span>
            {{ category }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.patient_phones') }}</span>
            {{ item.patient_phones }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.type_id') }}</span>
            {{ item.type }}
          </div>
          <div>
            <span>{{ $t('fields.appointment.default_price') }}</span>
            {{ default_price }}
          </div>
          <b-form-group class="cpf-cnpj">
            <legend>{{ $t('fields.patient.cpf_cnpj') }}</legend>
            <FormField
              entity-name="appointment"
              entity-state="item"
              :field="fields.cpf_cnpj"
              :mask="fields.cpf_cnpj.mask"
              :disabled="fields.cpf_cnpj.disabled || disableComponents"
            />
          </b-form-group>
        </div>
        <hr />
        <b-form-group>
          <legend>{{ $t('fields.appointment.price') }}</legend>
          <FormField
            entity-name="appointment"
            entity-state="item"
            :field="fields.price"
            :disabled="fields.price.disabled || disableComponents"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="disableComponents"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="info" @click="ok()" :disabled="disableComponents">
            <Spinner v-if="authorizingAppointment" size="small" />
            <span v-else>
              {{ $t('buttons.confirm') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';
import Skeleton from '@/components/utils/Skeleton';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner, Skeleton },

  mixins: [api, helper],

  data() {
    return {
      loadingData: false,
      disableComponents: false,
      authorizingAppointment: false,
      fields: {
        cpf_cnpj: {
          key: 'cpf_cnpj',
          label: '',
          mask: 'cpf_cnpj',
          placeholder: '____.____.____-___',
          disabled: false,
        },
        price: {
          key: 'price',
          label: '',
          type: 'money',
          disabled: false,
        },
      },
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    patient() {
      return this.$store.state.patient;
    },

    date() {
      return this.formatDate(this.item.date);
    },

    time_scheduling() {
      return this.formatTime(this.item.time_scheduling);
    },

    category() {
      return (
        this.item.category &&
        this.$t(`fields.appointment.category.${this.item.category}`)
      );
    },

    default_price() {
      return this.formatPrice(this.item.default_price);
    },
  },

  methods: {
    async init() {
      this.loadingData = true;
      this.disableComponents = true;

      if (this.item.category === 'unpaid') {
        this.item.price = 0;
        this.fields.price.disabled = true;
      }
    },

    reset() {
      this.fields.price.disabled = false;
    },

    async confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.authorizingAppointment = true;
      this.disableComponents = true;

      await this.authorizeAppointment();

      this.authorizingAppointment = false;
      this.disableComponents = false;
    },

    async authorizeAppointment() {
      const patient = {
        ...this.patient,
        cpf_cnpj: this.item.cpf_cnpj,
      };

      const appointment = {
        ...this.item,
        status: 'authorized',
        time_arrival: null,
        time_treatment: null,
        time_end: null,
      };

      await axios
        .put(`${this.baseApiUrl}/patients_merge/${patient.id}`, patient)
        .then(async () => {
          await axios
            .put(
              `${this.baseApiUrl}/appointments/${appointment.id}`,
              appointment,
            )
            .then(() => {
              this.$toasted.global.defaultSuccess({
                msg: this.$t('messages.appointment.confirmed', {
                  operation: this.$t('messages.appointment.authorized'),
                }),
              });

              this.$emit('submit');
              this.$bvModal.hide('appointments-authorize-modal');
            });
        })
        .catch(this.showError);
    },
  },

  watch: {
    patient(obj) {
      const cpf_cnpj = obj.cpf_cnpj;
      const item = { ...this.item, cpf_cnpj };

      this.$store.commit('mutate', { prop: 'item', with: { ...item } });

      this.loadingData = false;
      this.disableComponents = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#appointments-authorize-modal {
  .fields {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 20px;

    div {
      padding: 0 0 15px;
      font-size: 13px;
      color: $gray-700;

      span {
        font-weight: bold;

        &::after {
          content: ':';
        }
      }
    }

    &.xs,
    &.sm {
      grid-template-columns: auto;
    }
  }

  fieldset {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &.cpf-cnpj {
      justify-content: start;

      .form-group {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      div {
        padding: 0;
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      legend {
        width: unset;
        padding-right: 5px;
      }
    }
  }
}
</style>
