<template>
  <Modal>
    <b-modal
      id="signout-modal"
      centered
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      @ok="confirm"
    >
      <p class="msg">{{ $t('confirmations.user.signout') }}</p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()">
            {{ $t('buttons.no') }}
          </b-button>
          <b-button variant="info" @click="ok()">
            {{ $t('buttons.yes') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';

import auth from '@/mixins/auth';

import { mapState } from 'vuex';

export default {
  components: { Modal },

  mixins: [auth],

  computed: mapState(['goNext']),

  methods: {
    async confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$store.commit('mutate', { prop: 'goNext', with: true });
      this.$router.push({ path: '/auth' });
    },
  },
};
</script>
