<template>
  <Modal>
    <b-modal
      id="appointments-cancel-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @ok="confirm"
    >
      <input id="appointment-id" type="hidden" v-model="item.id" />
      <p
        class="msg"
        v-html="
          $t('confirmations.appointment.cancel', {
            title: item.patient_name,
          })
        "
      />
      <FormField
        entity-name="appointment"
        entity-state="item"
        :field="field"
        :disabled="disableComponents"
      />
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="cancelingAppointment"
          >
            {{ $t('buttons.no') }}
          </b-button>
          <b-button
            variant="danger"
            @click="ok()"
            :disabled="cancelingAppointment"
          >
            <Spinner v-if="cancelingAppointment" size="small" />
            <span v-else>
              {{ $t('buttons.yes') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, helper],

  data() {
    return {
      disableComponents: false,
      cancelingAppointment: false,
      field: {
        key: 'cancel_reason',
        state: true,
        type: 'textarea',
        formatter: 'removeExtraSpaces',
      },
    };
  },

  computed: mapState(['customer', 'user', 'item']),

  methods: {
    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.cancelAppointment();
    },

    cancelAppointment() {
      this.cancelingAppointment = true;
      this.disableComponents = true;

      const id = this.item.id;
      const cancel_user_id = this.user.id;
      const cancel_date_time = this.formatDateTime(
        new Date(),
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DD HH:mm:ss',
      );

      const appointment = {
        ...this.item,
        status: 'canceled',
        time_arrival: null,
        time_treatment: null,
        time_end: null,
        cancel_user_id,
        cancel_date_time,
      };

      axios
        .put(`${this.baseApiUrl}/appointments/${id}`, appointment)
        .then(() => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.appointment.confirmed', {
              operation: this.$t('messages.appointment.canceled'),
            }),
          });

          this.$emit('submit');
          this.$bvModal.hide('appointments-cancel-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.cancelingAppointment = false;
          this.disableComponents = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#appointments-cancel-modal {
  .msg {
    padding-bottom: 30px;
  }
}
</style>
