<template>
  <div class="mobile-menu">
    <div class="menu-items">
      <b-nav-item-dropdown class="menu-item" no-caret dropup>
        <template #button-content>
          <fa-icon :icon="['fas', 'table']" />
          {{ $t('menu.items.items') }}
        </template>
        <b-dropdown-item v-if="user.role === 'admin'" v-b-modal.customers-modal>
          {{ $t('menu.items.customers') }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.users-modal :disabled="!isCustomerSelected">
          {{ $t('menu.items.users') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-b-modal.appointment_types-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.items.appointment_types') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-b-modal.contacts-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.items.contacts') }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown class="menu-item" no-caret dropup>
        <template #button-content>
          <fa-icon :icon="['far', 'calendar-alt']" />
          {{ $t('menu.appointments.appointments') }}
        </template>
        <b-dropdown-item
          v-b-modal.appointment-schedules-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.appointments.schedules') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-b-modal.messages-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.appointments.messages') }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown class="menu-item" no-caret dropup>
        <template #button-content>
          <fa-icon :icon="['fas', 'dollar-sign']" />
          {{ $t('menu.finances.finances') }}
        </template>
        <b-dropdown-item
          v-b-modal.finances-revenues-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.finances.revenues') }}
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          v-b-modal.finances-bills-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.finances.bills') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-b-modal.bill_expenses-modal
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.finances.bill_expenses') }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown class="menu-item" no-caret dropup>
        <template #button-content>
          <fa-icon :icon="['fas', 'question-circle']" />
          {{ $t('menu.help.help') }}
        </template>
        <b-dropdown-item
          :href="customer.manual"
          target="_blank"
          :disabled="!isCustomerSelected"
        >
          {{ $t('menu.help.manual') }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.support-modal>
          {{ $t('menu.help.support') }}
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.about-modal>
          {{ $t('menu.help.about') }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </div>

    <ItemModal :entity="customer_model" />
    <ItemModal :entity="user_model" />
    <ItemModal :entity="appointment_type_model" />
    <ItemModal :entity="contact_model" />

    <AppointmentSchedulesModal />
    <MessagesModal />

    <FinancesRevenuesModal />
    <FinancesBillsModal />
    <ItemModal :entity="bill_expense_model" />

    <SupportModal />
    <AboutModal />
  </div>
</template>

<script>
import ItemModal from '@/components/items/ItemModal';

import AppointmentSchedulesModal from '@/components/appointments/AppointmentSchedulesModal';
import MessagesModal from '@/components/messages/MessagesModal';

import FinancesRevenuesModal from '@/components/finances/Revenues/FinancesRevenuesModal';
import FinancesBillsModal from '@/components/finances/Bills/FinancesBillsModal';

import SupportModal from '@/components/help/SupportModal';
import AboutModal from '@/components/help/AboutModal';

import customer_model from '@/models/customer';
import user_model from '@/models/user';
import appointment_type_model from '@/models/appointment_type';
import contact_model from '@/models/contact';

import bill_expense_model from '@/models/bill_expense';

export default {
  components: {
    ItemModal,

    AppointmentSchedulesModal,
    MessagesModal,

    FinancesRevenuesModal,
    FinancesBillsModal,

    SupportModal,
    AboutModal,
  },

  mixins: [
    customer_model,
    user_model,
    appointment_type_model,
    contact_model,
    bill_expense_model,
  ],

  computed: {
    user() {
      return this.$store.state.user;
    },

    customer() {
      return this.$store.state.customer;
    },

    isCustomerSelected() {
      return this.customer.id;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.mobile-menu {
  .menu-items {
    display: grid;
    grid-template-columns: auto auto auto auto auto;

    min-width: 280px;
    background-color: $bg-color;

    .menu-item {
      list-style: none;

      > a {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;

        width: 25vw;
        padding: 3vw 0;
        font-size: 3.8vw;
        color: inherit;
        outline: none;
        background-color: $bg-color;

        &:hover {
          cursor: pointer;
          background-color: darken($bg-color, 10%);
        }

        svg {
          padding-bottom: 5px;
          font-size: 10vw;
        }
      }
    }
  }
}
</style>
