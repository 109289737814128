<template>
  <Auth>
    <b-form @submit.prevent="signIn()">
      <b-form-group>
        <b-form-input
          type="text"
          name="email"
          v-model="user.email"
          :placeholder="$t('fields.user.email')"
          :disabled="authenticating"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="password"
          name="password"
          v-model="user.password"
          :placeholder="$t('fields.user.password')"
          :disabled="authenticating"
          autocomplete="new-password"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-button type="submit" variant="info" block :disabled="authenticating">
        <Spinner v-if="authenticating" size="small" />
        <span v-else>
          {{ $t('user.signIn') }}
        </span>
      </b-button>
      <router-link to="auth_forgot" :class="{ disabled: authenticating }">
        <span>{{ $t('user.forgotPassword') }}</span>
      </router-link>
    </b-form>
  </Auth>
</template>

<script>
import Auth from '@/components/auth/Auth';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import auth from '@/mixins/auth';

import axios from 'axios';

export default {
  components: { Auth, Spinner },

  mixins: [api, auth],

  data() {
    return {
      user: {},
      authenticating: false,
    };
  },

  methods: {
    signIn() {
      this.authenticating = true;

      axios
        .post(`${this.baseApiUrl}/signIn`, this.user)
        .then(res => {
          const user = res.data;

          this.setUser(user);

          this.$toasted.global.defaultSuccess({
            msg: `${this.$t('messages.user.hello')}, ${user.username}!`,
          });

          this.$router.push({ path: '/' });
        })
        .catch(this.showError)
        .finally(() => {
          this.authenticating = false;
        });
    },
  },
};
</script>
