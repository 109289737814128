<template>
  <b-modal
    id="patients-sidebar"
    shadow
    backdrop
    no-close-on-backdrop
    no-close-on-esc
    no-fade
    hide-footer
    @show="init"
    @hidden="reset"
  >
    <template #modal-header="{ close }">
      <button type="button" class="close" @click="close">×</button>
    </template>
    <div class="patients-search-input" :class="$mq">
      <fa-icon :icon="['fas', 'search']" />
      <b-form-input
        type="search"
        :placeholder="$t('patients.search')"
        v-model="searchString"
        autofocus
        autocomplete="off"
        autocorrect="off"
        autocapitalize="none"
      />
    </div>
    <Spinner v-if="isSearching" variant="primary" size="medium" />
    <div v-else-if="searchString.trim()">
      <p v-if="!patients.length" class="no-result">
        {{ $t('messages.emptyFilteredText') }}
      </p>
      <ul v-else class="patients-list">
        <li v-for="patient in patients" :key="patient.id">
          <b-card
            class="patient-card"
            bg-variant="secondary"
            hide-footer
            v-b-modal.patient-view-modal
            @click="setPatient(patient)"
          >
            <template #header>
              <h1>
                {{ patient.name }}
              </h1>
            </template>
            <b-card-text>
              <p class="patient-birth-date">
                {{ formatDate(patient.birth_date) }}
              </p>
              <div class="patient-data">
                <p class="patient-id">
                  {{ patient.id }}
                </p>
                <p class="patient-category">
                  {{ $t(`fields.patient.category.${patient.category}`) }}
                </p>
              </div>
              <b-alert
                :show="patient.revision_days && patient.revision_days <= 30"
              >
                <p>
                  {{
                    $t('messages.appointment.revisionSearch', {
                      doctorName: patient.last_consultation_doctor,
                    })
                  }}
                </p>
                <p>
                  {{
                    $t('messages.appointment.lastConsultation', {
                      lastConsultationDate: formatDate(
                        patient.last_consultation_date,
                      ),
                      revisionDays: patient.revision_days,
                    })
                  }}
                </p>
              </b-alert>
            </b-card-text>
          </b-card>
        </li>
      </ul>
    </div>
    <PatientViewModal @updatePatient="clearSearchString" />
  </b-modal>
</template>

<script>
import Spinner from '@/components/utils/Spinner';
import PatientViewModal from '@/components/patients/PatientViewModal';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Spinner, PatientViewModal },

  mixins: [api, helper],

  data() {
    return {
      searchString: '',
      isSearching: false,
      patients: [],
    };
  },

  computed: mapState(['customer']),

  methods: {
    init() {
      this.$store.commit('mutate', {
        prop: 'patientsSideBarVisible',
        with: true,
      });
    },

    reset() {
      this.searchString = '';
      this.patients = [];

      this.$store.commit('mutate', {
        prop: 'patientsSideBarVisible',
        with: false,
      });
    },

    clearSearchString() {
      this.searchString = '';
    },

    searchPatients() {
      this.isSearching = true;

      axios
        .get(
          `${this.baseApiUrl}/patients/search/${this.customer.id}/${this.searchString}`,
        )
        .then(res => {
          this.patients = res.data || [];
        })
        .catch(this.showError)
        .finally(() => {
          this.isSearching = false;
        });
    },

    setPatient(patient) {
      this.$store.commit('mutate', {
        prop: 'patientSearch',
        with: { ...patient },
      });
    },
  },

  watch: {
    searchString(value) {
      if (!value || !value.trim()) {
        this.patients = [];
      } else {
        this.searchPatients();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#patients-sidebar___BV_modal_content_ {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 320px;
  height: 68vh;
  margin-top: 16vh !important;
  border-left: none !important;
  border-radius: unset !important;
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;

  .patients-search-input {
    display: flex;
    flex-direction: row;
    width: 270px;
    height: 27px;
    background: $gray-100;
    border: 1px solid $input-border-color;
    border-radius: 5px;

    svg {
      margin: 4px 6px 0;
      color: $gray-500;
      opacity: 0.7;
    }

    input {
      height: 27px;
      padding: 0 5px 0 2px;
      font-size: 13px;
      background: none;
      border: none;
      outline: none;

      &:focus {
        background: none;
      }

      &::placeholder {
        color: $gray-600;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 15px;
        width: 15px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
        background-repeat: no-repeat;
        background-size: 13px;
        background-position: top right;
      }
    }

    &.sm,
    &.xs {
      max-width: 270px;
      width: 89vw;
    }
  }

  .no-result {
    padding: 30px 0 20px 0;
    font-size: 13px;
    color: $gray-800;
  }

  .patients-list {
    height: calc(68vh - 110px);
    margin-top: 15px;
    padding: 0;
    list-style-type: none;
    overflow: hidden scroll;

    .patient-card {
      margin: 0 10px 15px 0;
      outline: 0;
      box-shadow: $card-shadow;
      cursor: pointer;

      .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;
      }

      h1 {
        padding: 5px 0 5px 10px;
        font-size: 13px;
        color: $gray-800;
      }

      .card-header button {
        padding-right: 15px;
      }

      .card-body {
        padding: 0;
      }

      .card-text {
        outline: 0;
      }

      .patient-birth-date {
        margin: 0;
        padding: 5px 0 0 10px;
        font-size: 12px;
        color: $gray-800;
      }

      .patient-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .patient-id,
        .patient-category {
          margin: 0;
          padding: 0 10px 5px;
          font-size: 12px;
          color: $gray-700;
        }
      }

      .alert {
        padding: 0;
        font-size: 11px;
        color: $dark-yellow;
        background-color: transparent;
        border-color: transparent;

        p {
          margin: 0;
          padding: 0 10px;
        }
      }
    }
  }
}
</style>
