<template>
  <Modal>
    <b-modal
      id="appointments-call-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @ok="confirm"
    >
      <input id="appointment-id" type="hidden" v-model="item.id" />
      <p class="msg">
        {{
          $t('confirmations.appointment.call', {
            title: item.patient_name,
          })
        }}
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="callingPatient"
          >
            {{ $t('buttons.no') }}
          </b-button>
          <b-button variant="info" @click="ok()" :disabled="callingPatient">
            <Spinner v-if="callingPatient" size="small" />
            <span v-else>
              {{ $t('buttons.yes') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, Spinner },

  mixins: [api, helper],

  data() {
    return {
      callingPatient: false,
      receptionistId: null,
    };
  },

  computed: mapState(['user', 'item']),

  methods: {
    async confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.callingPatient = true;

      await this.sendMessage();
      await this.callPatient();
    },

    async callPatient() {
      const id = this.item.id;

      let time_treatment = new Date();
      time_treatment = this.formatTime(time_treatment);

      const appointment = {
        ...this.item,
        status: 'attending',
        time_treatment,
        time_end: null,
        phone_mobile: this.item.patient_phone_mobile,
        phone_home: this.item.patient_phone_home,
        phone_work: this.item.patient_phone_work,
        phone_other: this.item.patient_phone_other,
      };

      await axios
        .put(`${this.baseApiUrl}/appointments/${id}`, appointment)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.appointment.call', {
              title: appointment.patient_name,
            }),
          });

          this.$emit('setPatient');

          this.$store.commit('mutate', {
            prop: 'item',
            with: { ...appointment },
          });

          this.$bvModal.hide('appointments-call-modal');
          this.$bvModal.show('appointments-record-modal');
        })
        .catch(this.showError);

      this.callingPatient = false;
    },

    async sendMessage() {
      const message = {
        sender_id: this.user.id,
        recipient_id: this.item.call_user_id,
        type: 'call',
        content: this.$t('messages.appointment.call', {
          title: this.item.patient_name,
        }),
      };

      await axios
        .post(`${this.baseApiUrl}/messages`, message)
        .catch(this.showError);
    },
  },
};
</script>

<style lang="scss">
#appointments-call-modal {
  .msg {
    padding-bottom: 30px;
  }
}
</style>
