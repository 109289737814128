<template>
  <div class="bills-messenger" :class="$mq">
    <div class="messages">
      <div
        v-for="message in messages"
        :key="message.id"
        class="message"
        :class="message.status"
      >
        <button class="close" @click="closeMessage(message.id)">×</button>
        <p class="title">
          {{ $t('bills.bill') }}
          {{ $t(`bills.status.${message.status}`).toLowerCase() }}
        </p>
        <p>
          {{ message.name }}
          ({{ $t(`bills.account.${message.account}`) }})
        </p>
        <p>
          {{ $t('fields.bill.price') }}:
          {{ formatPrice(message.price) }}
        </p>
        <p>
          {{ $t('fields.bill.due_date') }}:
          {{ formatDate(message.due_date) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  mixins: [api, helper],

  data() {
    return {
      messages: [],
    };
  },

  computed: mapState(['customer']),

  async created() {
    if (this.$route.name === 'home' && this.customer.id) {
      await this.loadMessages();
    }
  },

  methods: {
    async loadMessages() {
      await axios
        .get(`${this.baseApiUrl}/finances/messages/${this.customer.id}`)
        .then(res => {
          const messages = res.data || [];

          this.messages = messages;
        });
    },

    closeMessage(message_id) {
      this.messages = this.messages.filter(
        message => message.id !== message_id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/custom.scss';

.bills-messenger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: fixed;
  top: 35px;
  left: 25px;

  .messages {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 320px;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    z-index: 1091;

    .message {
      width: 300px;
      margin: 2px 0 3px;
      padding: 5px 10px 15px 15px;
      font-size: 14px;
      color: $body-color;
      background-color: $white;
      border-radius: $border-radius;
      box-shadow: -1px 0 3px 1px rgba(0, 0, 0, 0.39);

      &.expired {
        background-color: darken($light-red, 5%);
      }

      &.aboutToExpire {
        background-color: $light-blue;
      }

      &.dueDay {
        background-color: lighten($purple, 25%);
      }

      p {
        margin: 0;

        &.title {
          margin: 10px 0 10px;
          font-weight: bold;
        }
      }
    }
  }

  &.xs {
    .messages {
      width: 80vw;
      max-width: 320px;

      .message {
        width: 80vw;
        max-width: 300px;
      }
    }
  }
}
</style>
