<template>
  <Modal>
    <b-modal
      id="profile-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('user.profileEdit') }}
        </h1>
        <button type="button" class="close" :disabled="saving" @click="close()">
          ×
        </button>
      </template>
      <form ref="form">
        <input id="user-id" type="hidden" v-model="user.id" />
        <FormField
          v-for="field in fields"
          :key="field.key"
          entity-name="user"
          entity-state="user"
          :field="field"
          :disabled="field.disabled || saving"
        />
      </form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()" :disabled="saving">
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="primary" @click="ok()" :disabled="saving">
            <Spinner v-if="saving" size="small" />
            <span v-else>
              {{ $t('buttons.save') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import auth from '@/mixins/auth';
import helper from '@/mixins/helper';
import validate from '@/mixins/validate';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, auth, helper, validate],

  computed: mapState(['user']),

  data() {
    return {
      title: document.title,
      saving: false,
      userData: {},
      fields: [
        { key: 'fullname', state: null, required: true },
        { key: 'username', state: null, required: true },
        {
          key: 'email',
          state: null,
          required: true,
          validation: 'checkEmail',
          validationMsg: this.$t('messages.user.emailInvalid'),
        },
        {
          key: 'role',
          label: this.$t('fields.user.role.role'),
          translate: true,
          disabled: true,
          required: true,
        },
      ],
    };
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('user.profileEdit')}`;
      this.userData = { ...this.user };
      this.fields.forEach(field => {
        field.state = true;
      });
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });
    },

    reset() {
      document.title = this.title;
      this.$store.commit('mutate', { prop: 'user', with: this.userData });
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (!this.checkFieldsValidity(this.user, this.fields)) {
        return;
      }
      this.saving = true;

      this.save();
    },

    save() {
      const user = {
        ...this.userData,
        fullname: this.captalize(this.user.fullname),
        username: this.captalize(this.user.username),
        email: this.formatEmail(this.user.email),
      };

      axios
        .put(`${this.baseApiUrl}/updateProfile/${user.id}`, user)
        .then(() => {
          this.userData = { ...user };
          this.setUser(user);

          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.user.profileUpdated'),
          });

          this.$bvModal.hide('profile-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>
