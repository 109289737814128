<template>
  <Auth>
    <b-form @submit.prevent="resetPassword()">
      <b-form-group>
        <b-form-input
          type="text"
          name="email"
          v-model="user.email"
          :placeholder="$t('fields.user.email')"
          :disabled="resetingPassword"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="text"
          name="reset_code"
          v-model="user.reset_code"
          :placeholder="$t('fields.user.resetCode')"
          :disabled="resetingPassword"
          autocomplete="chrome-off"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="password"
          name="passwordNew"
          v-model="user.passwordNew"
          :placeholder="$t('fields.user.passwordNew')"
          :disabled="resetingPassword"
          autocomplete="new-password"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-form-group>
        <b-form-input
          type="password"
          name="passwordNewConfirmation"
          v-model="user.passwordNewConfirmation"
          :placeholder="$t('fields.user.passwordNewConfirmation')"
          :disabled="resetingPassword"
          autocomplete="new-password"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-button type="submit" variant="info" block :disabled="resetingPassword">
        <Spinner v-if="resetingPassword" size="small" />
        <span v-else>
          {{ $t('buttons.save') }}
        </span>
      </b-button>
      <router-link to="auth_forgot" :class="{ disabled: resetingPassword }">
        <span>{{ $t('user.resendCode') }}</span>
      </router-link>
    </b-form>
  </Auth>
</template>

<script>
import Auth from '@/components/auth/Auth';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';

import axios from 'axios';

export default {
  components: { Auth, Spinner },

  mixins: [api],

  data() {
    return {
      user: {},
      resetingPassword: false,
    };
  },

  methods: {
    resetPassword() {
      this.resetingPassword = true;

      axios
        .post(`${this.baseApiUrl}/resetPassword`, this.user)
        .then(() => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.user.passwordSaved'),
          });

          this.$router.push({ path: '/auth' });
        })
        .catch(this.showError)
        .finally(() => {
          this.resetingPassword = false;
        });
    },
  },
};
</script>
