<template>
  <Modal>
    <b-modal
      id="finances-revenues-modal"
      size="xl"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t('finances.revenues.revenues') }}</h1>
        <button type="button" class="close" @click="close()">×</button>
      </template>
      <header v-show="!loading.data" class="revenues-header" :class="$mq">
        <b-form-group class="dates">
          <RevenuesDate
            date-type="start"
            :selected-date="date.start"
            :label-no-date-selected="$t('finances.revenues.startDate')"
            :disabled="disableComponents"
            @dateChange="onDateChanged"
          />
          <p class="date-to">{{ $t('finances.revenues.toDate') }}</p>
          <RevenuesDate
            date-type="end"
            :selected-date="date.end"
            :label-no-date-selected="$t('finances.revenues.endDate')"
            :disabled="disableComponents"
            @dateChange="onDateChanged"
          />
        </b-form-group>
        <RevenuesItems
          entity="doctors"
          :items="doctors"
          display-field="username"
          :disabled="disableComponents"
          @setSelected="onSetSelectedItems"
        />
        <RevenuesItems
          entity="appointment_types"
          :items="appointment_types"
          display-field="name"
          group-class="grid"
          :disabled="disableComponents"
          @setSelected="onSetSelectedItems"
        />
        <RevenuesItems
          entity="categories"
          :items="categories"
          display-field="text"
          :disabled="disableComponents"
          @setSelected="onSetSelectedItems"
        />
        <ActionButtons
          :actions="actions"
          @filterRevenues="filterRevenues"
          @print="print"
        />
        <RevenuesFooter
          v-if="showRevenues && $mq !== 'xl' && !loading.revenues.all"
          :total="total"
        />
      </header>
      <Spinner v-if="loading.data" variant="info" size="large" />
      <section v-else-if="showRevenues">
        <RevenuesTabs>
          <template #body>
            <b-tab
              v-for="tab in tabs"
              :key="tab.key"
              :title="tab.title"
              :active="tab.active && !loading.revenues.all"
            >
              <b-card-text>
                <RevenuesFooter
                  v-if="showRevenues && $mq === 'xl' && !loading.revenues.all"
                  :total="total"
                  class-name="print"
                />
                <Skeleton
                  v-if="loading.revenues.all"
                  :title="false"
                  :buttons="0"
                  :rows="13"
                  :columns="6"
                  :small="true"
                />
                <RevenuesTable
                  v-else
                  :tab="tab.key"
                  :fields="fields[tab.key]"
                  :items="revenues[tab.key]"
                  :main-field="tab.key"
                />
              </b-card-text>
            </b-tab>
          </template>
          <template #footer>
            <RevenuesFooter
              v-if="$mq === 'xl' && !loading.revenues.all"
              :total="total"
            />
          </template>
        </RevenuesTabs>
      </section>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';
import Skeleton from '@/components/utils/Skeleton';
import ActionButtons from '@/components/utils/ActionButtons/ActionButtons';

import RevenuesDate from '@/components/finances/Revenues/RevenuesDate';
import RevenuesItems from '@/components/finances/Revenues/RevenuesItems';

import RevenuesTabs from '@/components/finances/Revenues/RevenuesTabs';
import RevenuesTable from '@/components/finances/Revenues/RevenuesTable';
import RevenuesFooter from '@/components/finances/Revenues/RevenuesFooter';

import api from '@/mixins/api';
import helper from '@/mixins/helper';
import print from '@/mixins/print';

import axios from 'axios';

export default {
  components: {
    Modal,
    Spinner,
    Skeleton,
    ActionButtons,

    RevenuesDate,
    RevenuesItems,

    RevenuesTabs,
    RevenuesTable,
    RevenuesFooter,
  },

  mixins: [api, helper, print],

  data() {
    return {
      title: document.title,
      showRevenues: false,
      loading: {
        data: false,
        doctors: false,
        appointment_types: false,
        revenues: {
          all: false,
          list: false,
          doctor: false,
          type: false,
          category: false,
          patient: false,
        },
        total: false,
      },
      date: {
        start: null,
        end: null,
      },
      doctors: [],
      appointment_types: [],
      categories: [],
      selected: {
        doctors: null,
        appointment_types: null,
        categories: null,
      },
      tabs: [
        { key: 'list', title: this.$t('finances.revenues.list'), active: true },
        { key: 'doctor', title: this.$t('finances.revenues.summaryDoctor') },
        { key: 'type', title: this.$t('finances.revenues.summaryType') },
        {
          key: 'category',
          title: this.$t('finances.revenues.summaryCategory'),
        },
        { key: 'patient', title: this.$t('finances.revenues.summaryPatient') },
      ],
      revenues: {
        list: [],
        doctor: [],
        type: [],
        category: [],
        patient: [],
      },
      total: {},
      fields: {
        list: [
          { key: 'fit', label: '', class: this.$mq === 'xl' && 'text-right' },
          {
            key: 'date',
            label: this.$t('fields.appointment.date'),
            formatter: value => this.formatDate(value),
            class: this.$mq === 'xl' && 'text-center',
          },
          {
            key: 'patient',
            label: this.$t('fields.appointment.patient_name'),
          },
          {
            key: 'doctor',
            label: this.$t('fields.appointment.doctor'),
          },
          { key: 'type', label: this.$t('fields.appointment.type') },
          {
            key: 'category',
            label: this.$t('fields.appointment.category.category'),
            translate: true,
          },
          {
            key: 'price',
            label: this.$t('fields.appointment.price'),
            formatter: value => this.formatPrice(value),
            class: this.$mq === 'xl' && 'text-right',
          },
        ],
        doctor: [
          {
            key: 'doctor',
            label: this.$t('finances.revenues.doctor'),
            sortable: true,
          },
          {
            key: 'appointments',
            label: this.$t('finances.revenues.appointments'),
            class: this.$mq === 'xl' && 'text-center',
            sortable: true,
          },
          {
            key: 'total',
            label: this.$t('finances.revenues.total'),
            formatter: value => this.formatPrice(value),
            class: this.$mq === 'xl' && 'text-right',
            sortable: true,
          },
        ],
        type: [
          {
            key: 'type',
            label: this.$t('finances.revenues.appointment_type'),
            sortable: true,
          },
          {
            key: 'appointments',
            label: this.$t('finances.revenues.appointments'),
            class: this.$mq === 'xl' && 'text-center',
            sortable: true,
          },
          {
            key: 'total',
            label: this.$t('finances.revenues.total'),
            formatter: value => this.formatPrice(value),
            class: this.$mq === 'xl' && 'text-right',
            sortable: true,
          },
        ],
        category: [
          {
            key: 'category',
            label: this.$t('finances.revenues.category'),
            translate: true,
            sortable: true,
          },
          {
            key: 'appointments',
            label: this.$t('finances.revenues.appointments'),
            class: this.$mq === 'xl' && 'text-center',
            sortable: true,
          },
          {
            key: 'total',
            label: this.$t('finances.revenues.total'),
            formatter: value => this.formatPrice(value),
            class: this.$mq === 'xl' && 'text-right',
            sortable: true,
          },
        ],
        patient: [
          {
            key: 'patient',
            label: this.$t('finances.revenues.patient'),
            sortable: true,
          },
          {
            key: 'appointments',
            label: this.$t('finances.revenues.appointments'),
            class: this.$mq === 'xl' && 'text-center',
            sortable: true,
          },
          {
            key: 'total',
            label: this.$t('finances.revenues.total'),
            formatter: value => this.formatPrice(value),
            class: this.$mq === 'xl' && 'text-right',
            sortable: true,
          },
        ],
      },
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    optionsSelected() {
      return (
        !!this.date.start &&
        !!this.date.end &&
        !!this.selected.doctors.length &&
        !!this.selected.appointment_types.length &&
        !!this.selected.categories.length
      );
    },

    disableComponents() {
      return this.loading.data || this.loading.revenues.all;
    },

    actions() {
      const actions = [
        {
          name: 'filter',
          label: this.$t('finances.revenues.filter'),
          variant: 'primary',
          icon: ['fas', 'funnel-dollar'],
          function: 'filterRevenues',
          disabled:
            !this.optionsSelected ||
            this.loading.data ||
            this.loading.revenues.all,
        },
      ];

      if (this.$mq === 'xl') {
        actions.push({
          name: 'print',
          label: this.$t('buttons.print'),
          variant: 'dark',
          icon: ['fas', 'print'],
          function: 'print',
          functionParam: 'revenues-tabs',
          disabled:
            !this.optionsSelected ||
            !this.showRevenues ||
            this.loading.data ||
            this.loading.revenues.all,
        });
      }

      return actions;
    },
  },

  methods: {
    async init() {
      document.title = `${this.appName} - ${this.$t(
        'finances.revenues.revenues',
      )}`;

      await this.loadData();
    },

    reset() {
      document.title = this.title;

      this.showRevenues = false;
      this.loading = {
        data: false,
        doctors: false,
        appointment_types: false,
        revenues: {
          all: false,
          list: false,
          doctor: false,
          type: false,
          category: false,
          patient: false,
        },
        total: false,
      };
      this.date = {
        start: null,
        end: null,
      };
      this.doctors = [];
      this.appointment_types = [];
      this.categories = [];
      this.selected = {
        doctors: null,
        appointment_types: null,
        categories: null,
      };
      this.revenues = {
        list: [],
        doctor: [],
        type: [],
        category: [],
        patient: [],
      };
      this.total = {};
    },

    async loadData() {
      this.loading.data = true;

      await Promise.all([
        this.loadItem('doctors'),
        this.loadItem('appointment_types'),
      ]);

      this.setCategories();

      this.loading.data =
        this.loading.doctors || this.loading.appointment_types;
    },

    async loadItem(entity) {
      this.loading[entity] = true;

      await axios
        .get(`${this.baseApiUrl}/${entity}/${this.customer.id}`)
        .then(res => {
          this[entity] = res.data || [];

          this.loading[entity] = false;
        })
        .catch(this.showError);
    },

    setCategories() {
      const categories = [
        {
          id: '"private"',
          text: this.$t('fields.appointment.category.private'),
        },
        {
          id: '"unpaid"',
          text: this.$t('fields.appointment.category.unpaid'),
        },
      ];

      this.categories = categories;
    },

    onDateChanged(dateType, date) {
      this.date[dateType] = date;
    },

    onSetSelectedItems(entity, selected) {
      this.selected[entity] = selected;
    },

    async filterRevenues() {
      this.showRevenues = true;
      this.loading.revenues.all = true;

      await Promise.all([
        this.loadRevenues('list'),
        this.loadRevenues('doctor'),
        this.loadRevenues('type'),
        this.loadRevenues('category'),
        this.loadRevenues('patient'),
        this.getTotal(),
      ]);

      this.loading.revenues.all =
        this.loading.revenues.list ||
        this.loading.revenues.doctor ||
        this.loading.revenues.type ||
        this.loading.revenues.category ||
        this.loading.revenues.patient ||
        this.loading.total;
    },

    async loadRevenues(tab) {
      this.loading.revenues[tab] = true;

      const dateStart = this.formatDate(
        this.date.start,
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      );
      const dateEnd = this.formatDate(
        this.date.end,
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      );
      const doctors = this.selected.doctors;
      const appointment_types = this.selected.appointment_types;
      const categories = this.selected.categories;

      await axios
        .get(
          `${this.baseApiUrl}/finances/revenues/${tab}/${dateStart}/${dateEnd}/${doctors}/${appointment_types}/${categories}`,
        )
        .then(res => {
          const revenues = res.data || [];

          revenues.forEach(revenue => {
            this.fields[tab].forEach(field => {
              if (field.translate) {
                revenue[field.key] = this.$t(
                  `fields.appointment.${field.key}.${revenue[field.key]}`,
                );
              }
            });
            revenue.fit = revenue.fit ? this.$t('fields.appointment.fit') : '';
            revenue._rowVariant = revenue.status;
          });

          this.revenues[tab] = revenues;
          this.loading.revenues[tab] = false;
        })
        .catch(this.showError);
    },

    async getTotal() {
      this.loading.total = true;

      const dateStart = this.formatDate(
        this.date.start,
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      );
      const dateEnd = this.formatDate(
        this.date.end,
        'DD/MM/YYYY',
        'YYYY-MM-DD',
      );
      const doctors = this.selected.doctors;
      const appointment_types = this.selected.appointment_types;
      const categories = this.selected.categories;

      await axios
        .get(
          `${this.baseApiUrl}/finances/revenues/total/${dateStart}/${dateEnd}/${doctors}/${appointment_types}/${categories}`,
        )
        .then(res => {
          this.total = res.data || {};

          this.loading.total = false;
        })
        .catch(this.showError);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#finances-revenues-modal {
  .revenues-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;

    .dates {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 9px;

      .date-to {
        margin: 0;
        padding: 4px 10px;
        font-size: 12px;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 82px;
      margin: 9px 0 0;

      .action-button {
        flex: unset;
        margin: 0;
        border-radius: $border-radius;
      }
    }

    &.xs,
    &.sm {
      display: grid;
      gap: 20px;
      grid-template-areas:
        'dates dates'
        'doctors categories'
        'appointmentTypes appointmentTypes'
        'actionButtons actionButtons'
        'total total';

      .dates {
        grid-area: dates;
        margin-top: 0;

        > div {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      .finances-items {
        &:nth-child(2) {
          grid-area: doctors;
        }

        &:nth-child(3) {
          grid-area: appointmentTypes;
        }

        &:nth-child(4) {
          grid-area: categories;
        }

        > div {
          grid-template-columns: auto auto;
        }
      }

      .action-buttons {
        grid-area: actionButtons;
        min-height: unset;
        margin-bottom: 10px;
      }

      .revenues-footer {
        grid-area: total;
        flex-direction: column;
        font-weight: bold;
        line-height: 30px;

        span {
          font-weight: normal;
        }
      }
    }
  }
}
</style>
