<template>
  <Modal>
    <b-modal
      id="support-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <template #modal-header="{ close }">
        <h1>
          {{ $t('menu.help.support') }}
        </h1>
        <button
          type="button"
          class="close"
          :disabled="sendingMessage"
          @click="close()"
        >
          ×
        </button>
      </template>
      <p class="text">{{ $t('messages.user.hello') }}, {{ user.username }}!</p>
      <p class="text">
        {{ $t('messages.support.text') }}
      </p>
      <form ref="form">
        <FormField
          v-for="field in fields"
          :key="field.key"
          entity-name="message"
          entity-state="item"
          :field="field"
          :disabled="field.disabled || sendingMessage"
        />
      </form>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="sendingMessage"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button variant="primary" @click="ok()" :disabled="sendingMessage">
            <Spinner v-if="sendingMessage" size="small" />
            <span v-else>
              {{ $t('buttons.send') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import FormField from '@/components/utils/FormField';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import validate from '@/mixins/validate';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, FormField, Spinner },

  mixins: [api, validate],

  computed: mapState(['user', 'item']),

  data() {
    return {
      title: document.title,
      sendingMessage: false,
      messageData: {},
      fields: [
        {
          key: 'subject',
          state: null,
          required: true,
          autofocus: true,
        },
        {
          key: 'content',
          state: null,
          type: 'textarea',
          rows: 10,
          required: true,
        },
      ],
    };
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('menu.help.support')}`;
      this.fields.forEach(field => {
        field.state = true;
      });
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });
    },

    reset() {
      document.title = this.title;
      this.$store.commit('mutate', { prop: 'item', with: {} });
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (!this.checkFieldsValidity(this.item, this.fields)) {
        return;
      }
      this.sendingMessage = true;

      this.sendMessage();
    },

    sendMessage() {
      const message = { ...this.user, ...this.item };

      axios
        .post(`${this.baseApiUrl}/support`, message)
        .then(() => {
          this.$toasted.global.defaultSuccess({
            msg: this.$t('messages.support.sent'),
          });

          this.$bvModal.hide('support-modal');
        })
        .catch(this.showError)
        .finally(() => {
          this.sendingMessage = false;
        });
    },
  },
};
</script>
