var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',[_c('b-modal',{attrs:{"id":"appointments-cancel-modal","centered":"","no-close-on-backdrop":"","no-close-on-esc":"","hide-header":""},on:{"ok":_vm.confirm},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('div',{staticClass:"buttons",class:_vm.$mq},[_c('b-button',{attrs:{"variant":"secondary","disabled":_vm.cancelingAppointment},on:{"click":function($event){return cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.no'))+" ")]),_c('b-button',{attrs:{"variant":"danger","disabled":_vm.cancelingAppointment},on:{"click":function($event){return ok()}}},[(_vm.cancelingAppointment)?_c('Spinner',{attrs:{"size":"small"}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.yes'))+" ")])],1)],1)]}}])},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.id),expression:"item.id"}],attrs:{"id":"appointment-id","type":"hidden"},domProps:{"value":(_vm.item.id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.item, "id", $event.target.value)}}}),_c('p',{staticClass:"msg",domProps:{"innerHTML":_vm._s(
        _vm.$t('confirmations.appointment.cancel', {
          title: _vm.item.patient_name,
        })
      )}}),_c('FormField',{attrs:{"entity-name":"appointment","entity-state":"item","field":_vm.field,"disabled":_vm.disableComponents}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }