<template>
  <b-button-group class="action-buttons">
    <ActionButton
      v-for="action in actions"
      :key="action.name"
      :action="action"
      :entity-name="entityName"
      :is-item-selected="isItemSelected"
      :disabled="disabled"
      @emitFunction="emitFunction"
    />
  </b-button-group>
</template>

<script>
import ActionButton from '@/components/utils/ActionButtons/ActionButton';

export default {
  components: { ActionButton },

  props: {
    entityName: String,
    actions: Array,
    isItemSelected: Boolean,
    disabled: Boolean,
  },

  methods: {
    emitFunction(functionName, functionParam) {
      if (functionName) {
        this.$emit(functionName, functionParam);
      }
    },
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  flex-direction: row;
}
</style>
