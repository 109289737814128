<template>
  <div class="schedule-table">
    <b-table
      small
      striped
      borderless
      outlined
      hover
      show-empty
      no-border-collapse
      tbody-tr-class="row-class"
      :sticky-header="$mq === 'xl'"
      :items="schedules"
      :fields="fields"
      :fixed="fixed"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @row-clicked="onRowClicked"
      @row-contextmenu="onContextMenu"
      @row-dblclicked="doubleClick"
      :empty-text="emptyText"
    >
    </b-table>
    <DropdownMenu
      entity-name="appointments"
      :actions="dropdownActions"
      :position="dropdownPosition"
      @setFit="setFit"
      @setWait="setWait"
      @setBlock="setBlock"
      @setUnlock="setUnlock"
      @setAbsence="setAbsence"
      @setPatient="setPatient"
    />
  </div>
</template>

<script>
import DropdownMenu from '@/components/utils/DropdownMenu/DropdownMenu';

import helper from '@/mixins/helper';

export default {
  components: { DropdownMenu },

  mixins: [helper],

  props: {
    date: String,
    doctor: Object,
    fields: Array,
    isItemSelected: Boolean,
  },

  data() {
    return {
      schedules: [],
      selectedRow: null,
      sortBy: 'time_scheduling',
      sortDesc: false,
      dropdownPosition: {
        top: 0,
        left: 0,
      },
      actions: [
        {
          name: 'new',
          label: this.$t('actions.appointment.new'),
          modal: 'form',
        },
        {
          name: 'change',
          label: this.$t('actions.appointment.change'),
          modal: 'form',
        },
        {
          name: 'fit',
          label: this.$t('actions.appointment.fit'),
          modal: 'form',
          function: 'setFit',
        },
        {
          name: 'wait',
          label: this.$t('actions.appointment.wait'),
          function: 'setWait',
        },
        {
          name: 'authorize',
          label: this.$t('actions.appointment.authorize'),
          modal: 'authorize',
          function: 'setPatient',
        },
        {
          name: 'cancel',
          label: this.$t('actions.appointment.cancel'),
          modal: 'cancel',
        },
        {
          name: 'revert',
          label: this.$t('actions.appointment.revert'),
          modal: 'revert',
        },
        {
          name: 'block',
          label: this.$t('actions.appointment.block'),
          function: 'setBlock',
        },
        {
          name: 'unlock',
          label: this.$t('actions.appointment.unlock'),
          function: 'setUnlock',
        },
        {
          name: 'absence',
          label: this.$t('actions.appointment.absence'),
          function: 'setAbsence',
        },
        {
          name: 'patient',
          label: this.$t('actions.appointment.patientData'),
          modal: 'patient',
          function: 'setPatient',
        },
        {
          name: 'call',
          label: this.$t('actions.appointment.call'),
          modal: 'call',
        },
        {
          name: 'appointments',
          label: this.$t('actions.appointment.appointments'),
          modal: 'list',
          function: 'setPatient',
        },
        {
          name: 'viewRecord',
          label: this.$t('actions.appointment.viewRecord'),
          modal: 'record',
          function: 'setPatient',
        },
        {
          name: 'openRecord',
          label: this.$t('actions.appointment.openRecord'),
          modal: 'record',
          function: 'setPatient',
        },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    fixed() {
      return false; //this.$mq !== 'xl';
    },

    emptyText() {
      const currentDate = this.formatDate(new Date(), null, 'YYYY-MM-DD');
      const date = this.formatDate(this.date, 'DD/MM/YYYY', 'YYYY-MM-DD');

      return date < currentDate
        ? this.$t('messages.appointment.noAppointments')
        : this.$t('messages.appointment.noSchedules');
    },

    isRowSelected() {
      return this.selectedRow !== null;
    },

    dropdownActions() {
      let dropdownActions = [];

      if (this.user.role === 'receptionist') {
        switch (this.item._rowVariant) {
          case 'scheduled':
            dropdownActions = [
              { name: 'new', disabled: true },
              { name: 'change' },
              { name: 'fit' },
              { name: 'wait', divider: true, hidden: this.$mq !== 'xl' },
              { name: 'authorize', divider: this.$mq !== 'xl' },
              { name: 'cancel' },
              { name: 'block', disabled: true },
              { name: 'absence' },
              { name: 'patient', divider: true },
            ];
            break;
          case 'canceled':
            dropdownActions = [
              { name: 'new' },
              { name: 'change', disabled: true },
              { name: 'fit', disabled: true },
              {
                name: 'wait',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'authorize', divider: this.$mq !== 'xl', disabled: true },
              { name: 'cancel', disabled: true },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'absence':
          case 'waiting':
          case 'attending':
          case 'concluded':
          case 'authorized':
            dropdownActions = [
              { name: 'new', disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              {
                name: 'wait',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'authorize', divider: this.$mq !== 'xl', disabled: true },
              {
                name: 'revert',
                disabled:
                  this.$mq !== 'xl' &&
                  (this.item._rowVariant === 'waiting' ||
                    this.item._rowVariant === 'attending' ||
                    this.item._rowVariant === 'concluded'),
              },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'pending':
            dropdownActions = [
              { name: 'new', disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              {
                name: 'wait',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'authorize', divider: this.$mq !== 'xl' },
              { name: 'revert', disabled: this.$mq !== 'xl' },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'block':
            dropdownActions = [
              { name: 'new', disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit', disabled: true },
              {
                name: 'wait',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'authorize', divider: this.$mq !== 'xl', disabled: true },
              { name: 'cancel', disabled: true },
              { name: 'unlock' },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true, disabled: true },
            ];
            break;
          default:
            dropdownActions = [
              { name: 'new' },
              { name: 'change', disabled: true },
              { name: 'fit', disabled: true },
              {
                name: 'wait',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'authorize', divider: this.$mq !== 'xl', disabled: true },
              { name: 'cancel', disabled: true },
              { name: 'block' },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true, disabled: true },
            ];
            break;
        }
      } else if (this.user.role === 'doctor') {
        switch (this.item._rowVariant) {
          case 'scheduled':
          case 'canceled':
          case 'absence':
          case 'authorized':
            dropdownActions = [
              { name: 'call', disabled: true, hidden: this.$mq !== 'xl' },
              { name: 'viewRecord', divider: this.$mq === 'xl' },
              { name: 'appointments' },
              {
                name: 'revert',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'block', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'concluded':
          case 'pending':
            dropdownActions = [
              { name: 'call', disabled: true, hidden: this.$mq !== 'xl' },
              { name: 'viewRecord', divider: this.$mq === 'xl' },
              { name: 'appointments' },
              { name: 'revert', divider: true, hidden: this.$mq !== 'xl' },
              { name: 'block', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'waiting':
            dropdownActions = [
              { name: 'call', hidden: this.$mq !== 'xl' },
              { name: 'viewRecord', divider: this.$mq === 'xl' },
              { name: 'appointments' },
              {
                name: 'revert',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'block', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'attending':
            dropdownActions = [
              { name: 'call', disabled: true, hidden: this.$mq !== 'xl' },
              { name: 'viewRecord', hidden: this.$mq === 'xl' },
              { name: 'openRecord', divider: true, hidden: this.$mq !== 'xl' },
              { name: 'appointments' },
              { name: 'revert', divider: true, hidden: this.$mq !== 'xl' },
              { name: 'block', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'block':
            dropdownActions = [
              { name: 'call', disabled: true, hidden: this.$mq !== 'xl' },
              {
                name: 'viewRecord',
                divider: this.$mq === 'xl',
                disabled: true,
              },
              { name: 'appointments', disabled: true },
              {
                name: 'revert',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'unlock' },
              { name: 'patient', divider: true, disabled: true },
            ];
            break;
          default:
            dropdownActions = [
              { name: 'call', disabled: true, hidden: this.$mq !== 'xl' },
              {
                name: 'viewRecord',
                divider: this.$mq === 'xl',
                disabled: true,
              },
              { name: 'appointments', disabled: true },
              {
                name: 'revert',
                divider: true,
                disabled: true,
                hidden: this.$mq !== 'xl',
              },
              { name: 'block' },
              { name: 'patient', divider: true, disabled: true },
            ];
            break;
        }
      } else if (this.user.role === 'admin') {
        switch (this.item._rowVariant) {
          case 'scheduled':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true, disabled: true },
              { name: 'change' },
              { name: 'fit' },
              { name: 'wait', divider: true },
              { name: 'authorize' },
              { name: 'cancel' },
              { name: 'block', disabled: true },
              { name: 'absence' },
              { name: 'patient', divider: true },
            ];
            break;
          case 'canceled':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true },
              { name: 'change', disabled: true },
              { name: 'fit', disabled: true },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'cancel', disabled: true },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'absence':
          case 'authorized':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true, disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'revert' },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'concluded':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true, disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'revert' },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'pending':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true, disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize' },
              { name: 'revert' },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'waiting':
            dropdownActions = [
              { name: 'call' },
              { name: 'viewRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true, disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'revert' },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'attending':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'openRecord', divider: true },
              { name: 'appointments' },
              { name: 'new', divider: true, disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit' },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'revert' },
              { name: 'block', disabled: true },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true },
            ];
            break;
          case 'block':
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true, disabled: true },
              { name: 'appointments', disabled: true },
              { name: 'new', divider: true, disabled: true },
              { name: 'change', disabled: true },
              { name: 'fit', disabled: true },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'cancel', disabled: true },
              { name: 'unlock' },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true, disabled: true },
            ];
            break;
          default:
            dropdownActions = [
              { name: 'call', disabled: true },
              { name: 'viewRecord', divider: true, disabled: true },
              { name: 'appointments', disabled: true },
              { name: 'new', divider: true },
              { name: 'change', disabled: true },
              { name: 'fit', disabled: true },
              { name: 'wait', divider: true, disabled: true },
              { name: 'authorize', disabled: true },
              { name: 'cancel', disabled: true },
              { name: 'block' },
              { name: 'absence', disabled: true },
              { name: 'patient', divider: true, disabled: true },
            ];
            break;
        }
      }

      dropdownActions.forEach((button, index) => {
        this.actions.forEach(action => {
          if (button.name === action.name) {
            dropdownActions[index] = { ...button, ...action };
          }
        });
      });

      return dropdownActions;
    },
  },

  created() {
    this.setSchedules();
  },

  methods: {
    setSchedules() {
      this.setDoctorAppointments();
      this.setDoctorSchedule();
    },

    setDoctorAppointments() {
      const phone_types = ['mobile', 'home', 'work', 'other'];

      this.doctor.appointments.forEach(appointment => {
        const phones = [];
        phone_types.forEach(type => {
          const phone = appointment[`patient_phone_${type}`];
          if (phone && !phones.includes(phone)) {
            phones.push(phone);
          }
        });

        this.schedules.push({
          ...appointment,
          patient_phones: phones.join(' / '),
        });
      });
    },

    setDoctorSchedule() {
      const day = this.formatDay(this.date, 'DD/MM/YYYY', 'dddd');
      const doctorSchedule = this.getDoctorSchedule(day);

      doctorSchedule.forEach(schedule => {
        const timeStart = this.getDateTime(schedule.start, 'HH:mm');
        const interval = this.getDuration(schedule.interval, 'HH:mm', 'day');
        const timeEnd = this.getDateTime(schedule.end, 'HH:mm');
        const isBlock = schedule.type === 'block';
        const id = schedule.id;

        let time = timeStart;
        while (this.formatTime(time) < this.formatTime(timeEnd)) {
          this.insertTimeIntoSchedule(time, isBlock, id);
          time = time.add(interval);
        }
        this.insertTimeIntoSchedule(timeEnd, isBlock, id);
      });
    },

    insertTimeIntoSchedule(time, isBlock, id = null) {
      const time_scheduling = this.formatTime(time, 'HH:mm', 'HH:mm:ss');

      const schedules = [];
      this.schedules.forEach(schedule => {
        schedules.push(schedule.time_scheduling);
      });

      if (!schedules.includes(time_scheduling)) {
        const schedule = { time_scheduling };
        if (isBlock) {
          schedule.id = id;
          schedule.patient_id = 'block';
          schedule._rowVariant = 'block';
        }
        this.schedules.push(schedule);
      }
    },

    getDoctorSchedule(day) {
      const doctorSchedule = [];

      const blockSchedule = this.doctor.schedule.block;
      blockSchedule.forEach(schedule => {
        doctorSchedule.push(schedule);
      });

      const extraSchedule = this.doctor.schedule.extra;
      extraSchedule.forEach(schedule => {
        doctorSchedule.push(schedule);
      });

      const daySchedule = this.doctor.schedule.daily.filter(
        schedule => schedule.day === day,
      );
      daySchedule.forEach(schedule => {
        doctorSchedule.push(schedule);
      });

      return doctorSchedule;
    },

    onRowClicked(item, index, event) {
      if (this.$mq === 'xl') {
        this.selectRow(item, index, event);
      } else {
        this.openDropdownMenu(item, index, event);
        if (!this.isRowSelected) {
          this.openDropdownMenu(item, index, event);
        }
      }
    },

    onContextMenu(item, index, event) {
      event.preventDefault();

      if (this.$mq === 'xl') {
        this.openDropdownMenu(item, index, event);
      }
    },

    selectRow(item, index, event) {
      if (this.selectedRow !== index) {
        this.selectedRow = index;
      } else if (event.type !== 'contextmenu') {
        this.clearSelected();
      }

      const appointment = {
        ...item,
        customer_id: this.customer.id,
        user_id: this.user.id,
        doctor_id: this.doctor.id,
        date: this.formatDate(this.date, 'DD/MM/YYYY', 'YYYY-MM-DD'),
      };

      this.$store.commit('mutate', {
        prop: 'item',
        with: { ...appointment },
      });

      this.$emit('itemselected', this.isRowSelected);
    },

    doubleClick(item, index, event) {
      if (item.patient_id && item.patient_id === 'block') {
        return;
      }

      this.selectRow(item, index, event);
      if (!this.isRowSelected) {
        this.selectRow(item, index, event);
      }

      if (this.user.role === 'receptionist' || this.user.role === 'admin') {
        if (
          item.status &&
          item.status !== 'scheduled' &&
          item.status !== 'canceled'
        ) {
          this.setFit();
        }
        this.$bvModal.show('appointments-form-modal');
      } else if (this.user.role === 'doctor') {
        if (item.status === 'waiting') {
          this.$bvModal.show('appointments-call-modal');
        } else if (item.status) {
          this.setPatient();
          this.$bvModal.show('appointments-record-modal');
        }
      }
    },

    clearSelected() {
      this.selectedRow = null;
      this.$emit('itemselected', this.isRowSelected);

      this.$store.commit('mutate', { prop: 'item', with: {} });
    },

    openDropdownMenu(item, index, event) {
      this.selectRow(item, index, event);

      const top = event.clientY - 15;
      const left = event.clientX;
      this.dropdownPosition = { top, left };
    },

    setFit() {
      this.$emit('setFit');
    },

    setWait() {
      this.$emit('setWait');
    },

    setBlock() {
      this.$emit('setBlock');
    },

    setUnlock() {
      this.$emit('setUnlock');
    },

    setAbsence() {
      this.$emit('setAbsence');
    },

    setPatient() {
      this.$emit('setPatient');
    },
  },
  watch: {
    selectedRow(value) {
      const elements = document.getElementsByClassName('row-class');

      elements.forEach(element => {
        element.style.backgroundColor = '';
      });
      if (value !== null) {
        elements[value].style.backgroundColor = '#aacdfa';
      }
    },

    isItemSelected(value) {
      if (!value) {
        this.clearSelected();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.schedule-table {
  .b-table-sticky-header {
    max-height: 445px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  table {
    th {
      font-size: 13px;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .row-class {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: $light-blue;
    }
  }

  .cancel-reason {
    width: 20px;
  }

  .table.b-table > thead > tr,
  .table.b-table > thead > tr > .table-b-table-default {
    color: $white;
    background-color: $primary;
  }

  .table-waiting {
    font-weight: bold;
    color: $orange;
  }

  .table-attending {
    font-weight: bold;
    color: $blue;
  }

  .table-pending {
    font-weight: bold;
    color: $purple;
  }

  .table-concluded {
    color: $dark-green;
  }

  .table-authorized {
    font-weight: bold;
    color: $dark-green;
  }

  .table-canceled {
    font-weight: bold;
    color: $gray-500;
  }

  .table-absence {
    font-weight: bold;
    text-decoration: line-through;
  }

  .table-block {
    font-weight: bold;
    color: $red;
    background-color: $light-red;

    &:nth-of-type(odd),
    &:nth-of-type(even) {
      background-color: $light-red;
    }
  }
}
</style>
