<template>
  <Auth>
    <b-form @submit.prevent="forgotPassword()">
      <b-form-group>
        <b-form-input
          type="text"
          name="email"
          v-model="user.email"
          :placeholder="$t('fields.user.email')"
          :disabled="sendingEmail"
          autocorrect="off"
          autocapitalize="none"
        />
      </b-form-group>
      <b-button type="submit" variant="info" block :disabled="sendingEmail">
        <Spinner v-if="sendingEmail" size="small" />
        <span v-else>
          {{ $t('user.recoverPassword') }}
        </span>
      </b-button>
      <router-link to="auth" :class="{ disabled: sendingEmail }">
        <span>{{ $t('main.back') }}</span>
      </router-link>
    </b-form>
  </Auth>
</template>

<script>
import Auth from '@/components/auth/Auth';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';

import axios from 'axios';

export default {
  components: { Auth, Spinner },

  mixins: [api],

  data() {
    return {
      user: {},
      sendingEmail: false,
    };
  },

  methods: {
    forgotPassword() {
      this.sendingEmail = true;

      axios
        .post(`${this.baseApiUrl}/forgotPassword`, this.user)
        .then(() => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.user.recoverEmailSent', {
              email: this.user.email,
            }),
          });

          this.$router.push({ path: '/auth_reset' });
        })
        .catch(this.showError)
        .finally(() => {
          this.sendingEmail = false;
        });
    },
  },
};
</script>
