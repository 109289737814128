<template>
  <Modal>
    <b-modal
      id="finances-bills-modal"
      size="xl"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t('finances.bills.bills') }}</h1>
        <button type="button" class="close" @click="close()">×</button>
      </template>
      <header v-show="!loading.data" class="bills-header" :class="$mq">
        <div class="year-month">
          <b-form-group
            :label="$t('finances.bills.year')"
            label-for="finances-bills-year"
          >
            <b-form-select
              id="finances-bills-year"
              v-model="year"
              :options="years"
              :disabled="disableComponents"
            />
          </b-form-group>
          <b-form-group
            :label="$t('finances.bills.month')"
            label-for="finances-bills-month"
          >
            <b-form-select
              id="finances-bills-month"
              v-model="month"
              :options="months"
              :disabled="disableComponents"
            />
          </b-form-group>
        </div>
        <b-form-group class="accounts" :label="$t(`finances.bills.accounts`)">
          <b-form-checkbox
            v-for="account in accounts"
            :key="account.value"
            :id="`checkbox-accounts-${account.value}`"
            :name="`checkbox-accounts-${account.value}`"
            v-model="account.checked"
            plain
            :disabled="disableComponents"
            @change="setSelectedAccounts"
          >
            <label :for="`checkbox-accounts-${account.value}`">
              {{ account.text }}
            </label>
          </b-form-checkbox>
        </b-form-group>
        <ActionButtons
          entity-name="bills"
          :actions="actions"
          :is-item-selected="isItemSelected"
          :disabled="
            disableActions || !selectedAccounts.length || disableComponents
          "
          @filterBills="filterBills"
          @clearSelected="clearSelected"
          @print="print"
        />
        <BillsFooter
          v-if="showBills && $mq !== 'xl' && !loading.bills.all"
          :total="total"
          :bills="bills.list"
        />
      </header>
      <Spinner v-if="loading.data" variant="info" size="large" />
      <section v-else-if="showBills">
        <div class="help">
          <fa-icon
            :icon="['fas', 'question-circle']"
            @click="$bvToast.show('help-toast')"
          />
          <b-toast
            id="help-toast"
            :title="$t('messages.finances.colorCodes')"
            variant="primary"
            static
            no-auto-hide
          >
            <p
              v-for="colorCode in colorCodes"
              :key="colorCode.status"
              :class="colorCode.status"
            >
              {{ colorCode.text }}
            </p>
          </b-toast>
        </div>
        <BillsTabs>
          <template #body>
            <b-tab
              v-for="tab in tabs"
              :key="tab.key"
              :title="tab.title"
              :active="tab.active && !loading.bills.all"
            >
              <b-card-text>
                <BillsFooter
                  v-if="$mq === 'xl' && showBills && !loading.bills.all"
                  :total="total"
                  :bills="bills.list"
                  class-name="print"
                />
                <Skeleton
                  v-if="loading.bills.all"
                  :title="false"
                  :buttons="0"
                  :rows="13"
                  :columns="6"
                  :small="true"
                />
                <BillsTable
                  v-else
                  :tab="tab.key"
                  :fields="fields[tab.key]"
                  :items="bills[tab.key]"
                  :main-field="tab.key"
                  :is-item-selected="isItemSelected"
                  @itemselected="updateItemSelected"
                />
              </b-card-text>
            </b-tab>
          </template>
          <template #footer>
            <BillsFooter
              v-if="$mq === 'xl' && !loading.bills.all"
              :total="total"
              :bills="bills.list"
            />
          </template>
        </BillsTabs>
      </section>
      <BillsFormModal
        :action="formAction"
        :accounts="selectedAccounts"
        :year="year"
        :month="month"
        @submit="onSubmitForm"
      />
      <BillsDeleteModal @submit="onSubmitForm" />
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';
import Skeleton from '@/components/utils/Skeleton';
import ActionButtons from '@/components/utils/ActionButtons/ActionButtons';

import BillsFormModal from '@/components/finances/Bills/BillsFormModal';
import BillsDeleteModal from '@/components/finances/Bills/BillsDeleteModal';

import BillsTabs from '@/components/finances/Bills/BillsTabs';
import BillsTable from '@/components/finances/Bills/BillsTable';
import BillsFooter from '@/components/finances/Bills/BillsFooter';

import api from '@/mixins/api';
import helper from '@/mixins/helper';
import print from '@/mixins/print';

import axios from 'axios';

export default {
  components: {
    Modal,
    Spinner,
    Skeleton,
    ActionButtons,

    BillsFormModal,
    BillsDeleteModal,

    BillsTabs,
    BillsTable,
    BillsFooter,
  },

  mixins: [api, helper, print],

  data() {
    return {
      title: document.title,
      showBills: false,
      year: null,
      month: null,
      selectedAccounts: '',
      disableActions: false,
      loading: {
        data: false,
        bills: {
          all: false,
          list: false,
          account: false,
        },
        total: false,
      },
      bills: {
        list: [],
        account: [],
      },
      total: {},
      isItemSelected: false,
      colorCodes: [
        { status: 'notPaid', text: this.$t('bills.status.notPaid') },
        {
          status: 'aboutToExpire',
          text: this.$t('bills.status.aboutToExpire'),
        },
        { status: 'dueDay', text: this.$t('bills.status.dueDay') },
        { status: 'expired', text: this.$t('bills.status.expired') },
        { status: 'paid', text: this.$t('bills.status.paid') },
        { status: 'paidLate', text: this.$t('bills.status.paidLate') },
      ],
      accounts: [
        {
          value: '"business"',
          text: this.$t('bill_expenses.account.business'),
        },
        {
          value: '"personal"',
          text: this.$t('bill_expenses.account.personal'),
        },
      ],
      tabs: [
        { key: 'list', title: this.$t('finances.bills.list'), active: true },
        { key: 'account', title: this.$t('finances.bills.summaryAccount') },
      ],
      fields: {
        list: [
          {
            key: 'bill_account',
            label: this.$t('bills.bill_account.account'),
            translate: true,
          },
          {
            key: 'name',
            label: this.$t('fields.bill.name'),
          },
          {
            key: 'price',
            label: this.$t('fields.bill.price'),
            formatter: value => this.formatPrice(value),
            class: this.$mq === 'xl' && 'text-right',
          },
          {
            key: 'due_date',
            label: this.$t('fields.bill.due_date'),
            formatter: value => this.formatDate(value),
            class: this.$mq === 'xl' && 'text-center',
          },
          {
            key: 'payment_date',
            label: this.$t('fields.bill.payment_date'),
            formatter: value => this.formatDate(value),
            class: this.$mq === 'xl' && 'text-center',
          },
          {
            key: 'bill_method',
            label: this.$t('fields.bill.method'),
            translate: true,
          },
          {
            key: 'description',
            label: this.$t('fields.bill.description'),
          },
        ],
        account: [
          {
            key: 'account',
            label: this.$t('finances.bills.accounts'),
            translate: true,
            sortable: true,
          },
          {
            key: 'bills',
            label: this.$t('finances.bills.totalPaid'),
            class: 'text-center',
            sortable: true,
          },
          {
            key: 'total',
            label: this.$t('finances.bills.totalPricePaid'),
            formatter: value => this.formatPrice(value),
            class: 'text-right',
            sortable: true,
          },
        ],
      },
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    item() {
      return this.$store.state.item;
    },

    disableComponents() {
      return this.loading.data || this.loading.bills.all;
    },

    years() {
      const years = [];

      const licenseDate = this.customer.license_date;
      const licenseYear = this.formatDate(licenseDate, 'YYYY-MM-DD', 'Y');

      const currentDate = new Date();
      const currentYear = this.formatDate(currentDate, 'YYYY-MM-DD', 'Y');

      for (let year = licenseYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },

    months() {
      const months = this.moment
        .months()
        .map(month => month.toLowerCase())
        .map(month => this.$t(`bill_expenses.month.${month}`))
        .map((month, index) => {
          return {
            value: index + 1,
            text: month,
            disabled: this.isMonthDisabled(index + 1),
          };
        });

      return months;
    },

    formAction() {
      return this.isItemSelected ? 'edit' : 'add';
    },

    actions() {
      const actions = [
        {
          name: 'filter',
          label: this.$t('finances.bills.filter'),
          variant: 'primary',
          icon: ['fas', 'funnel-dollar'],
          function: 'filterBills',
        },
        {
          name: 'add',
        },
      ];

      if (this.$mq === 'xl') {
        actions.push({ name: 'edit' });
        actions.push({ name: 'delete' });
        actions.push({
          name: 'print',
          label: this.$t('buttons.print'),
          variant: 'dark',
          icon: ['fas', 'print'],
          function: 'print',
          functionParam: 'bills-tabs',
          disabled:
            !this.selectedAccounts.length ||
            !this.showBills ||
            this.loading.bills.all,
        });
      }

      return actions;
    },
  },

  methods: {
    async init() {
      document.title = `${this.appName} - ${this.$t('finances.bills.bills')}`;

      this.$store.commit('mutate', { prop: 'closeDialog', with: true });

      this.year = this.formatDate(new Date(), 'YYYY-MM-DD', 'Y');
      this.month = this.formatDate(new Date(), 'YYYY-MM-DD', 'M');

      await this.createBills();
    },

    reset() {
      document.title = this.title;

      this.$store.commit('mutate', { prop: 'closeDialog', with: false });

      this.clearSelected();

      this.year = null;
      this.month = null;

      this.selectedAccounts = '';
      this.accounts.forEach(account => {
        account.checked = false;
      });

      this.showBills = false;
      this.loading = {
        data: false,
        bills: {
          all: false,
          list: false,
          account: false,
        },
        total: false,
      };
      this.bills = {
        list: [],
        account: [],
      };
      this.total = {};
    },

    isMonthDisabled(month) {
      const currentYear = this.formatDate(new Date(), 'YYYY-MM-DD', 'Y');

      if (this.year !== currentYear) {
        return false;
      }

      const currentMonth = this.formatDate(new Date(), 'YYYY-MM-DD', 'M');

      return month > currentMonth;
    },

    setSelectedAccounts() {
      this.selectedAccounts = this.accounts
        .filter(account => account.checked)
        .map(account => account.value)
        .join(', ');
    },

    updateItemSelected(value) {
      this.isItemSelected = value;
      this.disableActions = false;
    },

    async onSubmitForm() {
      await this.filterBills();
      this.clearSelected();
    },

    clearSelected() {
      this.isItemSelected = false;
      this.$store.commit('mutate', { prop: 'item', with: {} });
    },

    async createBills() {
      this.loading.data = true;

      const customer_id = this.customer.id;
      const year = `${this.year}`;
      const monthNumber =
        `${this.month}`.length === 1 ? `0${this.month}` : `${this.month}`;
      let monthName = this.month;
      monthName = this.formatDate(monthName, 'M', 'MMMM');
      monthName = monthName.toLowerCase();
      monthName = this.$t(`bill_expenses.month.${monthName}`);

      await axios
        .get(
          `${this.baseApiUrl}/bill_expenses/${customer_id}/${year}/${monthNumber}/${monthName}`,
        )
        .then(async res => {
          const bill_expenses = res.data || [];

          await bill_expenses.forEach(async bill_expense => {
            const due_day =
              `${bill_expense.due_day}`.length === 1
                ? `0${bill_expense.due_day}`
                : `${bill_expense.due_day}`;
            const due_date = `${year}-${monthNumber}-${due_day}`;

            const bill = {
              customer_id,
              expense_id: bill_expense.id,
              name: bill_expense.name,
              account: bill_expense.account,
              description: bill_expense.description,
              due_date,
              price: bill_expense.price,
            };

            await axios
              .post(`${this.baseApiUrl}/bills`, bill)
              .catch(this.showError);
          });
        })
        .catch(this.showError);

      this.loading.data = false;
    },

    async filterBills() {
      this.loading.bills.all = true;
      this.showBills = true;

      await Promise.all([
        this.loadBills('list'),
        this.loadBills('account'),
        this.getTotal(),
      ]);

      this.loading.bills.all =
        this.loading.bills.list ||
        this.loading.bills.account ||
        this.loading.total;
    },

    async loadBills(tab) {
      this.loading.bills[tab] = true;

      const customer_id = this.customer.id;
      const year = this.year;
      const month = this.month;
      const accounts = this.selectedAccounts;

      await axios
        .get(
          `${this.baseApiUrl}/finances/bills/${customer_id}/${tab}/${year}/${month}/${accounts}`,
        )
        .then(res => {
          const bills = res.data || [];

          bills.forEach(bill => {
            this.fields[tab].forEach(field => {
              if (field.translate && bill[field.key]) {
                bill[field.key] = this.$t(
                  `bills.${field.key}.${bill[field.key]}`,
                );
              }
            });
            bill._rowVariant = bill.status;
          });

          this.bills[tab] = bills;
          this.loading.bills[tab] = false;
        })
        .catch(this.showError);
    },

    async getTotal() {
      this.loading.total = true;

      const customer_id = this.customer.id;
      const year = this.year;
      const month = this.month;
      const accounts = this.selectedAccounts;

      await axios
        .get(
          `${this.baseApiUrl}/finances/bills/${customer_id}/total/${year}/${month}/${accounts}`,
        )
        .then(res => {
          this.total = res.data || {};

          this.loading.total = false;
        })
        .catch(this.showError);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#finances-bills-modal {
  .bills-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 15px;

    .year-month {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 190px;

      .form-group {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        height: 55px;
        margin-bottom: 10px;

        label {
          margin: 0;
          padding: 5px 5px 0;
          font-size: 12px;
          font-weight: bold;
          color: $gray-700;

          &::after {
            content: ':';
          }
        }

        .custom-select {
          width: auto;
          height: auto;
          font-size: 12px;
          background-color: $gray-100;
          border-color: $gray-200;
          cursor: pointer;

          &:focus {
            border-color: $gray-300;
          }
        }
      }
    }

    .accounts {
      width: 210px;
      height: 55px;
      margin-left: 25px;
      padding: 0 15px 8px;
      background-color: $gray-100;
      border: 1px solid $gray-200;
      border-radius: $border-radius;

      legend {
        width: auto;
        margin-bottom: 5px;
        padding: 0 5px;
        font-size: 12px !important;
      }

      div {
        display: flex;
        flex-direction: row;

        .form-check {
          font-size: 12px;

          label {
            margin: 1px 25px 0 -2px;

            label {
              margin: 1px 0;
            }
          }
        }
      }
    }

    .action-buttons {
      align-self: center;
      margin-left: 40px;
    }

    &.xs,
    &.sm {
      flex-direction: column;

      .accounts {
        margin-left: 0;

        div .form-check label {
          margin-top: 2px;
        }
      }

      .action-buttons {
        align-self: flex-end;
        margin: -35px 0 5px 0;
      }

      .bills-footer {
        flex-direction: column;
        margin-top: 20px;
        font-weight: bold;
        line-height: 30px;

        span {
          font-weight: normal;
        }
      }
    }
  }

  .help svg {
    position: absolute;
    right: 30px;
    margin-top: 15px;
    color: $primary;
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: darken($primary, 10%);
    }
  }

  #help-toast__toast_outer {
    position: absolute;
    right: 26px;
    margin-top: 35px;
    z-index: 3;

    p {
      font-size: 13px;

      &.notPaid {
        color: black;
      }

      &.aboutToExpire {
        font-weight: bold;
        color: $blue;
      }

      &.dueDay {
        font-weight: bold;
        color: $purple;
      }

      &.expired {
        font-weight: bold;
        color: $red;
      }

      &.paid {
        color: $dark-green;
      }

      &.paidLate {
        font-weight: bold;
        color: $dark-green;
      }
    }
  }
}
</style>
