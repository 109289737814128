<template>
  <div class="skeleton">
    <header v-if="title || buttons">
      <b-skeleton v-if="title" type="input" />
      <div class="skeleton-buttons">
        <b-skeleton v-for="i in buttons" :key="i" type="button" />
      </div>
    </header>
    <b-skeleton-table
      :rows="rows"
      :columns="columns"
      :table-props="{
        small,
        striped: true,
        borderless: true,
        outlined: true,
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: Boolean,
    buttons: Number,
    rows: Number,
    columns: Number,
    small: Boolean,
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.skeleton {
  .row {
    margin-top: 20px;
  }

  header {
    margin-bottom: 25px;
  }

  .b-skeleton-input {
    width: 350px;
    height: 27px;
  }

  .skeleton-buttons {
    display: flex;
    flex-direction: row;
  }

  .b-skeleton-button {
    width: 90px;
    height: 31px;
    margin: 0 5px;
  }
}
</style>
