import helper from '@/mixins/helper';
import validate from '@/mixins/validate';

export default {
  mixins: [helper, validate],

  data() {
    return {
      patient_model: {
        name: {
          singular: 'patient',
          plural: 'patients',
        },
        listSize: 'xl',
        formSize: 'xl',
        mainField: 'name',
        secondaryField: { key: 'birth_date', formatter: 'formatDate' },
        actions: [
          { name: 'add' },
          { name: 'edit' },
          {
            name: 'appointments',
            label: this.$t('actions.patient.appointments'),
            variant: 'primary',
            icon: ['far', 'calendar-alt'],
            modal: 'appointments',
            hasId: true,
          },
          {
            name: 'records',
            label: this.$t('actions.patient.records'),
            variant: 'dark',
            icon: ['far', 'clipboard'],
            modal: 'records',
            hasId: true,
          },
          { name: 'delete' },
        ],
        dropdownActions: [
          { name: 'edit' },
          {
            name: 'appointments',
            label: this.$t('actions.patient.appointments'),
            modal: 'appointments',
          },
          {
            name: 'records',
            label: this.$t('actions.patient.records'),
            modal: 'records',
          },
          { name: 'delete' },
        ],

        fields: {
          list: [
            {
              key: 'id',
              label: this.$t('fields.patient.id'),
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'name',
              label: this.$t('fields.patient.name'),
              sortable: true,
            },
            {
              key: 'birth_date',
              label: this.$t('fields.patient.birth_date'),
              formatter: value => this.formatDate(value),
              class: 'text-center',
              sortable: true,
            },
            ,
            {
              key: 'appointments',
              label: this.$t('fields.patient.appointments'),
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'first_appointment_date',
              label: this.$t('fields.patient.first_appointment_date'),
              class: 'text-center',
              formatter: value => this.formatDate(value),
              sortable: true,
            },
          ],

          view: [
            {
              groupName: this.$t('fields.patient.personal'),
              type: 'list',
              fields: [
                { key: 'cpf_cnpj', label: this.$t('fields.patient.cpf_cnpj') },
                {
                  key: 'identity_number',
                  label: this.$t('fields.patient.identity_number'),
                },
                {
                  key: 'gender',
                  label: this.$t('fields.patient.gender'),
                  translate: true,
                },
                {
                  key: 'marital_status',
                  label: this.$t('fields.patient.marital_status'),
                  translate: true,
                },
                {
                  key: 'skin',
                  label: this.$t('fields.patient.skin'),
                  translate: true,
                  breakLine: true,
                },
                {
                  key: 'citizenship',
                  label: this.$t('fields.patient.citizenship'),
                  translate: true,
                },
                {
                  key: 'place_of_birth',
                  label: this.$t('fields.patient.place_of_birth'),
                  breakLine: true,
                },
                {
                  key: 'occupation',
                  label: this.$t('fields.patient.occupation'),
                },
                {
                  key: 'email',
                  label: this.$t('fields.patient.email'),
                },
              ],
            },
            {
              groupName: this.$t('fields.patient.filiation'),
              type: 'concat',
              fields: [{ key: 'mother_name' }, { key: 'father_name' }],
            },
            {
              groupName: this.$t('fields.phone.phones'),
              type: 'list',
              fields: [
                { key: 'phone_mobile', label: this.$t('fields.phone.mobile') },
                { key: 'phone_home', label: this.$t('fields.phone.home') },
                { key: 'phone_work', label: this.$t('fields.phone.work') },
                { key: 'phone_other', label: this.$t('fields.phone.other') },
              ],
            },
            {
              groupName: this.$t('fields.address.address'),
              type: 'concat',
              fields: [
                { key: 'address_street' },
                { key: 'address_number' },
                { key: 'address_complement' },
                { key: 'address_district' },
                { key: 'address_city' },
                { key: 'address_region' },
                {
                  key: 'address_zip_code',
                  label: this.$t('fields.address.zip_code'),
                },
              ],
            },
            {
              groupName: this.$t('fields.patient.information'),
              type: 'concat',
              fields: [{ key: 'information' }],
            },
          ],

          form: [
            {
              type: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr 1fr',
              fields: [
                {
                  key: 'name',
                  state: null,
                  required: true,
                  autofocus: true,
                  maxlength: 100,
                  formatter: 'captalize',
                },
                {
                  key: 'cpf_cnpj',
                  state: null,
                  required: true,
                  mask: 'cpf_cnpj',
                  placeholder: '____.____.____-___',
                  validation: 'checkCPFOrCNPJ',
                  validationMsg: this.$t('messages.patient.cpfCnpjInvalid'),
                },
                {
                  key: 'identity_number',
                  state: null,
                  maxlength: 20,
                  formatter: 'removeExtraSpaces',
                },
                {
                  key: 'birth_date',
                  state: null,
                  type: 'date',
                  required: true,
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 270px',
              fields: [
                {
                  key: 'gender',
                  state: null,
                  type: 'select',
                  options: this.getGenders(),
                  required: true,
                },
                {
                  key: 'marital_status',
                  state: null,
                  type: 'select',
                  options: this.getMaritalStatus(),
                },
                {
                  key: 'skin',
                  state: null,
                  type: 'select',
                  options: this.getSkins(),
                },
                {
                  key: 'citizenship',
                  state: null,
                  type: 'select',
                  options: this.getCitizenships(),
                },
                {
                  key: 'place_of_birth',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '1fr 1fr 270px',
              fields: [
                {
                  key: 'occupation',
                  state: null,
                  maxlength: 30,
                  formatter: 'removeExtraSpaces',
                },
                {
                  key: 'mother_name',
                  state: null,
                  maxlength: 65,
                  formatter: 'captalize',
                },
                {
                  key: 'father_name',
                  state: null,
                  maxlength: 65,
                  formatter: 'captalize',
                },
              ],
            },
            {
              groupName: this.$t('fields.phone.phones'),
              type: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 270px',
              fields: [
                {
                  key: 'phone_mobile',
                  state: null,
                  type: 'tel',
                  placeholder: '(__) ______-_____',
                },
                {
                  key: 'phone_home',
                  state: null,
                  type: 'tel',
                  placeholder: '(__) ______-_____',
                },
                {
                  key: 'phone_work',
                  state: null,
                  type: 'tel',
                  placeholder: '(__) ______-_____',
                },
                {
                  key: 'phone_other',
                  state: null,
                  type: 'tel',
                  placeholder: '(__) ______-_____',
                },
                {
                  key: 'email',
                  state: null,
                  maxlength: 55,
                  formatter: 'formatEmail',
                  validation: 'checkEmail',
                  validationMsg: this.$t('messages.patient.emailInvalid'),
                },
              ],
            },
            {
              groupName: this.$t('fields.address.address'),
              type: 'grid',
              gridTemplateColumns: '1fr 308px 1fr 270px',
              fields: [
                {
                  key: 'address_zip_code',
                  state: null,
                  mask: 'cep',
                  placeholder: '________-____',
                  validation: 'checkZipCode',
                  validationMsg: this.$t('messages.address.zipCodeInvalid'),
                  onKeyUp: 'searchAddress',
                },
                {
                  key: 'address_street',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_number',
                  state: null,
                  maxlength: 20,
                  formatter: 'removeExtraSpaces',
                },
                {
                  key: 'address_complement',
                  state: null,
                  maxlength: 20,
                  formatter: 'lowerAll',
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '4fr 4fr 1fr',
              fields: [
                {
                  key: 'address_district',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_city',
                  state: null,
                  maxlength: 55,
                  formatter: 'captalize',
                },
                {
                  key: 'address_region',
                  state: null,
                  type: 'select',
                  options: this.getBrazilianRegions(),
                },
              ],
            },
            {
              groupName: this.$t('fields.patient.information'),
              fields: [
                {
                  key: 'information',
                  state: null,
                  label: '',
                  maxlength: 320,
                  formatter: 'removeExtraSpaces',
                },
              ],
            },
          ],
        },

        default: {
          formFields: [],
        },
      },
    };
  },

  mounted() {
    this.patient_model.default.formFields = this.patient_model.fields.form;

    this.setMobilePatient();
  },

  methods: {
    getGenders() {
      return [
        { value: null, text: '' },
        { value: 'male', text: this.$t('fields.gender.male') },
        { value: 'female', text: this.$t('fields.gender.female') },
      ];
    },

    getMaritalStatus() {
      return [
        { value: null, text: '' },
        { value: 'single', text: this.$t('fields.marital_status.single') },
        { value: 'married', text: this.$t('fields.marital_status.married') },
        {
          value: 'separated',
          text: this.$t('fields.marital_status.separated'),
        },
        { value: 'divorced', text: this.$t('fields.marital_status.divorced') },
        { value: 'widowed', text: this.$t('fields.marital_status.widowed') },
        { value: 'other', text: this.$t('fields.marital_status.other') },
      ];
    },

    getSkins() {
      return [
        { value: null, text: '' },
        { value: 'white', text: this.$t('fields.skin.white') },
        { value: 'black', text: this.$t('fields.skin.black') },
        { value: 'brown', text: this.$t('fields.skin.brown') },
        { value: 'other', text: this.$t('fields.skin.other') },
      ];
    },

    getCitizenships() {
      return [
        { value: null, text: '' },
        { value: 'brazilian', text: this.$t('fields.citizenship.brazilian') },
        { value: 'other', text: this.$t('fields.citizenship.other') },
      ];
    },

    setMobilePatient() {
      if (this.$mq === 'xs') {
        this.patient_model.fields.form.forEach(group => {
          if (group.type === 'grid') group.gridTemplateColumns = 'auto';

          group.fields.forEach(field => {
            if (field.required) delete field.required;
            if (field.validation) delete field.validation;
            if (field.validationMsg) delete field.validationMsg;
          });
        });
      } else {
        this.patient_model.fields.form = this.patient_model.default.formFields;
      }
    },
  },

  watch: {
    $mq() {
      this.setMobilePatient();
    },
  },
};
