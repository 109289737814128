<template>
  <ul class="app-info" :class="$mq">
    <li>© {{ year }} {{ $t('main.appName') }}. {{ $t('main.copyright') }}.</li>
    <li>
      {{ $t('main.developedBy') }}
      <a href="https://henriquedornel.com" target="_blank">Henrique Dornel</a>
    </li>
    <li class="app-url">
      <a href="https://henriquedornel.com" target="_blank">
        henriquedornel.com
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    appUrl: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    year() {
      const today = new Date();
      return today.getFullYear();
    },
  },
};
</script>
