<template>
  <Modal>
    <b-modal
      id="record-dialog-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @ok="confirm"
    >
      <p class="msg">
        {{
          $t(`confirmations.record.${action}`, {
            title: patient.name,
          })
        }}
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="savingRecord"
          >
            {{ $t('buttons.no') }}
          </b-button>
          <b-button
            :variant="buttonVariant"
            @click="ok()"
            :disabled="savingRecord"
          >
            <Spinner v-if="savingRecord" size="small" />
            <span v-else>
              {{ $t('buttons.yes') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, Spinner },

  mixins: [api, helper],

  props: {
    action: String,
    history: Object,
    record: Object,
  },

  data() {
    return {
      savingRecord: false,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    patient() {
      return this.$store.state.patient;
    },

    buttonVariant() {
      return this.action === 'revert' ? 'danger' : 'info';
    },
  },

  methods: {
    async confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.savingRecord = true;

      await this[`${this.action}Record`]();
    },

    async revertRecord() {
      const appointment = {
        ...this.item,
        status: 'waiting',
        price: 0,
        time_treatment: null,
        time_end: null,
        phone_mobile: this.item.patient_phone_mobile,
        phone_home: this.item.patient_phone_home,
        phone_work: this.item.patient_phone_work,
        phone_other: this.item.patient_phone_other,
      };

      await axios
        .put(`${this.baseApiUrl}/appointments/${this.item.id}`, appointment)
        .then(async () => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.appointment.confirmed', {
              operation: this.$t('messages.appointment.reverted'),
            }),
          });

          await this.sendMessage(this.action);

          this.$emit('submit');
          this.$bvModal.hide('record-dialog-modal');
          this.$bvModal.hide('appointments-record-modal');
        })
        .catch(this.showError);

      this.savingRecord = false;
    },

    async saveRecord() {
      let time_end = new Date();
      time_end = this.formatTime(time_end);

      const status =
        this.item.category === 'private' &&
        this.item.type_id !== 7 &&
        !this.item.default_price
          ? 'pending'
          : 'concluded';

      const appointment = {
        ...this.item,
        status,
        time_end,
        price: this.item.category === 'unpaid' ? 0 : this.item.default_price,
        phone_mobile: this.item.patient_phone_mobile,
        phone_home: this.item.patient_phone_home,
        phone_work: this.item.patient_phone_work,
        phone_other: this.item.patient_phone_other,
      };

      delete appointment.time_treatment;

      const record = {
        patient_id: this.patient.id,
      };

      const isRecordEmpty =
        !this.record.content ||
        !this.record.content.replace(/(<([^>]+)>)/gi, '').trim();

      if (isRecordEmpty) {
        this.$toasted.global.defaultError({
          msg: this.$t('messages.record.contentRequired'),
        });
      } else {
        let content = '<br /><br />';
        content += '<p>';
        content += '<strong>';
        content += this.$t('fields.record.doctor').toUpperCase();
        content += ': </strong>';
        content += appointment.doctor_name.toUpperCase();
        content +=
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>';
        content += this.$t('fields.record.date').toUpperCase();
        content += ': </strong>';
        content += this.formatDate(appointment.date);
        content += '</p>';
        content += '<br />';
        content += this.record.content || '';
        content += '<br />';

        record.content = this.history.content
          ? this.history.content + content
          : content;

        await axios
          .put(`${this.baseApiUrl}/appointments/${this.item.id}`, appointment)
          .then(async () => {
            if (this.history.content) {
              await axios
                .put(`${this.baseApiUrl}/records/${this.history.id}`, record)
                .then(async () => {
                  this.$toasted.global.defaultSuccess({
                    msg: this.$t('messages.record.save', {
                      title: this.patient.name,
                    }),
                  });

                  await this.sendMessage(this.action, status);

                  this.$emit('submit');
                  this.$bvModal.hide('appointments-record-modal');
                })
                .catch(this.showError);
            } else {
              await axios
                .post(`${this.baseApiUrl}/records`, record)
                .then(async () => {
                  this.$toasted.global.defaultSuccess({
                    msg: this.$t('messages.record.save', {
                      title: this.patient.name,
                    }),
                  });

                  await this.sendMessage(this.action);

                  this.$emit('submit');
                  this.$bvModal.hide('appointments-record-modal');
                })
                .catch(this.showError);
            }
          })
          .catch(this.showError);
      }

      this.$nextTick(() => {
        this.$bvModal.hide('record-dialog-modal');
      });
      this.savingRecord = false;
    },

    async sendMessage(action, status) {
      const type = status === 'pending' ? status : action;

      const message = {
        sender_id: this.user.id,
        recipient_id: this.item.call_user_id,
        type,
        content: this.$t(`messages.record.${type}`, {
          title: this.patient.name,
        }),
      };

      await axios
        .post(`${this.baseApiUrl}/messages`, message)
        .catch(this.showError);
    },
  },
};
</script>

<style lang="scss">
#record-dialog-modal {
  .msg {
    padding-bottom: 30px;
  }
}
</style>
