<template>
  <div class="schedule-doctor" :class="$mq">
    <label v-if="$mq === 'xl'" for="schedule-doctor">
      {{ $t('appointments.doctor') }}
    </label>
    <b-form-select
      v-if="user.role !== 'doctor'"
      id="schedule-doctor"
      size="sm"
      v-model="doctor_id"
      :options="list"
      :disabled="disabled"
      @change="$emit('doctorChange', doctor_id)"
    >
      <template #first>
        <b-form-select-option :value="null" disabled>
          -- {{ $t('messages.appointment.selectDoctor') }} --
        </b-form-select-option>
      </template>
    </b-form-select>
    <span v-else>{{ user.username }}</span>
    <div class="messages">
      <p>{{ message.inactive }}</p>
      <p>{{ message.noDailySchedule }}</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    doctors: Array,
    disabled: Boolean,
  },

  data() {
    return {
      list: [],
      doctor_id: null,
      message: {},
    };
  },

  computed: mapState(['user']),

  watch: {
    doctors(array) {
      this.list = [{}];

      array.forEach((doctor, index) => {
        this.list[index] = {
          value: doctor.id,
          text: doctor.username,
        };
      });
    },

    doctor_id(value) {
      this.doctors.forEach(doctor => {
        if (doctor.id === value) {
          this.message.inactive = doctor.active
            ? ''
            : this.$t('messages.appointment.doctorInactive');

          this.message.noDailySchedule = doctor.daily_schedules
            ? ''
            : this.$t('messages.appointment.noDailySchedules');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/custom.scss';

.schedule-doctor {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  label {
    font-size: 12px;
    font-weight: bold;
    color: $gray-700;
    margin: 0;
    padding: 5px 5px 0 30px;

    &::after {
      content: ':';
    }
  }

  .custom-select {
    width: 220px;
    height: auto;
    font-size: 12px;
    background-color: $gray-100;
    border-color: $input-border-color;
    cursor: pointer;

    &:focus {
      border-color: $gray-300;
    }
  }

  span {
    font-size: 13px;
  }

  .messages p {
    margin: 0;
    padding: 0 10px;
    font-size: 12px;
    color: $orange;
  }

  &.xs,
  &.sm {
    flex-direction: column;
  }
}
</style>
