export default {
  data() {
    return {
      appName: this.$t('main.appName'),
    };
  },

  created() {
    const title = this.getTitle(this);
    if (title) {
      document.title = title;
    }
  },

  methods: {
    getTitle(vm) {
      const { title } = vm.$options;
      if (title) {
        return typeof title === 'function' ? title.call(vm) : title;
      }
    },
  },
};
