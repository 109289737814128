<template>
  <div class="bills-table" :class="$mq">
    <b-table
      small
      sticky-header
      striped
      borderless
      outlined
      hover
      show-empty
      no-border-collapse
      head-variant="dark"
      :tbody-tr-class="`row-class ${tab}`"
      :items="items"
      :fields="fields"
      :empty-text="$t('messages.emptyText')"
      :stacked="stacked"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      @sort-changed="clearSelected"
      @row-clicked="onRowClicked"
      @row-contextmenu="onContextMenu"
      @row-dblclicked="doubleClick"
    >
    </b-table>
    <DropdownMenu
      entity-name="bills"
      :actions="actions"
      :position="dropdownPosition"
    />
  </div>
</template>

<script>
import DropdownMenu from '@/components/utils/DropdownMenu/DropdownMenu';

import helper from '@/mixins/helper';

export default {
  components: { DropdownMenu },

  mixins: [helper],

  props: {
    tab: String,
    fields: Array,
    items: Array,
    mainField: String,
    isItemSelected: Boolean,
  },

  data() {
    return {
      sortBy: 'payment_date',
      sortDesc: false,
      selectedRow: null,
      dropdownPosition: {
        top: 0,
        left: 0,
      },
      actions: [{ name: 'edit' }, { name: 'delete' }],
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    isRowSelected() {
      return this.selectedRow !== null;
    },

    stacked() {
      return this.$mq !== 'xl' && this.tab === 'list';
    },
  },

  methods: {
    onRowClicked(item, index, event) {
      if (this.tab === 'list') {
        if (this.$mq === 'xl') {
          this.selectRow(item, index, event);
        } else {
          this.openDropdownMenu(item, index, event);
          if (!this.isRowSelected) {
            this.openDropdownMenu(item, index, event);
          }
        }
      }
    },

    onContextMenu(item, index, event) {
      event.preventDefault();

      if (this.tab === 'list' && this.$mq === 'xl') {
        this.openDropdownMenu(item, index, event);
      }
    },

    selectRow(item, index, event) {
      if (this.selectedRow !== index) {
        this.selectedRow = index;
      } else if (event.type !== 'contextmenu') {
        this.clearSelected();
      }

      this.$emit('itemselected', this.isRowSelected);

      item.due_date = this.formatDate(
        item.due_date,
        'YYYY-MM-DD',
        'YYYY-MM-DD',
      );
      item.payment_date = this.formatDate(
        item.payment_date,
        'YYYY-MM-DD',
        'YYYY-MM-DD',
      );

      this.$store.commit('mutate', { prop: 'item', with: { ...item } });
    },

    doubleClick(item, index, event) {
      if (this.tab === 'list') {
        this.selectRow(item, index, event);
        if (!this.isRowSelected) {
          this.selectRow(item, index, event);
        }
        this.$bvModal.show('bills-form-modal');
      }
    },

    clearSelected() {
      if (this.tab === 'list') {
        this.selectedRow = null;
        this.$emit('itemselected', this.isRowSelected);

        this.$store.commit('mutate', { prop: 'item', with: {} });
      }
    },

    openDropdownMenu(item, index, event) {
      this.selectRow(item, index, event);

      const top = event.clientY - 15;
      const left = event.clientX;
      this.dropdownPosition = { top, left };
    },
  },

  watch: {
    selectedRow(value) {
      const elements = document.getElementsByClassName('row-class');

      elements.forEach(element => {
        element.style.backgroundColor = '';
      });
      if (value !== null) {
        elements[value].style.backgroundColor = '#aacdfa';
      }
    },

    isItemSelected(value) {
      if (!value) {
        this.clearSelected();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.bills-table {
  max-height: 440px;

  .b-table-sticky-header {
    max-height: 440px;
    margin-bottom: 0;
    white-space: nowrap;
  }

  table {
    th {
      font-size: 13px;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .row-class {
    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: $light-blue;
    }
  }

  .table.b-table > thead > tr > .table-b-table-default {
    color: $white;
    background-color: $primary;
  }

  .table-notPaid {
    color: black;
  }

  .table-aboutToExpire {
    font-weight: bold;
    color: $blue;
  }

  .table-dueDay {
    font-weight: bold;
    color: $purple;
  }

  .table-expired {
    font-weight: bold;
    color: $red;
  }

  .table-paid {
    color: $dark-green;
  }

  .table-paidLate {
    font-weight: bold;
    color: $dark-green;
  }

  &.xs,
  &.sm {
    max-height: unset;
  }
}
</style>
