<template>
  <Modal>
    <b-modal
      :id="`${entity.name.plural}-delete-modal`"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @show="init"
      @hidden="reset"
      @ok="confirm"
    >
      <input
        :id="`${entity.name.singular}-id`"
        type="hidden"
        v-model="item.id"
      />
      <p class="msg">
        {{
          $t(`confirmations.${entity.name.singular}.delete`, {
            title: item[entity.mainField],
          })
        }}
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button variant="secondary" @click="cancel()" :disabled="deleting">
            {{ $t('buttons.no') }}
          </b-button>
          <b-button variant="danger" @click="ok()" :disabled="deleting">
            <Spinner v-if="deleting" size="small" />
            <span v-else>
              {{ $t('buttons.yes') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, Spinner },

  mixins: [api],

  props: {
    entity: Object,
  },

  data() {
    return {
      title: document.title,
      deleting: false,
    };
  },

  computed: mapState(['customer', 'item']),

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t(
        `${this.entity.name.plural}.delete`,
      )}`;
      this.$store.commit('mutate', { prop: 'closeDialog', with: true });
    },

    reset() {
      document.title = `${this.appName} - ${this.$t(
        `menu.items.${this.entity.name.plural}`,
      )}`;
      this.$store.commit('mutate', { prop: 'closeDialog', with: false });
    },

    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.deleting = true;
      this.delete();
    },

    delete() {
      const id = this.item.id;
      const title = this.item[this.entity.mainField];

      axios
        .delete(`${this.baseApiUrl}/${this.entity.name.plural}/${id}`)
        .then(() => {
          this.$toasted.global.defaultInfo({
            msg: this.$t(`messages.${this.entity.name.singular}.confirmed`, {
              title,
              operation: this.$t(
                `messages.${this.entity.name.singular}.removed`,
              ),
            }),
          });
          this.updateList();

          this.$emit('submit');
        })
        .catch(this.showError)
        .finally(() => {
          this.deleting = false;

          this.$bvModal.hide(`${this.entity.name.plural}-delete-modal`);
        });
    },

    updateList() {
      this.$store.commit('mutate', { prop: 'loadingItems', with: true });

      const customer_id = this.customer.id;
      const id =
        this.entity.name.singular !== 'customer' ? `/${customer_id}` : '';

      axios
        .get(`${this.baseApiUrl}/${this.entity.name.plural}${id}`)
        .then(res => {
          const items = res.data || [];

          this.$store.commit('mutate', { prop: 'items', with: items });
          this.$store.commit('mutate', { prop: 'loadingItems', with: false });
        })
        .catch(this.showError);
    },
  },
};
</script>
