<template>
  <div class="menu">
    <b-navbar type="light" variant="secondary">
      <b-navbar-nav>
        <b-nav-item-dropdown :text="$t('menu.user.user')" no-caret>
          <b-dropdown-item v-b-modal.profile-modal>
            {{ $t('menu.user.profile') }}
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.change-password-modal>
            {{ $t('menu.user.changePassword') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-b-modal.signout-modal>
            {{ $t('menu.user.signOut') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown :text="$t('menu.items.items')" no-caret>
          <b-dropdown-item
            v-if="user.role === 'admin'"
            v-b-modal.customers-modal
          >
            {{ $t('menu.items.customers') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.users-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.items.users') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.appointment_types-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.items.appointment_types') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.contacts-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.items.contacts') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          :text="$t('menu.appointments.appointments')"
          no-caret
        >
          <b-dropdown-item
            v-b-modal.appointment-schedules-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.appointments.schedules') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.messages-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.appointments.messages') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown :text="$t('menu.finances.finances')" no-caret>
          <b-dropdown-item
            v-b-modal.finances-revenues-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.finances.revenues') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            v-b-modal.finances-bills-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.finances.bills') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-b-modal.bill_expenses-modal
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.finances.bill_expenses') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown :text="$t('menu.help.help')" no-caret>
          <b-dropdown-item
            :href="customer.manual"
            target="_blank"
            :disabled="!isCustomerSelected"
          >
            {{ $t('menu.help.manual') }}
          </b-dropdown-item>
          <b-dropdown-item v-b-modal.support-modal>
            {{ $t('menu.help.support') }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item v-b-modal.about-modal>
            {{ $t('menu.help.about') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <ProfileModal />
    <ChangePasswordModal />
    <SignOutModal />

    <ItemModal :entity="customer_model" />
    <ItemModal :entity="user_model" />
    <ItemModal :entity="appointment_type_model" />
    <ItemModal :entity="contact_model" />

    <AppointmentSchedulesModal />
    <MessagesModal />

    <FinancesRevenuesModal />
    <FinancesBillsModal />
    <ItemModal :entity="bill_expense_model" />

    <SupportModal />
    <AboutModal />
  </div>
</template>

<script>
import ProfileModal from '@/components/users/ProfileModal';
import ChangePasswordModal from '@/components/users/ChangePasswordModal';
import SignOutModal from '@/components/users/SignOutModal';

import ItemModal from '@/components/items/ItemModal';

import AppointmentSchedulesModal from '@/components/appointments/AppointmentSchedulesModal';
import MessagesModal from '@/components/messages/MessagesModal';

import FinancesRevenuesModal from '@/components/finances/Revenues/FinancesRevenuesModal';
import FinancesBillsModal from '@/components/finances/Bills/FinancesBillsModal';

import SupportModal from '@/components/help/SupportModal';
import AboutModal from '@/components/help/AboutModal';

import customer_model from '@/models/customer';
import user_model from '@/models/user';
import appointment_type_model from '@/models/appointment_type';
import contact_model from '@/models/contact';

import bill_expense_model from '@/models/bill_expense';

export default {
  components: {
    ProfileModal,
    ChangePasswordModal,
    SignOutModal,

    ItemModal,

    AppointmentSchedulesModal,
    MessagesModal,

    FinancesRevenuesModal,
    FinancesBillsModal,

    SupportModal,
    AboutModal,
  },

  mixins: [
    customer_model,
    user_model,
    appointment_type_model,
    contact_model,
    bill_expense_model,
  ],

  computed: {
    user() {
      return this.$store.state.user;
    },

    customer() {
      return this.$store.state.customer;
    },

    isCustomerSelected() {
      return this.customer.id;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.menu {
  nav {
    height: 30px;
    font-size: 13px;

    a {
      outline: none;
    }

    ul li ul {
      z-index: 1081;
    }
  }
}
</style>
