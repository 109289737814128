<template>
  <Modal>
    <b-modal
      id="messages-modal"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t('message.messages') }}</h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <header class="action-buttons">
        <ActionButtons
          entity-name="messages"
          :actions="actions"
          :disabled="loading.messages"
          @clearSelected="clearSelected"
        />
      </header>
      <MessagesTabs @clearSelected="clearSelected">
        <b-tab
          v-for="tab in tabs"
          :key="tab.key"
          :title="tab.title"
          :active="tab.active && !loading.messages"
        >
          <b-card-text>
            <Skeleton
              v-if="loading.messages"
              :title="false"
              :buttons="0"
              :rows="10"
              :columns="1"
              :small="true"
            />
            <section v-else>
              <MessagesTable
                :tab="tab.key"
                :fields="fields"
                :items="messages[tab.key]"
                :is-item-selected="isItemSelected"
                @itemselected="updateItemSelected"
                @readMessage="readMessage"
              />
              <MessagesCard v-if="showCard" />
            </section>
          </b-card-text>
        </b-tab>
      </MessagesTabs>
    </b-modal>
    <MessagesSendModal :users="users" @submit="loadData" />
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Skeleton from '@/components/utils/Skeleton';
import ActionButtons from '@/components/utils/ActionButtons/ActionButtons';
import MessagesSendModal from '@/components/messages/MessagesSendModal';
import MessagesTabs from '@/components/messages/MessagesTabs';
import MessagesTable from '@/components/messages/MessagesTable';
import MessagesCard from '@/components/messages/MessagesCard';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: {
    Modal,
    Skeleton,
    ActionButtons,
    MessagesSendModal,
    MessagesTabs,
    MessagesTable,
    MessagesCard,
  },

  mixins: [api, helper],

  data() {
    return {
      title: document.title,
      disableComponents: false,
      loading: {
        messages: false,
        received: false,
        sent: false,
        users: false,
      },
      messages: {
        received: [],
        sent: [],
      },
      users: [],
      isItemSelected: false,
      tabs: [
        { key: 'received', title: this.$t('message.received'), active: true },
        { key: 'sent', title: this.$t('message.sent') },
      ],
      actions: [
        {
          name: 'new',
          label: this.$t('message.new'),
          variant: 'success',
          icon: ['fas', 'plus'],
          modal: 'send',
          function: 'clearSelected',
        },
      ],
      fields: [
        { key: 'read' },
        { key: 'user' },
        { key: 'content', formatter: value => this.formatContent(value) },
        {
          key: 'created_at',
          formatter: value =>
            this.formatDateTime(value, 'YYYY-MM-DD HH:mm:ss', 'todayTime'),
          class: 'text-right',
        },
      ],
    };
  },

  computed: {
    customer() {
      return this.$store.state.customer;
    },

    user() {
      return this.$store.state.user;
    },

    message() {
      return this.$store.state.message;
    },

    showCard() {
      return this.isItemSelected;
    },

    refreshMessages() {
      return this.$store.state.refreshMessages;
    },
  },

  methods: {
    async init() {
      document.title = `${this.appName} - ${this.$t('message.messages')}`;

      await this.loadData();
    },

    reset() {
      document.title = this.title;

      this.clearSelected();

      this.messages = [];
      this.users = [];
    },

    async loadData() {
      this.loading.messages = true;
      this.disableComponents = true;

      await Promise.all([
        this.loadMessages('received'),
        this.loadMessages('sent'),
        this.loadUsers(),
      ]);

      this.loading.messages =
        this.loading.received || this.loading.sent || this.loading.users;
      this.disableComponents = false;
    },

    async loadMessages(type) {
      this.loading[type] = true;

      await axios
        .get(`${this.baseApiUrl}/messages/${type}/${this.user.id}`)
        .then(res => {
          const messages = res.data || [];

          if (type === 'received') {
            messages.forEach(message => {
              if (!message.read) {
                message._rowVariant = `${message.type}-unread`;
              } else {
                message._rowVariant = message.type;
              }
              message.read = '';
            });
          } else if (type === 'sent') {
            messages.forEach(message => {
              message.read = message.read ? this.$t('fields.message.read') : '';
              message._rowVariant = message.type;
            });
          }

          this.messages[type] = messages;

          this.loading[type] = false;
        })
        .catch(this.showError);
    },

    async loadUsers() {
      this.loading.users = true;

      await axios
        .get(
          `${this.baseApiUrl}/messages/users/${this.customer.id}/${this.user.id}`,
        )
        .then(res => {
          this.users = res.data || [];

          this.loading.users = false;
        })
        .catch(this.showError);
    },

    updateItemSelected(value) {
      this.isItemSelected = value;
    },

    clearSelected() {
      this.isItemSelected = false;
      this.$store.commit('mutate', { prop: 'message', with: {} });
    },

    readMessage(message) {
      const messageData = { ...message };
      messageData.read = true;

      axios
        .put(`${this.baseApiUrl}/messages/${messageData.id}`, messageData)
        .catch(this.showError);
    },
  },

  watch: {
    async refreshMessages(value) {
      if (value) {
        await this.loadData();
        this.clearSelected();
        this.$store.commit('mutate', { prop: 'refreshMessages', with: false });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#messages-modal___BV_modal_content_ {
  height: 703px;
}

#messages-modal {
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      position: relative;
      top: 5px;
      right: 5px;
      z-index: 1080;
    }
  }
}
</style>
