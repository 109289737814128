<template>
  <div class="actions-menu-item">
    <b-dropdown-divider v-if="divider" />
    <b-dropdown-item
      :variant="variant"
      :disabled="disabled || disabledAction || action.disabled"
      v-b-modal="!disabled && modal"
      @click="$emit('emitFunction', clickFunction, clickFunctionParam)"
    >
      {{ label }}
    </b-dropdown-item>
  </div>
</template>

<script>
export default {
  props: {
    action: Object,
    entityName: String,
    isItemSelected: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      defaultActions: {
        add: {
          variant: 'success',
          modal: 'form',
          function: 'clearSelected',
        },
        edit: {
          variant: 'warning',
          modal: 'form',
          hasId: true,
        },
        delete: {
          variant: 'danger',
          modal: 'delete',
          divider: true,
          hasId: true,
        },
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    item() {
      return this.$store.state.item;
    },

    defaultAction() {
      return this.defaultActions[this.action.name];
    },

    variant() {
      let variant;

      if (!this.defaultAction) {
        variant = this.action.variant;
      } else {
        variant = this.action.variant || this.defaultAction.variant;
      }

      return variant;
    },

    label() {
      let label;

      if (!this.defaultAction) {
        label = this.action.label;
      } else {
        label = this.action.label || this.$t(`buttons.${this.action.name}`);
      }

      return label;
    },

    modal() {
      let modal;

      if (!this.defaultAction) {
        modal = this.action.modal;
      } else {
        modal = this.action.modal || this.defaultAction.modal;
      }

      return `${this.entityName}-${modal}-modal`;
    },

    divider() {
      let divider;

      if (!this.defaultAction) {
        divider = this.action.divider;
      } else {
        divider = this.action.divider || this.defaultAction.divider;
      }

      return divider;
    },

    disabledAction() {
      let disableCondition = false;

      if (this.action.disableCondition) {
        const [field, condition, value] = this.action.disableCondition;

        if (condition === 'exists') {
          disableCondition = !!this.item[field];
        } else if (condition === 'notExists') {
          disableCondition = !this.item[field];
        } else if (condition === 'equals') {
          disableCondition = this.item[field] === value;
        } else if (condition === 'notEquals') {
          disableCondition = this.item[field] !== value;
        }
      }

      let hasId;

      if (!this.defaultAction) {
        hasId = this.action.hasId;
      } else {
        hasId = this.action.hasId || this.defaultAction.hasId;
      }

      let forbidden = false;
      if (this.action.role) {
        if (this.action.role !== this.user.role && this.user.role !== 'admin') {
          forbidden = true;
        }
      }

      return disableCondition || (hasId && !this.isItemSelected) || forbidden;
    },

    clickFunction() {
      let clickFunction;

      if (!this.defaultAction) {
        clickFunction = this.action.function;
      } else {
        clickFunction = this.action.function || this.defaultAction.function;
      }

      return clickFunction;
    },

    clickFunctionParam() {
      let clickFunctionParam;

      if (!this.defaultAction) {
        clickFunctionParam = this.action.functionParam;
      } else {
        clickFunctionParam =
          this.action.functionParam || this.defaultAction.functionParam;
      }

      return clickFunctionParam;
    },
  },
};
</script>
