<template>
  <b-dropdown
    class="actions-menu"
    size="lg"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template #button-content>
      <fa-icon :icon="['fas', 'ellipsis-h']" />
    </template>
    <ActionsMenuItem
      v-for="action in actions"
      :key="action.name"
      :action="action"
      :entity-name="entityName"
      :is-item-selected="isItemSelected"
      :disabled="disabled"
      @emitFunction="emitFunction"
    />
  </b-dropdown>
</template>

<script>
import ActionsMenuItem from '@/components/utils/ActionsMenu/ActionsMenuItem';

export default {
  components: { ActionsMenuItem },

  props: {
    entityName: String,
    actions: Array,
    isItemSelected: Boolean,
    disabled: Boolean,
  },

  methods: {
    emitFunction(functionName, functionParam) {
      if (functionName) {
        this.$emit(functionName, functionParam);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

.actions-menu {
  display: flex;
  flex-direction: column;

  position: absolute;
  right: 27px;
  z-index: 1;

  button {
    padding: 0 0 0 10px;
    font-size: 25px;
    color: $gray-700;

    &:hover {
      color: darken($gray-700, 15%);
    }
  }
}
</style>
