import Vue from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faSearch,
  faPlus,
  faMinus,
  faPen,
  faTrash,
  faDollarSign,
  faUserClock,
  faUserCheck,
  faCheck,
  faTimes,
  faBan,
  faUndo,
  faUnlockAlt,
  faEnvelope,
  faFunnelDollar,
  faPrint,
  faUserSlash,
  faUserEdit,
  faSave,
  faChevronRight,
  faCloudUploadAlt,
  faQuestionCircle,
  faUser,
  faTable,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
  faClipboard,
  faFilePdf,
  faPlusSquare,
} from '@fortawesome/free-regular-svg-icons';

import 'font-awesome/css/font-awesome.css';

library.add(
  faSearch,
  faPlus,
  faMinus,
  faPen,
  faTrash,
  faDollarSign,
  faUserClock,
  faUserCheck,
  faCheck,
  faTimes,
  faBan,
  faUndo,
  faUnlockAlt,
  faEnvelope,
  faFunnelDollar,
  faPrint,
  faUserSlash,
  faUserEdit,
  faSave,
  faChevronRight,
  faCloudUploadAlt,
  faQuestionCircle,
  faUser,
  faTable,
  faEllipsisH,
  faCalendarAlt,
  faClipboard,
  faFilePdf,
  faPlusSquare,
);

Vue.component('fa-icon', FontAwesomeIcon);
