<template>
  <Modal>
    <b-modal
      id="patient-appointments-modal"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      @show="init"
      @hidden="reset"
    >
      <template #modal-header="{ close }">
        <h1>{{ $t('patients.appointments') }}</h1>
        <button
          type="button"
          class="close"
          :disabled="disableComponents"
          @click="close()"
        >
          ×
        </button>
      </template>
      <Skeleton
        v-if="loadingAppointments"
        :title="true"
        :buttons="0"
        :rows="9"
        :columns="5"
        :small="true"
      />
      <div v-else>
        <caption>
          <span>{{ $t('patients.patient') }}</span>
          {{
            patient.name
          }}
        </caption>
        <caption v-if="age">
          <span>{{ $t('fields.age.age') }}</span>
          {{
            age
          }}
        </caption>
        <b-table
          small
          sticky-header
          striped
          borderless
          outlined
          hover
          show-empty
          no-border-collapse
          :items="appointments"
          :fields="fields"
          :stacked="stacked"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :empty-text="$t('messages.emptyText')"
        >
        </b-table>
      </div>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Skeleton from '@/components/utils/Skeleton';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import axios from 'axios';

export default {
  components: { Modal, Skeleton },

  mixins: [api, helper],

  data() {
    return {
      title: document.title,
      loadingAppointments: false,
      disableComponents: false,
      appointments: [],
      fields: [
        { key: 'fit', label: '', class: this.$mq === 'xl' && 'text-right' },
        {
          key: 'date',
          label: this.$t('fields.appointment.date'),
          formatter: value => this.formatDate(value),
          class: this.$mq === 'xl' && 'text-center',
        },
        {
          key: 'time_scheduling',
          label: this.$t('fields.appointment.time_scheduling'),
          formatter: value => this.formatTime(value),
        },
        { key: 'doctor', label: this.$t('fields.appointment.doctor') },
        {
          key: 'category',
          label: this.$t('fields.appointment.category.category'),
          translate: true,
        },
        { key: 'type', label: this.$t('fields.appointment.type') },
      ],
      sortBy: 'date',
      sortDesc: true,
    };
  },

  computed: {
    patient() {
      return this.$store.state.patientSearch;
    },

    age() {
      const birth_date = this.patient.birth_date;

      return this.formatAge(birth_date);
    },

    stacked() {
      return this.$mq !== 'xl';
    },
  },

  methods: {
    init() {
      document.title = `${this.appName} - ${this.$t('patients.appointments')}`;

      this.loadAppointments();
    },

    reset() {
      document.title = `${this.appName} - ${this.$t('patients.info')}`;

      this.appointments = [];
    },

    loadAppointments() {
      this.loadingAppointments = true;
      this.disableComponents = true;

      axios
        .get(`${this.baseApiUrl}/appointments/${this.patient.id}`)
        .then(res => {
          const appointments = res.data || [];

          appointments.forEach(appointment => {
            this.fields.forEach(field => {
              if (field.translate) {
                appointment[field.key] = this.$t(
                  `fields.appointment.${field.key}.${appointment[field.key]}`,
                );
              }
            });

            appointment.fit = appointment.fit
              ? this.$t('fields.appointment.fit')
              : '';

            appointment._rowVariant = appointment.status;
          });

          this.appointments = appointments;

          this.loadingAppointments = false;
        })
        .catch(this.showError)
        .finally(() => {
          this.disableComponents = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/custom.scss';

#patient-appointments-modal {
  caption {
    display: block;
    padding: 0 0 15px;
    font-size: 13px;
    color: $gray-700;

    span {
      font-weight: bold;

      &::after {
        content: ':';
      }
    }
  }

  .b-table-sticky-header {
    max-height: 560px;
    margin-bottom: 0;
  }

  table {
    th {
      font-size: 13px;
    }

    td {
      font-size: 12px;
      vertical-align: middle;
    }
  }

  .table.b-table > thead > tr > .table-b-table-default {
    color: $white;
    background-color: $primary;
  }

  .table-waiting {
    font-weight: bold;
    color: $orange;
  }

  .table-attending {
    font-weight: bold;
    color: $blue;
  }

  .table-pending {
    font-weight: bold;
    color: $purple;
  }

  .table-concluded {
    color: $dark-green;
  }

  .table-authorized {
    font-weight: bold;
    color: $dark-green;
  }

  .table-canceled {
    font-weight: bold;
    color: $gray-500;
  }

  .table-absence {
    font-weight: bold;
    text-decoration: line-through;
  }
}
</style>
