<template>
  <Modal>
    <b-modal
      id="appointments-revert-modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      @ok="confirm"
    >
      <input id="appointment-id" type="hidden" v-model="item.id" />
      <p class="msg">
        {{
          $t('confirmations.appointment.revert', {
            title: item.patient_name,
          })
        }}
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div class="buttons" :class="$mq">
          <b-button
            variant="secondary"
            @click="cancel()"
            :disabled="revertingAppointment"
          >
            {{ $t('buttons.no') }}
          </b-button>
          <b-button
            variant="info"
            @click="ok()"
            :disabled="revertingAppointment"
          >
            <Spinner v-if="revertingAppointment" size="small" />
            <span v-else>
              {{ $t('buttons.yes') }}
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </Modal>
</template>

<script>
import Modal from '@/components/home/Modal';
import Spinner from '@/components/utils/Spinner';

import api from '@/mixins/api';
import helper from '@/mixins/helper';

import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: { Modal, Spinner },

  mixins: [api, helper],

  props: {
    doctorId: Number,
  },

  data() {
    return {
      disableComponents: false,
      revertingAppointment: false,
    };
  },

  computed: mapState(['customer', 'user', 'item']),

  methods: {
    async confirm(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.revertingAppointment = true;
      this.disableComponents = true;

      await this.sendMessage();
      await this.revertAppointment();
    },

    async revertAppointment() {
      const id = this.item.id;

      const appointment = {
        ...this.item,
        status: this.doctorId === this.user.id ? 'waiting' : 'scheduled',
        price: 0,
        time_arrival:
          this.doctorId === this.user.id ? this.item.time_arrival : null,
        time_treatment: null,
        time_end: null,
        phone_mobile: this.item.patient_phone_mobile,
        phone_home: this.item.patient_phone_home,
        phone_work: this.item.patient_phone_work,
        phone_other: this.item.patient_phone_other,
      };

      await axios
        .put(`${this.baseApiUrl}/appointments/${id}`, appointment)
        .then(() => {
          this.$toasted.global.defaultInfo({
            msg: this.$t('messages.appointment.confirmed', {
              operation: this.$t('messages.appointment.reverted'),
            }),
          });

          this.$emit('submit');
          this.$bvModal.hide('appointments-revert-modal');
        })
        .catch(this.showError);

      this.revertingAppointment = false;
      this.disableComponents = false;
    },

    async sendMessage() {
      if (
        this.item.status === 'waiting' ||
        this.item.status === 'attending' ||
        this.item.status === 'concluded'
      ) {
        const recipient_id =
          this.doctorId === this.user.id
            ? this.item.call_user_id
            : this.doctorId;

        const message = {
          sender_id: this.user.id,
          recipient_id,
          type: 'revert',
          content: this.$t('messages.appointment.revert', {
            title: this.item.patient_name,
          }),
        };

        await axios
          .post(`${this.baseApiUrl}/messages`, message)
          .catch(this.showError);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#appointments-revert-modal {
  .msg {
    padding-bottom: 30px;
  }
}
</style>
