var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',[_c('b-modal',{attrs:{"id":"patient-view-modal","size":"xl","centered":"","hide-footer":"","no-close-on-backdrop":"","no-close-on-esc":""},on:{"show":_vm.init,"hidden":_vm.reset},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h1',[_vm._v(_vm._s(_vm.$t('patients.info')))]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return close()}}},[_vm._v("×")])]}}])},[_c('div',{staticClass:"patient-info",class:_vm.$mq},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('patients.patient')))]),_vm._v(" "+_vm._s(_vm.patient.name)+" ")]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fields.patient.id')))]),_vm._v(" "+_vm._s(_vm.patient.id)+" ")]),(_vm.patient.birth_date)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fields.patient.birth_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.patient.birth_date))+" ")]):_vm._e(),(_vm.patient.birth_date)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fields.age.age')))]),_vm._v(" "+_vm._s(_vm.formatAge(_vm.patient.birth_date))+" ")]):_vm._e(),(_vm.patient.first_appointment_date)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fields.patient.first_appointment_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.patient.first_appointment_date))+" ")]):_vm._e(),(_vm.patient.last_appointment_date)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fields.patient.last_appointment_date')))]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.patient.last_appointment_date))+" ")]):_vm._e(),(_vm.patient.appointments)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('fields.patient.appointments')))]),_vm._v(" "+_vm._s(_vm.patient.appointments)+" ")]):_vm._e()]),_c('b-button-group',{staticClass:"action-buttons"},_vm._l((_vm.actions),function(action){return _c('ActionButton',{key:action.name,attrs:{"action":action,"entity-name":"patient"}})}),1),(Object.keys(_vm.record).length !== 0)?_c('div',{staticClass:"patient-view-card",class:_vm.$mq},[_c('b-card',_vm._l((_vm.patient_model.fields.view),function(group){return (_vm.groupFields[group.groupName])?_c('b-card-text',{key:group.groupName},[(
              Object.keys(_vm.groupFields).length > 1 &&
              _vm.groupFields[group.groupName]
            )?_c('caption',[_vm._v(" "+_vm._s(group.groupName)+" ")]):_vm._e(),_c('ul',{class:group.type},_vm._l((group.fields),function(field){return (_vm.record[field.key])?_c('li',{key:field.key},[_c('span',[(
                    _vm.groupFields[group.groupName].length > 1 && field.label
                  )?_c('label',{attrs:{"for":field.key}},[_vm._v(" "+_vm._s(field.label))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.record[field.key]))])]),(field.breakLine)?_c('br'):_vm._e()]):_vm._e()}),0)]):_vm._e()}),1)],1):_vm._e()],1),_c('PatientFormModal',{on:{"updatePatient":function($event){return _vm.$emit('updatePatient')}}}),_c('PatientAppointmentsModal'),_c('PatientRecordModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }