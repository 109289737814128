import Vue from 'vue';
import App from './App';

import './config/bootstrap';
import './config/mq';

import router from './config/router';
import store from './config/store';
import i18n from './config/i18n';

import wb from './registerServiceWorker';
Vue.prototype.$workbox = wb;

import title from './mixins/title';
Vue.mixin(title);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
