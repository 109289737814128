import helper from '@/mixins/helper';

export default {
  mixins: [helper],

  data() {
    return {
      bill_expense_model: {
        name: {
          singular: 'bill_expense',
          plural: 'bill_expenses',
        },
        listSize: 'xl',
        formSize: 'lg',
        mainField: 'name',
        secondaryField: 'expense_account',
        actions: [{ name: 'add' }, { name: 'edit' }, { name: 'delete' }],
        dropdownActions: [{ name: 'edit' }, { name: 'delete' }],

        fields: {
          list: [
            {
              key: 'expense_account',
              label: this.$t('fields.bill_expense.account'),
              sortable: true,
              translate: true,
            },
            {
              key: 'name',
              label: this.$t('fields.bill_expense.name'),
              sortable: true,
            },
            {
              key: 'type_price',
              label: this.$t('fields.bill_expense.price'),
              sortable: true,
              formatter: value => this.formatPrice(value),
            },
            {
              key: 'last_payment_date',
              label: this.$t('fields.bill_expense.last_payment_date'),
              sortable: true,
              formatter: value => this.formatDate(value),
            },
          ],

          view: [
            {
              type: 'list',
              fields: [
                {
                  key: 'type_price',
                  label: this.$t('fields.bill_expense.price'),
                  formatter: 'formatPrice',
                },
                {
                  key: 'due_day',
                  label: this.$t('fields.bill_expense.due_day'),
                  breakLine: true,
                },
                {
                  key: 'last_payment_date',
                  label: this.$t('fields.bill_expense.last_payment_date'),
                  formatter: 'formatDate',
                  breakLine: true,
                },
                {
                  key: 'months',
                  label: this.$t('fields.bill_expense.months'),
                  formatter: 'formatMonths',
                  breakLine: true,
                },
                {
                  key: 'description',
                  label: this.$t('fields.bill_expense.description'),
                },
              ],
            },
          ],

          form: [
            {
              type: 'grid',
              gridTemplateColumns: '2fr 1fr',
              fields: [
                {
                  key: 'name',
                  state: null,
                  autofocus: true,
                  placeholder: '',
                  maxlength: 55,
                  formatter: 'removeExtraSpaces',
                },
                {
                  key: 'account',
                  state: null,
                  type: 'select',
                  options: this.getAccounts(),
                },
              ],
            },
            {
              fields: [
                {
                  key: 'description',
                  state: null,
                  placeholder: '',
                  maxlength: 255,
                  formatter: 'removeExtraSpaces',
                },
              ],
            },
            {
              type: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              fields: [
                {
                  key: 'due_day',
                  state: null,
                  placeholder: '',
                  mask: 'month_day',
                },
                {
                  key: 'type',
                  state: null,
                  type: 'select',
                  options: this.getTypes(),
                },
                {
                  key: 'price',
                  state: null,
                  type: 'money',
                  maxlength: 55,
                  hideCondition: ['type', 'equals', 'variable'],
                },
              ],
            },
            {
              fields: [
                {
                  key: 'months',
                  state: null,
                  label: this.$t('fields.bill_expense.months'),
                  placeholder: '',
                  type: 'multiselect',
                  options: this.getMonths(),
                },
              ],
            },
          ],
        },

        default: {
          actions: [],
          mobileActions: [],
          listFields: [],
          formFields: [],
        },
      },
    };
  },

  mounted() {
    this.bill_expense_model.default.actions = this.bill_expense_model.actions;
    this.bill_expense_model.default.listFields =
      this.bill_expense_model.fields.list;
    this.bill_expense_model.default.formFields =
      this.bill_expense_model.fields.form;

    this.setMobileBillExpense();
  },

  methods: {
    getAccounts() {
      return [
        { value: null, text: '' },
        { value: 'personal', text: this.$t('bill_expenses.account.personal') },
        { value: 'business', text: this.$t('bill_expenses.account.business') },
      ];
    },

    getTypes() {
      return [
        { value: null, text: '' },
        { value: 'fixed', text: this.$t('bill_expenses.type.fixed') },
        { value: 'variable', text: this.$t('bill_expenses.type.variable') },
      ];
    },

    getMonths() {
      return [
        {
          all: this.$t('bill_expenses.month.all'),
          values: [
            { name: this.$t('bill_expenses.month.january') },
            { name: this.$t('bill_expenses.month.february') },
            { name: this.$t('bill_expenses.month.march') },
            { name: this.$t('bill_expenses.month.april') },
            { name: this.$t('bill_expenses.month.may') },
            { name: this.$t('bill_expenses.month.june') },
            { name: this.$t('bill_expenses.month.july') },
            { name: this.$t('bill_expenses.month.august') },
            { name: this.$t('bill_expenses.month.september') },
            { name: this.$t('bill_expenses.month.october') },
            { name: this.$t('bill_expenses.month.november') },
            { name: this.$t('bill_expenses.month.december') },
          ],
        },
      ];
    },

    setMobileBillExpense() {
      if (this.$mq === 'xl') {
        this.bill_expense_model.actions =
          this.bill_expense_model.default.actions;
      } else {
        this.bill_expense_model.actions =
          this.bill_expense_model.default.actions.filter(
            action => action.name === 'add',
          );
        this.bill_expense_model.mobileActions =
          this.bill_expense_model.default.actions.filter(
            action => action.name !== 'add',
          );
      }

      if (this.$mq === 'xs') {
        this.bill_expense_model.fields.list =
          this.bill_expense_model.default.listFields.filter(
            field => field.key === 'expense_account' || field.key === 'name',
          );

        this.bill_expense_model.fields.form.forEach(group => {
          if (group.type === 'grid') group.gridTemplateColumns = 'auto';

          group.fields.forEach(field => {
            if (field.required) delete field.required;
            if (field.validation) delete field.validation;
            if (field.validationMsg) delete field.validationMsg;
          });
        });
      } else {
        this.bill_expense_model.fields.list =
          this.bill_expense_model.default.listFields;
        this.bill_expense_model.fields.form =
          this.bill_expense_model.default.formFields;
      }
    },
  },

  watch: {
    $mq() {
      this.setMobileBillExpense();
    },
  },
};
